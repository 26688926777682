import React, {useState, useEffect, useContext} from 'react';
// import { useAuth } from './AuthContext';
import { MyProvider } from './context/myProvider.js';
import { AuthProvider } from './AuthContext'
import Home from './pages/home';
import AltHome from './pages/altHome';
import JeanHome from './pages/jeanHome';
import RealArticles from './pages/realArticles';
import Stories from './pages/stories';
import UploadArticle from './pages/uploadArticle';
import UploadObit from './pages/uploadObit';
import Agenda from './pages/agenda';
import Activities from './pages/activities';
import GroupPassword from './pages/groupPassword';
import SignUp from './pages/signUp';
import Login from './pages/login';
import ComingSoon from './pages/comingSoon';
import Should from './pages/shouldBeOnList';
import CommunityWall from './pages/community-wall';
import NotFound from './pages/notFound';
import TreeContainer from './pages/tree/treeContainer';
// import Data, {testData} from '../data'
import ReactFlow, { Controls, Background, ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';
import Flow from './pages/tree/flow.js'
import './somecss.css';
import { BrowserRouter as Router, Routes, Route } from 
'react-router-dom';
import TheArticle from './pages/theArticles';

import History from './pages/history/history';
import HistoryContent from './pages/history/content';
import UploadHistory from './pages/history/uploadContent';
import Profile from './pages/profile';
import Profiles from './pages/profiles';
import OtherProfiles from './pages/otherProfiles';
import AlbumExample from './pages/photos/albumExample';
import ExampleAlbum from './unused logic/exampleAlbum';
import AlbumList from './pages/photos/albumList';
import Upload from './pages/photos/upload';
import NodesList, {theNodes, someNodes} from './pages/tree/nodes.js';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// from exportNode..testing etc
import ExportNodes, {testingExport} from './pages/tree/testingExportNode.js'


// re: sharing/changing "about me" state...
// I think make certain lines in "testNodes" state...
// that can be updated from Profile component?
// 

function App() {
  // const { currentUser } = useAuth();
  const [showKate, setShowKate] = useState(false);
  const katePass = () => {
    setShowKate(true);
  }
  // this is for dropdown/photo components (passing/changing state êntre les enfants)
  const [hidePhotoButtons, setHidePhotoButtons] = useState(true);
  const hideButtonsFunction = () => {
    setHidePhotoButtons(!hidePhotoButtons);
  };
  // this is 
  const [testShare, setTestShare] = useState("Robert Thomas Riley");
  
  // OK! so the above can go into a separate file (export const...)?
  // and I can 
  // FIO : CUSTOM EDGES!
  const [arrayData, setArrayData] = useState([]);
  const updateArrayData = (exportNode) => {
    setArrayData(exportNode);
  };

  // LOCAL STORAGE GROUP PASSWORD STUFF
  useEffect(() => {
    // Retrieve the password from localStorage
    const storagePassword = localStorage.getItem('groupPassword');

    // Check if a password is stored in localStorage
    if (storagePassword) {
      // Password exists in localStorage
      setShowKate(true);
    }
  }, []);
  
  return (
    <>
    <AuthProvider>
    {/* <ExportNodes updateArrayData={updateArrayData} /> */}
    {/* <br/> */}
    {/* {JSON.stringify(arrayData, null, 2)} */}
    <Router>
    {/* <div className="tree-center"> */}
    <>
      <ReactFlowProvider>
      <Routes>
      <Route path="/" element={<GroupPassword katePass={katePass} showKate={showKate}  />} />
      {showKate ? <>
      <Route path="/agenda" element={<Agenda hideFunction={hideButtonsFunction}  />} />
      <Route path="/activities-and-attractions" element={<Activities hideFunction={hideButtonsFunction}  />} />
      {/* <Route path="/home" element={<AltHome sharedState={arrayData} hideFunction={hideButtonsFunction}  />} /> */}
      <Route path="/home" element={<JeanHome sharedState={arrayData} hideFunction={hideButtonsFunction}  />} />
      {/* <Route path="/family-tree" element={<Flow sharedState={arrayData} hideFunction={hideButtonsFunction}  />} /> */}
      <Route path="/stories" element={<Stories hideFunction={hideButtonsFunction}  />} />      
      <Route path="/stories-upload" element={<UploadArticle hideFunction={hideButtonsFunction}  />} />
      <Route path="/stories-upload-obit" element={<UploadObit hideFunction={hideButtonsFunction}  />} />
      <Route path="/profile" element={<Profile hideFunction={hideButtonsFunction}  />} />
      <Route path="/profiles" element={<Profiles hideFunction={hideButtonsFunction}  />} />
      <Route path="/profiles/:encryptedEmail" element={<OtherProfiles hideFunction={hideButtonsFunction}  />} />
      {/* <Route path="/faqs" element={<History hideFunction={hideButtonsFunction}  />} /> */}
      {/* <Route path="/history-upload" element={<UploadHistory hideFunction={hideButtonsFunction}  />} />
      <Route path="/history-content" element={<HistoryContent hideFunction={hideButtonsFunction}  />} /> */}
      <Route path="/album/:encryptedEmail/:albumTag/:albumName" element={<AlbumExample hideFunction={hideButtonsFunction} />} />
      {/* <Route path="/album-example" element={<AlbumExample  hideFunction={hideButtonsFunction} />} /> */}
      {/* <Route path="/example-album" element={<ExampleAlbum hideButtons={hidePhotoButtons} hideFunction={hideButtonsFunction}  />} /> */}
      <Route path="/album-list" element={<AlbumList hideButtons={hidePhotoButtons} hideFunction={hideButtonsFunction}  />} />
      <Route path="/upload" element={<Upload hideButtons={hidePhotoButtons} hideFunction={hideButtonsFunction}  />} />
      <Route path="/community-wall" element={<CommunityWall hideButtons={hidePhotoButtons} hideFunction={hideButtonsFunction}  />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      {/* <Route path="/should-be-on-list" element={<Should />} /> */}
      {/* ARTICLES BELOW: */}
      <Route path="/article/:theEmail/:theTitle" element={<TheArticle />} />
      <Route path="*" element={<NotFound />} />
      </>
      :
      <Route path="/" element={<GroupPassword katePass={katePass} showKate={showKate}  />} />
      }
      </Routes>
      </ReactFlowProvider>
    </>
    </Router>
    </AuthProvider>
    </>
  );
}

export default App;
