import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../dropDown';
import LoadingSpinner from './loadingSpinner';
import {BsHouseFill, BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill} from "react-icons/bs";
import { useNavigate  } from 'react-router-dom'
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_riley.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import signature from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sig-newest.png';

function Agenda() {
  const navigate = useNavigate();

  // testing function
  function testIt() {
    alert('Will make this button render the appropriate itinerary')
  }
  // naviagation:

  function toHome() {
    navigate('/home');
  }
  function toTree() {
    navigate('/stories');
  }
  function toProfile() {
    navigate('/profiles')
  }
  function toFaqs() {
    navigate('/faqs');
  }
  function toPhotos() {
    navigate('/album-list');
  }
  function toWall() {
    navigate('/community-wall');
}
function toAgenda() {
  navigate('/agenda');
}
function toActivities() {
  navigate('/activities-and-attractions');
}

  const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

const [fri, setFri] = useState(true);
const [sat, setSat] = useState(false);
const [sun, setSun] = useState(false);

function toFriday() {
  setFri(true);
  setSat(false);
  setSun(false);
}
function toSaturday() {
  setFri(false);
  setSat(true);
  setSun(false);
}
function toSunday() {
  setFri(false);
  setSat(false);
  setSun(true);
}
  return (
    <>
    {theWidth>750 ?
      <div>
      <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    {/* <div>
    <div style={{position:"absolute",left:"4%", top:"1.5%"}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div 
            className="profile-link"
    >Profile</div>
    <BsPersonVcard onClick={toProfile} 
    className="person-vcard"
    />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"3.5%", top:"1.5%",}}>Photos</div>
    <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"13.3%", top:"1.5%",}}>Agenda</div>
    <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"24.5%", top:"1.5%",}}>Tree</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} />
    </div> */}
    </div>

    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    {/* <BsHouseFill onClick={goHome} style={{cursor:"pointer",position:"absolute",right:"75px"}} /> */}
    {/* <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    {/* <h1 className="the-titles" >Agenda</h1> */}
    </div>
    <div style={{
          // backgroundImage: `url(${newpic})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
             }}>
             {theWidth>450 &&<div style={{position:"absolute", right:`${theWidth/100-15}%`, top:"200px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
              <img src={signature} style={{width:`${theWidth/5+20}px`}} />
            </div>}
            {theWidth>450 &&<div style={{position:"absolute", left:`${theWidth/100+7}%`, top:"200px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
              <img src={signature} style={{width:`${theWidth/5+20}px`}} />
            </div>}
    <div className="center-stuff"
    // style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",}}
    >
    <div>
    {/* <div style={{marginTop:"10px"}}>
    <div className="center-stuff">
    <i>
    (See Also:&nbsp;<a href="#" onClick={toActivities}>Activities & Attractions</a>)
    </i>
    </div>
    </div> */}
    <div className="center-stuff-agenda">
    <button disabled={fri} className="agenda-button" onClick={toFriday} style={{margin:"10px", cursor:"pointer"}}>Friday</button><button disabled={sat} className="agenda-button" onClick={toSaturday} style={{margin:"10px", cursor:"pointer"}}>Saturday</button><button disabled={sun} className="agenda-button" onClick={toSunday} style={{margin:"10px", cursor:"pointer"}}>Sunday</button>
    </div>
    <div className="reunion-schedule">
      {fri &&
        <>
        <h2 className="center-stuff">Friday, May 10, 2024</h2>
            <div>
        <div className="center-stuff">
        <i>
        (See Also:&nbsp;<a href="#" onClick={toActivities}>Activities & Attractions</a>)
        </i>
        </div>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >ARRIVAL & CHECK IN</h4>
        <strong className="center-stuff">3:00 PM - 6:00 PM</strong> 
        <p className="center-stuff" ><i>Manitoba Room</i></p>
        </div>
        <div style={{margin:"5px"}}>
        <h4 className="center-stuff">BUFFET DINNER</h4>
        <strong className="center-stuff">6:30 PM</strong>
        <p className="center-stuff"><i>Heritage Room</i></p>
        <p className="center-stuff"><i>Casual dress</i></p>
        <p className="center-stuff"><i>Bar, pool table, games room & lawn outside</i></p>
        </div>
        </>
      }
      {sat &&
        <>
        <h2 className="center-stuff">Saturday, May 11, 2024</h2>
        <div className="center-stuff">
        <i>
        (See Also:&nbsp;<a href="#" onClick={toActivities}>Activities & Attractions</a>)
        </i>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff">BUFFET BREAKFAST</h4>
        <strong className="center-stuff">8:00 AM - 10:00 AM</strong>
        <p className="center-stuff"><i>Dining Room</i></p>
        </div>
        <div>
        <h4 className="center-stuff">OPTIONAL ACTIVITIES</h4>
        <strong className="center-stuff">9:00 AM - 4:00 PM</strong>
        <p className="center-stuff"><i><a href="#" onClick={toActivities}>Click to view options</a></i></p>
        {/* <p className="center-stuff"><i>Golf - Curling Lessons - Squash Tournament - Volleyball Game - Parc Omega - Yoga</i></p> */}
        {/* <p className="center-stuff"><i>Kids Programming</i></p> */}
        </div>
        
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff">GROUP PHOTOGRAPH</h4>
        <strong className="center-stuff">4:30 PM</strong>
        {/* <p className="center-stuff"><i>Lawn</i></p> */}
        {/* <p className="center-stuff"><i>Group/Individual Photos</i></p> */}
        </div>
        <div>
        <h4 className="center-stuff">COCKTAILS</h4>
        <strong className="center-stuff">5:30 PM</strong>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff">FORMAL DINNER</h4>
        <strong className="center-stuff">6:30 PM - 10:00 PM</strong>
        <p className="center-stuff"><i>Canada Room</i></p>
        <p className="center-stuff"><i>Adults and children 10+</i></p>
        {/* Canada Room (children's entertainment available) */}
        </div>
        <div style={{margin:"5px"}}>
        <h4 className="center-stuff">BABYSITTING</h4>
        <strong className="center-stuff">5:00 PM - 11:00 PM</strong>
        <p className="center-stuff"><i>Ontario and Manitoba Rooms</i></p>
        <p className="center-stuff"><i>Early Dinner, Activities and Movie</i></p>
        </div>
        <br/>
        </>
      }
      {sun &&
        <>
        <h2 className="center-stuff">Sunday, May 12, 2024</h2>
        <div className="center-stuff">
        <i>
        (See Also:&nbsp;<a href="#" onClick={toActivities}>Activities & Attractions</a>)
        </i>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black",paddingBottom:"15px"}}>
        <h4 className="center-stuff">BUFFET BREAKFAST</h4>
        <strong className="center-stuff">7:00 AM - 9:00 AM</strong>
        </div>
        {/* <div style={{borderBottom: "1px solid black",margin:"5px",paddingBottom:"15px"}}>
        <h4 className="center-stuff">MOTHER'S DAY BRUNCH</h4>
        <strong className="center-stuff">11:00 AM - 1:00 PM</strong>
        </div> */}
        <p className="center-stuff">
        <i>Free time</i>
        </p>
      </>
      }
    </div>
    {/* <div className="center-stuff">
      <LoadingSpinner />
    </div> */}
    </div>
    </div>
    </div>
    </>
  );
}

export default Agenda;