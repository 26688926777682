import React from 'react';
import '../somecss.css';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_face.jpeg';
import cs3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_face_filter2.jpeg';


const LoadingSpinner = () => {

  // const randomNumber = Math.random();
  // const src = randomNumber < 0.5 ? cs : cs3;

  return (
    <div className="loading-spinner">
      <div className="spinner"></div>
      <div className="image-container">
        <img src={cs3} alt="Your Image" />
      </div>
    </div>
  );
};

export default LoadingSpinner;