import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Dropdown from '../dropDown';
import Select from 'react-select';
import CryptoJS from 'crypto-js';
import Compressor from 'compressorjs';
import { useNavigate  } from 'react-router-dom'
import {BsHouseFill, BsPersonVcard,BsFillHouseFill} from "react-icons/bs";
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import '../somecss.css'
import { sendPasswordResetEmail } from '@firebase/auth';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_riley.jpeg';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_rt.jpeg';
import LoadingSpinner from './loadingSpinner';
// firebase 
import { getStorage, ref, uploadBytes, listAll,deleteObject } from 'firebase/storage';
import { collection, addDoc, getFirestore, serverTimestamp, getDocs, query, where } from "firebase/firestore";

import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext';

function UploadArticle() {
  const { currentUser } = useAuth();
  const db = getFirestore();

    const navigate = useNavigate();
    const storage = getStorage();
    const [file, setFile] = useState();
    const folderPath = 'albums';
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [uploadString, setUploadString] = useState('Upload Image(s) of Article');

    const [articleName, setArticleName] = useState('');
    const [articleContent, setArticleContent] = useState('');
    const maxWords = 1500;

    const theSecret = process.env.REACT_APP_SECRET;

    const encryptEmail = (email) => {
      try {
        const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
        return encodeURIComponent(encryptedEmail);
      } catch (error) {
        console.error('Encryption error:', error);
        return null;
      }
    };

    function toHome() {
      navigate('/home');
    }
    function toTree() {
      navigate('/family-tree');
    }
    function toProfile() {
      navigate('/profile')
    }
    function toProfiles() {
      navigate('/profiles')
    }
    function toFaqs() {
      navigate('/faqs');
    }
    function toPhotos() {
      navigate('/album-list');
    }
    function toWall() {
      navigate('/community-wall');
    }
    function toAgenda() {
      navigate('/agenda');
    }
    function toStories() {
      navigate('/stories');
    }
    function toLogin() {
      navigate('/login');
  }


  const [albumTag, setAlbumTag] = useState('Family');

  const branchOptions = [
    { value: 'default', label: 'Family' },
    { value: 'historical', label: 'Historical' },
    { value: 'past-events', label: 'Past Events'},
    { value: 'other', label: 'Other' },
  ];
  const handleOptionChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    if (selectedOption && (selectedOption.value === 'default')) {
      setAlbumTag('Family');
      }  if (selectedOption && (selectedOption.value === 'historical')) {
        setAlbumTag('Historical');
      }    if (selectedOption && (selectedOption.value === 'past-events')) {
        setAlbumTag('Past-Events');
      }  if (selectedOption && (selectedOption.value === 'other')) {
        setAlbumTag('Other');
      }      
      else {
        // Do something for other options
        console.log('Option is not any of the listed ones');
        // Perform action Y
      }
  };

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    function backToArticles() {
        navigate('/stories');
    }
    function goHome(){
      navigate('/home');
    }
    function toProfile(){
      navigate('/profile');
    }

    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    function toLogin() {
      navigate('/login');
  }
  function toSignUp() {
    navigate('/login');
}
const [buttonDisabled, setButtonDisabled] = useState(true);
const [uploadProgress, setUploadProgress] = useState(0);

const compressWithQuality = (file, quality, resolve, reject) => {
  new Compressor(file, {
    quality: quality,
    maxWidth:2000,
    // maxheight:500,
    success(result) {
      console.log('Original file size:', file.size, 'bytes');
      console.log('Compressed file size:', result.size, 'bytes');
      resolve(result);
    },
    error(error) {
      reject(error);
    },
  });
};
const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes

    if (fileSizeMB > 10) {
      // Compress with quality 0.4 for files larger than 5 MB
      compressWithQuality(file, 0.8, resolve, reject);
      console.log('over 10')
    } else if (fileSizeMB > 5) {
      // Compress with quality 0.4 for files larger than 5 MB
      console.log('over 5')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 2) {
      // Compress with quality 0.6 for files between 2 MB and 5 MB
      console.log('over 2')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 1) {
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      console.log('over 1mb')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.8) {
      console.log('over 0.8mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.6) {
      console.log('over 0.6mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.4) {
      console.log('over 0.4mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else {
      console.log('under 0.4mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    }
  });
};

// new handleFileChange for ONE PHOTO only:
const handleFileChange = async (event) => {
    // make logic that ensures only images are uploaded!
    const file = event.target.files[0];
    try {
      const compressedFile = await compressImage(file);
      setSelectedFile(compressedFile);
      setButtonDisabled(false);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };
  const handlePdfChange = async (event) => {
    // make logic that ensures only images are uploaded!
    const file = event.target.files[0];
    setSelectedPdf(file);
  };
  const handleImageChange = async (event) => {
    // make logic that ensures only images are uploaded!
    const file = event.target.files[0];
    try {
      const compressedFile = await compressImage(file);
      setSelectedImage(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };
  // handling multiple images:
  const handleImages = async (e) => {
    const newFiles = Array.from(e.target.files);
    try {
      const compressedFiles = await Promise.all(
        newFiles.map(async (file) => {
          if (file.type.startsWith('image/')) {
            // Check if the file is an image
            return await compressImage(file);
          } else {
            console.warn('Skipping non-image file:', file.name);
            return file; // If not an image, return the original file
          }
        })
      );
      // Update files with the compressed files after processing
      setSelectedImages((prevFiles) => [...prevFiles, ...compressedFiles]);
    } catch (error) {
      console.error('Error compressing images:', error);
    } finally {
      console.error('Everything seems fine');
    }
  };
  // 

  const uploadFileToStorage = async (file) => {
    // Check if a file is selected
    if (file) {
      try {
        setLoading(true);

        const deleteRef = ref(storage, `articles/${currentUser.email}/${articleName}`);
        const storageRef = ref(storage, `articles/${currentUser.email}/${articleName}/${file.name}`);
        // DELETE ANY PHOTOS
        const listResult = await listAll(deleteRef);
        const deletePromises = listResult.items.map((item) => deleteObject(item));
        await Promise.all(deletePromises);
        // UPLOAD PHOTO
        await uploadBytes(storageRef, file);
        // alert('File uploaded successfully!');
        toStories();
        window.location.reload();
        // setLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file:', error);
      }
    } else {
      // Handle case where no file is selected
    //   alert('Please select a file to upload.');
    }
  // pushEdit function resumes:
  await uploadFileToStorage(selectedFile);
};
const uploadArticleImage = async (file) => {
  // Check if a file is selected
  if (selectedImages.length>0) {
    try {
        setLoading(true);
          const uploadPromises = selectedImages.map((file) => {
            const storageRef = ref(storage, `articles/${currentUser.email}/articleimages/${articleName}/${file.name}`);
            return uploadBytes(storageRef, file);
          });
          await Promise.all(uploadPromises)
          console.log('Files uploaded successfully!');
      
    } catch (error) {
      console.error('Error uploading files:', error);
      setLoading(false);
      alert('Error uploading files:', error);
    }finally {
      setLoading(false);
    }
  } else {
    // Handle case where no file is selected
    setLoading(false);
  //   alert('Please select a file to upload.');
  }
// pushEdit function resumes:
await uploadArticleImage(selectedImages);
};

const uploadPdf = async () => {
  // Check if a file is selected
  if (selectedPdf) {
    if(currentUser){
    const encryptedEmail = encryptEmail(currentUser.email);
    const emailHash = CryptoJS.SHA256(currentUser.email).toString(CryptoJS.enc.Hex);
    try {
      setLoading(true);
      // Construct reference to the PDF file
      const pdfRef = ref(storage, `articles/${emailHash}/${articleName}/pdf/${selectedPdf.name}`);
      // Upload the PDF file
      await uploadBytes(pdfRef, selectedPdf);
      console.log('PDF uploaded successfully!');
    } catch (error) {
      console.error('Error uploading pdf:', error);
      // You might want to handle the error here
    } finally {
      setLoading(false);
    }
  }
  else{
    alert('log in, please');
  }
  } else {
    // Handle case where no file is selected
    console.log('Please select a file to upload.');
  }
};
// await uploadPdf(selectedPdf);

// upload content/metada to firestore:
const uploadArticle = async () => {

    try {
      if(selectedImage) {
        const sanitizedContent = "";
      }
      const sanitizedContent = articleContent.replace(/\n/g, "___LINE_BREAK___");
      

      const postData = {
        title: articleName,
        author:currentUser.email,
        timestamp: serverTimestamp(),
        content:sanitizedContent,
      };
    //   const docRef = await addDoc(collection(db, "wall-test"), postData);

      const docRef = await addDoc(collection(db, `articles`), postData);
      console.log("Post added with ID: ", docRef.id);
      setArticleContent('')
    } catch (error) {
      console.error("Error adding post: ", error);
    }
  };


  // const handleKeyDown = (e) => {
  //   if (e.key === 'Tab') {
  //     e.preventDefault();
  //     const { selectionStart, selectionEnd } = e.target;
  //     const newText = `${articleContent.substring(0, selectionStart)}  ${articleContent.substring(selectionEnd)}`;
  //     // setText(newText);
  //     setArticleContent(newText);

  //   }
  // };

// dfgdfgdfg
const handleArticleName = (e) => {
  setArticleName(e.target.value);
};
// const handleArticleContent = (e) => {
//     setArticleContent(e.target.value);
//   };
  const handleArticleContent = (e) => {
    const newText = e.target.value;
    const currentWordCount = countWords(articleContent);
    const newWordCount = countWords(newText);

    // Check if adding the new text would exceed the word limit
    if (newWordCount <= maxWords) {
      setArticleContent(newText);
    } else if (currentWordCount < maxWords) {
      // If the user tries to exceed the limit by pasting a large text, truncate the input
      const remainingWords = maxWords - currentWordCount;
      setArticleContent(newText.split(/\s+/).slice(0, remainingWords).join(' '));
    }
  };

  const countWords = (text) => {
    const words = text.trim().split(/\s+/);
    return words.length;
  };

  const isWithinWordLimit = () => {
    return countWords(articleContent) <= maxWords;
  };

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

const handleUpload = async () => {
  if (!selectedImages && articleContent.trim().length === 0) {
    alert('Please write an article or upload an image of one');
    return; // Stop further execution
  }
  if (articleName.trim().length === 0) {
      alert('Please enter a title before uploading.');
      return; // Stop further execution
    }
    if(selectedImages.length>0 && articleContent.trim().length > 0){
      alert('No uploading text and an image of your article. Pick one')
      return;
    }
    if (articleName.includes('/')) {
      alert(`Please don't use "/" ...I won't let you`);
      return;
    }
  try {
    const q = query(collection(db, 'articles'), where('title', '==', articleName));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.size > 0) {
      // At least one document with title containing "test" exists
      console.log('Found at least one document with title containing "test"');
    alert('Upload an article with a NEW title... you already used that one')
    } else {
      setLoading(true);
      // No documents with title containing "test" found
      console.log('looking good...');
      if(selectedImages) {
        uploadArticle();
        uploadFileToStorage();
        uploadArticleImage();
        uploadPdf();
      } else {
        uploadPdf();
        uploadArticle();
        uploadFileToStorage();
        // if(selectedPdf){
          // console.log('tryna upload pdf?...');
        // }
      }
    }
  } catch (error) {
    console.error('Error checking for "test" title:', error);
  }
  // 
}
// newprocessfile:
const processFile = (file) => {
    return new Promise((resolve) => {
      // Simulating file processing with a timeout
      const processingInterval = setInterval(() => {
        const progress = Math.floor(Math.random() * 100); // Simulated progress, adjust as needed
        setUploadProgress(progress);
  
        if (progress === 100) {
          clearInterval(processingInterval);
          resolve(); // Resolve the promise when processing is done
        }
      }, 500); // Simulated processing time, adjust as needed
    });
  };


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 250, // You can adjust this value as needed
  }),
};
const [showScreenshot,setShowScreenshot] = useState(false);
function toggleScreenshot() {
  setShowScreenshot(!showScreenshot);
  if(!showScreenshot) {
    setUploadString('Write an article')
  } else {
    setSelectedImage(null);
    setSelectedImages([]);
    setUploadString('Upload image(s) of article')
  }
}
  return (
    <>
    {theWidth>750 ?
    <div>
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfiles} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toStories} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Stories</div>
    </div>
    </div>
    {/* <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} /> */}
    </div>
    :
    <>  
      <div onClick={goHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
      <Dropdown handleBoolChange={handleBoolChange} />
    </>
}
        <div className="title">
            <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
            Riley Reunion
         </div>
    </div>   
    {/* {JSON.stringify(selectedImages)} */}
    <div className={loading ? "blur-it" : ""}>
    <div>
    <div className="center-stuff">
    <div style={{marginTop:"15px",cursor:"pointer"}}>
      {!loading && <button className="read-more-button" onClick={backToArticles}>Back to Stories</button>}
      </div>
    </div>
    <div >
    {!currentUser 
    ? 
    <>
    <h3 className="center-stuff">First, you must:</h3>
    <div className="center-stuff">
    <button className="stories-button" onClick={toLogin}>Login</button>
    </div>
    </>
    :
    <div>
    <div>
      <div style={{ display: 'flex', flexDirection: 'column',marginTop:"0px"}}>

      <div className="center-stuff">
      <div style={{margin:"10px"}}>Article Title:</div>
      </div>
      <div className="center-stuff">
      <input style={{margin:"10px",width:"250px"}} type="text" 
      placeholder="Name your article..." value={articleName} 
      onChange={handleArticleName} 
      // onKeyDown={handleKeyDown} 
      />
      </div>

      <div className="center-stuff"><div style={{margin:"10px"}} >Upload a Photo to go along with your article:</div></div>
            <div className="center-stuff">
        <label htmlFor="fileInput" className="stories-button">
    Choose Photo
    <input
        id="fileInput"
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }} 
    />
    </label>
      </div>
      <div className="center-stuff">
      {selectedFile && selectedFile.name}
      </div>
      {/* optional upload pdf option: */}
      <div className="center-stuff"><div style={{margin:"10px"}} >Attach a PDF (optional):</div></div>
            <div className="center-stuff">
        <label htmlFor="pdfInput" className="stories-button">
    Choose PDF
    <input
        id="pdfInput"
        type="file"
        onChange={handlePdfChange}
        // accept="pdf/*"
        accept="application/pdf"
        style={{ display: 'none' }}
    />
    </label>
      </div>
      <div className="center-stuff">
      {selectedPdf && selectedPdf.name}
      </div>
      {/* ending optional pdf logic */}
      
      {/* upload logic for screenshot: */}
      {showScreenshot &&
      <>
        <div className="center-stuff"><div style={{margin:"10px"}} >Upload Image(s) of the article:</div></div>
              <div className="center-stuff">
          <label htmlFor="imageInput" className="stories-button">
      Choose Image(s)
      <input
          id="imageInput"
          type="file"
          onChange={handleImages}
          accept="image/*"
          style={{ display: 'none' }}
          multiple
      />
      </label>
        </div>
        <div className="center-stuff">
        {/* {selectedImage && selectedImage.name} */}
        </div>
        {/* for multiple images: */}
        {selectedImages.length > 0 && (
        <>
        <div className="center-stuff">Article images:{' '}{`(${selectedImages.length} total)`}</div>
        <div className="center-stuff">
        <p style={{margin:"10px"}}>
          {selectedImages
            .slice(0, 3)
            .map((file, index) => file.name)
            .join(', ')}
          {selectedImages.length > 3 && ` ... (the remaining ${selectedImages.length-3} etc.)`}
        </p>
        </div>
        </>
      )}
        {/*  */}
        </>
      }
      {/* upload logic done */}
      <div className="center-stuff">
      <div style={{marginTop:"10px"}}>
      <button className="upload-button" disabled={buttonDisabled} onClick={handleUpload}>Upload</button>
      </div>
      </div>

      <div className="center-stuff">
      {/* {articleContent} */}
      {/* <div style={{margin:"10px"}}>Article Content:</div> */}
      <div style={{marginLeft:"70%"}}>
        {/* Word count: {countWords(articleContent)} / {maxWords} */}
        {countWords(articleContent)===maxWords ?<div className="pulse-text">WORD COUNT REACHED</div> :<>Word count: </> }
        <div className="center-stuff">
        {articleContent ? countWords(articleContent) : 0} / {maxWords}
        </div>
      </div>
      </div>
      {/* reinstate this code for pictures: */}
      {/* <div style={{marginLeft:"15px",marginBottom:"15px"}}>Or: <button style={{cursor:"pointer",boxShadow:"0px 0px 3px 0px"}} onClick={toggleScreenshot}>{uploadString}</button></div> */}
      {!showScreenshot && 
          <div className="center-stuff">
          {/* <input style={{margin:"10px",width:"50%"}} type="text" placeholder="Name your article..." /> */}
          <textarea rows="4" cols="50" placeholder="Type your article here..." className="article-content"
            value={articleContent} onChange={handleArticleContent}
        />  
          </div>
      }
      </div>
    </div>
    </div>
    }
    </div>
    
    </div>
    
    </div>
    
    <br/>
    {loading && 
    <>
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    </>
    }
    </>
  );
}

export default UploadArticle;