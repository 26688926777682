import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../dropDown';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import { useNavigate, useParams  } from 'react-router-dom'
import { collection, addDoc, getFirestore, onSnapshot, getDoc, getDocs, doc, updateDoc } from "firebase/firestore";
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import {BsFillHouseFill,BsCheckCircleFill,BsXCircleFill} from "react-icons/bs";
// import { functions } from 'firebase/app';
import { getFunctions,httpsCallable } from 'firebase/functions';
import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';




 function Should() {

  // profile-pics - > email - > file
  const db = getFirestore();
  const functions = getFunctions(app);
  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();
  // const sendItFunction = functions.httpsCallable('sendItFunction');
  const sendItFunction = httpsCallable(getFunctions(), 'sendItFunction')

//   const [userData, setUserData] = useState({});
  const [theWidth, setTheWidth] = useState(window.innerWidth);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailReset, setEmailReset] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(()=>{
    const handleResize = () => {
      setTheWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // 
    // 
  },[]);

  // 
  const oldSendIt = async () => {
    if (!name.trim() || !email.trim()) {
        // Show an error message or handle empty fields
        console.error('Name or email cannot be empty');
        setError(true);
        setErrorMessage("Input text, please");
        return;
      }
    try {
      const userData = {
        theName: name,
        theEmail: email,
      };
      const dataRef = collection(db, 'newEmails');
      setLoading(true);
      await addDoc(dataRef, userData);
      console.log('Document added successfully!');
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error adding document:', error);
      setError(true);
      setErrorMessage("Error adding document:', error");
    }
  };

  const sendIt = async () => {
    try {
      const result = await sendItFunction({ name, email });
  console.log('success');
  window.location.reload();
      // ... rest of your code ...
    } catch (error) {
      console.error('Error calling function:', error);
      setError(true);
      setErrorMessage(`Error calling function: ${error.message}`);
    }
  };

  function updatePhoto() {
    // setEnlarged(true);
  }
  function expandPhoto() {
    // setEnlarged(true);
  }
  function shrinkPhoto() {
    // setEnlarged(false);
  }
  function toHome() {
    navigate('/home');
  }
  function toTree() {
    navigate('/stories');
  }
  function toProfile() {
    navigate('/profile')
  }
  function toFaqs() {
    navigate('/faqs');
  }
  function toPhotos() {
    navigate('/album-list');
  }
  function toWall() {
    navigate('/community-wall');
  }
  function toAgenda() {
    navigate('/agenda');
  }
  function toTree() {
    navigate('/family-tree');
  }
  function toLogin() {
    navigate('/login');
}

const handleSubmit = (event) => {
    event.preventDefault();
    alert('toss in a setSomething to trigger loading and "email sent" message?')
  };

  // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

  return (
    <> 
      <div>
          <div className="center-stuff"> 
            <form onSubmit={sendIt}>
            {/* <div className="title">
            <div className="the-titles" >Not On The List?</div>
          </div>    */}
                <div className="center-stuff">
                    <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"10px"}}>Name:</div>
                </div>
                <div className="center-stuff">
                    <input type="text" value={name} onChange={handleNameChange} /> 
                </div>
                <div className="center-stuff">
                    <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"10px"}} >Email:</div> 
                </div>            
                <div className="center-stuff">
                    <input type="email" value={email} onChange={handleEmailChange} />
                </div>
                <div className="center-stuff">
                    <button type="submit" style={{cursor:"pointer",margin:"15px"}}>Submit</button>
                </div>
                <div className="center-stuff">
                    {
                      error && 
                      <div className="center-stuff">
                          <div style={{backgroundColor:"white", paddingBottom:"10px"}}>
                              <BsXCircleFill style={{color:"maroon", marginRight:"10px"}} />{errorMessage}<BsXCircleFill style={{color:"maroon",marginLeft:"10px"}} />
                          </div>
                      </div>
                    }
                </div>
            </form>
            {
        success && 
        <div style={{backgroundColor:"white",marginTop:"25px",padding:"15px"}}>
        <div className="center-stuff">
        <BsCheckCircleFill style={{color:"green", marginRight:"10px"}} />Thank you!<BsCheckCircleFill style={{color:"green",marginLeft:"10px"}} />
        </div>
        <div className="center-stuff"><div style={{margin:"10px"}}>Expect an email</div></div>
        </div>
      }
      {
        error && 
        <div className="center-stuff">
            <div style={{backgroundColor:"white",padding:"10px",marginTop:"20px"}}>
                {/* <BsXCircleFill style={{color:"maroon", marginRight:"10px"}} />{errorMessage}<BsXCircleFill style={{color:"maroon",marginLeft:"10px"}} /> */}
            </div>
        </div>
      }
            </div>         
          </div>  
    </>
  );
}
export default Should;