import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Dropdown from '../dropDown';
import Comments from './wallComments';
import {BsHouseFill, BsPersonVcard,BsVectorPen, BsCalendar2Day, BsImages, BsDiagram3, BsBook,BsFillHouseFill} from "react-icons/bs";
import { useNavigate  } from 'react-router-dom'
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import paint from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-7.jpeg';
// firebase firestore stuff:
import { collection, addDoc, getDoc, doc, getFirestore, serverTimestamp } from "firebase/firestore";

function CommunityWall() {
  const db = getFirestore();

  const navigate = useNavigate();
  const { logout, currentUser } = useAuth();

  function toHome() {
    navigate('/home');
  }
  function toAgenda() {
    navigate('/agenda');
  }
  function toTree() {
    navigate('/stories');
  }
  function toProfile() {
    navigate('/profiles')
  }
  function toFaqs() {
    navigate('/faqs');
  }
  function toPhotos() {
    navigate('/album-list');
  }
  function toWall() {
    navigate('/community-wall');
}
function toLogin() {
  navigate('/login');
}

  const [theWidth, setTheWidth] = useState(window.innerWidth);

  const [userComment, setUserComment] = useState('');

  const handleCommentChange = (event) => {
    setUserComment(event.target.value);
  };

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // turn shortcuts to hamburger at 700px?

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

// FIRESTORE CRUD (TESTS):
const [testData, setTestData] = useState("");
const handleTestChange = (event) => {
  setTestData(event.target.value);
};

function testIt(){
  alert(testData);
}

const uploadComment = async () => {
  if (userComment.trim().length === 0) {
    // If userComment is empty or contains only whitespace characters
    alert('Please enter a comment before uploading.');
    return; // Stop further execution
  }
  // alert(userComment);
  try {
    const postData = {
      comment: userComment,
      user:currentUser.email,
      timestamp: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, "wall-test"), postData);
    console.log("Post added with ID: ", docRef.id);
    // I could have a time-delay and trigger the loading icon in wallComments...
    
    // const timer = setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
    // return () => {
    //   setLoading(false);
    // };
    setUserComment('')
  } catch (error) {
    console.error("Error adding post: ", error);
  }
};

function testy() {
  // alert('dfg');
}

// firebase storage testing:
// const downloadPhoto = async (photoPath) => {
//   try {
//     const photoRef = ref(storage, photoPath);
//     const downloadURL = await getDownloadURL(photoRef);
    // Use the downloadURL to display or download the photo
//     console.log('Download URL:', downloadURL);
//   } catch (error) {
//     console.error('Error downloading photo:', error);
//   }
// };

// props to change button zIndexes in wallComments... lol
const [boolValue, setBoolValue] = useState(false);

  const handleBoolChange = (newValue) => {
    setBoolValue(newValue);
  };

  const [loadValue, setLoadValue] = useState(false);
  const [more, setMore] = useState(false);

  const handleLoadChange = (newValue) => {
    setLoadValue(newValue);
  };
  const handleMoreThanThree = (newValue) => {
    setMore(newValue);
  };

  // useEffect(() => {
  //   if(theWidth>700) {
  //     setBoolValue(false);
  //   }
  // },[])
  // fetching usernames to replace currentUSer.email:
  const [theUsername, setTheUsername] = useState('');

  // useEffect(() => {
  //   const fetchUserProfile = async () => {
  //     try {
  //       if (currentUser) {
  //         const userDocRef = doc(db, 'profiles', currentUser.email);
  //         const userDocument = await getDoc(userDocRef);

  //         if (userDocument.exists()) {
  //           // Document found, extract and set the username to the state
  //           const username = userDocument.data().username;
  //           setTheUsername(username);
  //         } else {
  //           // Document not found, handle accordingly
  //           console.log('User profile not found');
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user profile:', error);
  //     }
  //   };

  //   fetchUserProfile();
  // }, [db, currentUser]);
  return (
    <>
    {/* <br/> */}
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    {/* <div style={{position:"absolute",left:"4%", top:"1.5%",}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div style={{position:"absolute",left:"14%", top:"1.5%",}}>Profile</div>
    <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",left:"15%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"3.5%", top:"1.5%",}}>Photos</div>
    <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"13.3%", top:"1.5%",}}>Agenda</div>
    <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"24.5%", top:"1.5%",}}>Tree</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} /> */}
    </div>
    :
    <div>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange}  /></div>
    }
    <div className="title"> 
    {/* <h1 className="the-titles">Community Wall</h1> */}
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>
    <div style={{
          // backgroundImage: `url(${paint})`,
          // backgroundColor: '#0078d0',
          // backgroundColor: '#9DC5E0',0078d0
          backgroundColor: 'white',
          // background: 'linear-gradient(to bottom, #9DC5E0, #9DC5E0)',
          backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        // height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
        // height:'100vh',
        height:more===true ? "" : "100vh",
             }}>

    {/* <div style={{position:"absolute",top:"150px",left:"5%"}}><div className="stylized-cursive">Riley</div></div> */}
    {/* <div style={{position:"absolute",top:"150px",right:"5%"}}><div className="stylized-cursive">Reunion</div></div> */}
    <div className="center-stuff"
    // style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",}}
    >
    <div className={theWidth > 1150 || theWidth < 530 ? "wall-create-post" : "wall-create-post-small"}>
    <div style={{marginTop:"5px"}}>
    {!currentUser ?
      <div className="center-stuff">
      <div className="wall-post-prompt">
      {/* make the "true" below into !loading? */}
      {true && <><div className="center-stuff">
      <div>You must&nbsp;<button className="stories-button" style={{cursor:"pointer",zIndex:boolValue!=true || theWidth>700 ? 1 : -1}} onClick={toLogin}>Login</button>&nbsp;in order to post</div>
      </div></>
      }
      </div>
      </div>
      :
      <>
      <div className="center-stuff">
      <div className="wall-post-prompt">
      <span className="center-stuff"><div style={{margin:"10px",fontFamily: 'Helvetica, sans-serif',fontSize: '16px',color: '#333',fontWeight: 'bold',}}>Make a post!</div></span>
    <textarea rows="4" cols="50" placeholder="Type here..." className="question"
        value={userComment}
        onChange={handleCommentChange}
    />  
        { !loadValue ? <div className="center-stuff">
        <div style={{marginTop:"5px",zIndex:boolValue!=true || theWidth>700 ? 1 : -1}}>
        <button className="stories-button" onClick={uploadComment}>Submit</button> 
        </div>
        </div> : <><br/><br/></>}
    </div>
    </div>  
    {/* </div> */}
      </>
    }
    </div>
    {/* <h3 style={{marginLeft:"50px"}}> Past Posts:</h3> */}
    <Comments moreThanThree={handleMoreThanThree} loadChange={handleLoadChange} boolValue={boolValue}  />
    </div>
    {/* <input onChange={handleTestChange} /> */}
    {/* <button onClick={addPost}>Test Write</button> */}
    {/* <button>Load More</button> */}
    {/* PAST POSTS */}
    </div>
    </div>
    </>
  );
}

export default CommunityWall;