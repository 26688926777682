// NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';
import eye3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/eye-3.jpeg';

const NotFound = () => (
  <div>
    <div className="center-stuff">
    <h2>Page Not Found</h2>
  </div>
    <div className="center-stuff">
    <p>Sorry, there's nothing on this page.</p>
  </div>
    <div className="center-stuff">
    <Link to="/home">Go to Home Page</Link>
  </div>
  <div className="center-stuff">
    <img style={{maxWidth:"100%",marginTop:"15px",borderRadius:"0%"}} src={eye3} />
    </div>
  </div>
);

export default NotFound;