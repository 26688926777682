import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
// import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button, Alert, Spinner, Badge } from 'react-bootstrap'
import Dropdown from '../dropDown';
import { useNavigate  } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import goodfellasPainting from './goodfellas_painting.jpg';
// import garcia from './garcia_74.jpeg'
// import dimon from './jamie_dimon.jpeg'
import '../unused logic/carouselStyles.css'
import '../somecss.css'
import { collection, addDoc, getDoc, doc, getFirestore, serverTimestamp } from "firebase/firestore";

import beer from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/beer_meme.jpeg';
// import reunion81 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_1981.png'
import reunion_filter1 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_1981.png'
import reunion_filter2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_1981_filter.jpeg'
import reunion_filter3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_1981_filter6.jpeg'

import goodfellasPainting from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/goodfellas_painting.jpg';
import garcia from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/garcia_74.jpeg';
import waves from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/wave_sunset.jpeg';
import clouds from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/clouds.jpg';
import stripes from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/stripes.png';
import sdyorkshire from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_yorkshire.jpeg';
import sdimage from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_home_3.jpeg';
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import cs_face from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_face.jpeg';
import letter_r from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/letter_r.jpeg';
import riley_clan from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_clan.png';
import welcome from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_welcome.png';
import eye from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/eye-1.jpeg';
import eye2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/eye-3.jpeg';
import eye3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/eye-4.jpeg';
// crows:
import crow from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-28.jpeg';
import crow2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-21.jpeg';
import crow3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-23.jpeg';
import crow4 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-5.jpeg';
import crow5 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-8.jpeg';
import crow6 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-14.jpeg';
import crow7 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-15.jpeg';
import crow8 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-24.jpeg';
import crow9 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-16.jpeg';
import crow10 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-19.jpeg';
import crow11 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-20.jpeg';
import crow12 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-22.jpeg';
import crow13 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/crow-28.jpeg';
import beau from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/beauchesne.jpeg';
import pam from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/pam_landy_compressed.png';
import signature from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sig-newest.png';

import bourne from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/ultimatum.mp4';


// import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/rt_head.png';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_ai_sign2.jpeg';




import { BsXCircle } from "react-icons/bs";
import { FaDivide } from 'react-icons/fa';

    // https://commons.wikimedia.org/wiki/File:Elyu_3.jpg
    // https://commons.wikimedia.org/wiki/File:Clouds_in_Russia._img_423.jpg

    // CSS pallettes:
    // https://palettes.shecodes.io/palettes/267#palette
    // https://palettes.shecodes.io/palettes/615#palette
    // https://palettes.shecodes.io/palettes/6
    // https://palettes.shecodes.io/palettes/569
    // https://palettes.shecodes.io/palettes/582

function JeanHome() {
  const db = getFirestore();

  const { logout, currentUser } = useAuth();


  const [prompt, setPrompt] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);

    // const email = currentUser.email;
    // const firstTwoChars = email.slice(0, 8);
    // const lastTwoChars = email.slice(-8);
    // const maskedEmail = `${firstTwoChars}...`;

    const logOut = async () => {
      try {
        await logout()
      } catch(error) {
        alert("Failed to log out", error.message)
      }
    }
// navigate stuff below:
    const navigate = useNavigate();

    function toTree() {
        navigate('/stories');
    }
    function toAgenda() {
        navigate('/agenda');
    }
    function toActivities() {
      navigate('/activities-and-attractions');
  }
    function toPhotos() {
        navigate('/album-list');
    }
    function toFaqs() {
        navigate('/faqs');
    }
    function toWall() {
        navigate('/community-wall');
    }
    function toSignUp() {
      navigate('/sign-up');
  }
  function toLogin() {
    navigate('/login');
}

// icons in top right
    function toHome(){
        navigate('/home');
      }
      function toProfile(){
        navigate('/profiles');
      }
      function toYourProfile(){
        navigate('/profile');
      }


      // picture resizing etc.
    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const x = theWidth/13.5;
const y = 50;

const [showImage, setShowImage] = useState(true);
const [reunionImage, setReunionImage] = useState(reunion_filter1);
const [nextImage, setNextImage] = useState('');
  const [transitioning, setTransitioning] = useState(false);

// hover underline styles and logic

const [agendaHover, setAgendaHover] = useState(false);
const [photosHover, setPhotosHover] = useState(false);
const [treeHover, setTreeHover] = useState(false);
const [historyHover, setHistoryHover] = useState(false);
const [wallHover, setWallHover] = useState(false);
const [profileHover, setProfileHover] = useState(false);
const [loaded, setLoaded] = useState(false);

const [shouldRender, setShouldRender] = useState(false);

// const [] = useState(false);
// const [] = useState(false);

const agendaEnter = () => {
  setAgendaHover(true);
};
const photosEnter = () => {
  setPhotosHover(true);
};
const treeEnter = () => {
  setTreeHover(true);
};
const historyEnter = () => {
  setHistoryHover(true);
};
const wallEnter = () => {
  setWallHover(true);
};
const profileEnter = () => {
  setProfileHover(true);
};
// mouseleave can be the same for all
const handleMouseLeave = () => {
  setAgendaHover(false);
  setPhotosHover(false)
  setTreeHover(false);
  setHistoryHover(false);  
  setWallHover(false);
  setProfileHover(false);
};
const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

// slide in effects...
const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Set a timeout to trigger the slide-in effect after a delay (e.g., 500ms)
  const timeout = setTimeout(() => {
    setIsVisible(true);
  }, 500);

  // Clear the timeout to avoid memory leaks
  return () => clearTimeout(timeout);
}, []);

useEffect(() => {
  // Set a timeout to trigger the slide-in effect after a delay (e.g., 500ms)
  const timeout = setTimeout(() => {
    setShowWelcome(true);
  }, 100);

  // Clear the timeout to avoid memory leaks
  return () => clearTimeout(timeout);
}, []);

// image loop logic/state:
const [imageIndex, setImageIndex] = useState(0);
const images = [eye, eye2, eye3];
const crows = [pam,beau,crow4,crow5,crow5,crow6,crow, crow2];

useEffect(() => {
  const intervalId = setInterval(() => {
    // Update the image index every 10 seconds
    setImageIndex((prevIndex) => (prevIndex + 1) % crows.length);
  }, 10000);

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
}, []);

// fetch username:
const [theUsername, setTheUsername] = useState('');

useEffect(() => {
  const fetchUserProfile = async () => {
    try {
      if (currentUser) {
        const userDocRef = doc(db, 'profiles', currentUser.email);
        const userDocument = await getDoc(userDocRef);

        if (userDocument.exists()) {
          // Document found, extract and set the username to the state
          const username = userDocument.data().username;
          setTheUsername(username);
        } else {
          // Document not found, handle accordingly
          console.log('User profile not found');
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  fetchUserProfile();
}, [db, currentUser]);

const nameArray = theUsername.split(' ');
const firstName = nameArray[0];

const allowedEmails = [];
const srcValue = allowedEmails.includes(currentUser?.email) ? crows[imageIndex] : reunionImage;

// testing greetings styles:
// useEffect(() => {
//   const container = document.querySelector('.date-container');

//   // Set a timeout to remove the neon effect after 2 seconds
//   const timeoutId = setTimeout(() => {
//     container.style.animation = 'none'; // Remove the animation
//   }, 2000);

//   return () => {
//     clearTimeout(timeoutId);
//   };
// }, []);
const videoRef = useRef(null);
// useEffect(() => {
//   const playPromise = videoRef.current.play();
//   // Handling autoplay restrictions
//   if (playPromise !== undefined) {
//     playPromise.then(_ => {}).catch(error => {
//       // Autoplay was prevented, show controls as a fallback
//       videoRef.current.controls = true;
//     });
//   }
// }, []);

// start halfway through:
useEffect(() => {
  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      const startAtTime = videoRef.current.duration / 4.2;
      videoRef.current.currentTime = startAtTime;

      // Play the video
      const playPromise = videoRef.current.play();

      // Handling autoplay restrictions
      if (playPromise !== undefined) {
        playPromise.then(_ => {}).catch(error => {
          // Autoplay was prevented, show controls as a fallback
          videoRef.current.controls = true;
        });
      }

      // Remove the event listener to avoid setting currentTime multiple times
      videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
    }
  };

  // Set up event listener for loadedmetadata
  if (videoRef.current) {
    videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
  }

  // Cleanup: Remove event listener when the component unmounts
  return () => {
    if (videoRef.current) {
      videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
    }
  };
}, []);

useEffect(() => {
  if (currentUser && !prompt) {
    setTimeout(() => {
      setShouldRender(true);
    }, 0);
  }
}, [currentUser,prompt]);

const handleImageLoad = () => {
  setLoaded(true);
};


// useEffect(() => {
//   const interval1 = setInterval(() => {
//     // Update the state every five seconds with reunion_filter2
//     setReunionImage(reunion_filter2);
//   }, 7000);

//   const interval2 = setInterval(() => {
//     // Update the state after 10 seconds with reunion_filter3
//     setReunionImage(reunion_filter3);
//   }, 14000);

//   const interval3 = setInterval(() => {
//     // Update the state after 10 seconds with reunion_filter3
//     setReunionImage(reunion_filter1);
//   }, 21000);

//   // Cleanup function to clear the intervals when the component unmounts
//   return () => {
//     clearInterval(interval1);
//     clearInterval(interval2);
//   };
// }, []);

// useEffect(() => {
//   const interval1 = setInterval(() => {
//     setShowImage(false); // Start fading out
//     setTimeout(() => {
//       setReunionImage(reunion_filter2);
//       setShowImage(true); // Start fading in with the new image
//     }, 500); // Adjust the duration as needed
//   }, 5000);

//   const interval2 = setInterval(() => {
//     setShowImage(false); // Start fading out
//     setTimeout(() => {
//       setReunionImage(reunion_filter3);
//       setShowImage(true); // Start fading in with the new image
//     }, 500); // Adjust the duration as needed
//   }, 10000);

//   const interval3 = setInterval(() => {
//     setShowImage(false); // Start fading out
//     setTimeout(() => {
//       setReunionImage(reunion_filter1);
//       setShowImage(true); // Start fading in with the new image
//     }, 500); // Adjust the duration as needed
//   }, 15000);

//   // Cleanup function to clear the intervals when the component unmounts
//   return () => {
//     clearInterval(interval1);
//     clearInterval(interval2);
//   };
// }, []);

useEffect(() => {
  if(!prompt) {
  const timeout = setTimeout(() => {
    const randomNumber = Math.random();
    if (randomNumber < 0.5) {
      setReunionImage(reunion_filter3);
    } else {
      setReunionImage(reunion_filter3);
    }
  }, 4000);

  return () => clearTimeout(timeout);
  }
}, [prompt]);

// prompt stuff:

function hidePrompt() {
  setPrompt(false);
}

const [daysLeft, setDaysLeft] = useState(calculateDaysLeft());
const [secondsLeft, setSecondsLeft] = useState(calculateSecondsLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setDaysLeft(calculateDaysLeft());
      setSecondsLeft(calculateSecondsLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const totalSeconds = calculateSecondsLeft();

// Calculate days
const days = Math.floor(totalSeconds / (60 * 60 * 24));
const remainingSecondsAfterDays = totalSeconds % (60 * 60 * 24);

// Calculate hours
const hours = Math.floor(remainingSecondsAfterDays / (60 * 60));
const remainingSecondsAfterHours = remainingSecondsAfterDays % (60 * 60);

// Calculate minutes
const minutes = Math.floor(remainingSecondsAfterHours / 60);

// Calculate remaining seconds
const seconds = remainingSecondsAfterHours % 60;

// console.log(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);

  function calculateDaysLeft() {
    // Calculate the number of days until the event
    const eventDate = new Date('2024-05-10T15:00:00-04:00'); // Set event date to May 10, 2024, 3 PM EST
    const currentDate = new Date();
    const timeDifference = eventDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }

  function calculateSecondsLeft() {
    // Calculate the number of seconds until the event
    const eventDate = new Date('2024-05-10T15:00:00-04:00'); // Set event date to May 10, 2024, 3 PM EST
    const currentDate = new Date();
    const timeDifference = eventDate.getTime() - currentDate.getTime();
    return Math.ceil(timeDifference / 1000);
  }

  return (
    <>  
    <div className={prompt ? "blur-it" : ""}>

    <div>
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"17.5px",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"17.5px",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    </div>
    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"3%", top:"3px",zIndex:"-1"}}>   
     <img src={riley_clan} style={{width:theWidth<450?"35px":"50px"}} />
    </div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    <div>
    <div>
    {currentUser ? 
      <>
      <div onClick={logOut} style={{position:"absolute", right:theWidth<450?"10px":"20px", top:theWidth<450?"60px":"80px",zIndex:1,cursor:"pointer"}}>
      {/* <div className="center-stuff"> */}
      <button className="button-73">Log Out</button>
      {/* </div> */}
      </div>
      {/* <div style={{position:"absolute",top:theWidth>650 ?"0%":"5.5%", right:theWidth>650 ?"25px":"25px"}}>
      </div> */}
      </>
    :
    <>
    <div style={{position:"absolute", right:theWidth<450?"10px":"20px", top:theWidth<450?"60px":"80px",zIndex:1}}>
      <button className="button-73" onClick={toLogin}>Login / Sign Up</button>
    </div>
    <div style={{position:"absolute",top:theWidth>650 ?"0%":"5.5%", right:theWidth>650 ?"25px":"25px"}}>
    </div>
    <div onClick={toSignUp} style={{position:"absolute", right:theWidth>750 ?"50px" :"25px", top:"1%"}}>
    </div>
    <div onClick={toLogin}  style={{position:"absolute", right:theWidth>750 ?"70px":"45px", top:"5%"}}>
    </div>
    </>
    }
    </div>
    </div>
    {!currentUser && 
    <div style={{position:"absolute", top:theWidth>650 ?"21%":"30%", right:theWidth>650 ?"100px":"",left:theWidth>650?"":"50%",transform:theWidth>650?"":"translate(-50%, -50%)"}} onClick={toFaqs}>
    </div>
    }
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>
    <div style={{ display: 'flex', alignItems: 'center',justifyContent:"center"}}>
    {currentUser ?
    <>
    {allowedEmails.includes(currentUser.email) 
    ?
    <div>
      <video style={{ 
        width:"100vw" }} ref={videoRef}>
        <source src={bourne} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    :
      <img
      // onLoad={handleImageLoad}
        src={reunionImage}
        alt="Reunion Image"
        style={{ 
        width:"100vw",
        opacity: showImage ? 1 : 0,
          transition: 'blur 0.5s ease-in-out'
         }}
      />
    }
      
    </>
      :
      <img
        src={reunionImage}
        // src={srcValue}
        alt="Reunion Image"
        style={{ 
          // maxWidth: '100%', height: 'auto',
        width:"100vw" }}
      />
    }
      </div> 
      <div style={{position:"absolute", left:"20px", top:theWidth<450?"60px":"80px"}}>
      {currentUser && shouldRender &&
    <div className="date-container">
    { !theUsername ? <a onClick={toYourProfile} href="#">Complete your profile</a>:theWidth < 450 ? (<><>Hi,</><br /><>{firstName}</></>) : (<>Hi, {firstName} &#x1F496;</>)

}
    </div>}
      </div>

<div style={{
          // backgroundImage: `url(${reunion81})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height:theWidth,
             }}>   
          
             
    {theWidth>750 && theWidth<900 && false &&
      <div style={{position:"absolute", left:"50%", top:theWidth<790 ?`${theWidth/2+280}px`:"675px", transform:"translate(-50%, -50%)",zIndex:"-1"}}>   
        <img src={riley_clan} style={{width:"50px"}} />
      </div>
    }
    {/* <br/> */}
    {/* theWidth > 700 ?  (these were the styles before removing them with the below) */}
    {true ?
    <>
    </>
    :
    <div style={{position:"absolute",top:theWidth>750 ?"550px" :`${theWidth/2+210}px`,margin:"20px"}}>
    <div className="title">
    <div style={{ marginRight:50,marginLeft:50,marginBottom:25,fontFamily:"Tahoma",fontSize:"18px"}}>
    <i>“When I left England I had no definite plans
    as to what I was going to do...and no pledges, except to my mother that I would return at the end of two years”</i><br/><br/>
    - R.T. Riley</div>
    </div>
    </div>
    }
    </div>
    {showWelcome && <div style={{ display: 'flex', alignItems: 'center',justifyContent:"center"}}>
      <img src={welcome} style={{ maxWidth: '100%', height: 'auto' }} />
    </div>}
    {theWidth<450 && showWelcome &&
      <div style={{ display: 'flex', alignItems: 'center',justifyContent:"center",marginBottom:"15px"}}>
      <img src={reunionImage && signature} style={{ maxWidth: '90%', height: 'auto' }} />
    </div>}
    </div>


    {/*  */}

      </div>

    {prompt &&
     <div style={{position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%)"}}>
      {/* <LoadingSpinner /> */}
      <Card>
      <div className="countdown-prompt" style={{cursor:"default"}}>
      <div style={{marginTop:"10px", color:"maroon",cursor:"pointer", position:"fixed",right:"10%"}} onClick={hidePrompt}><BsXCircle style={{fontSize:"22px"}} /></div>
      <div style={{marginRight:"10px",marginLeft:"10px"}}>
      <div className="center-stuff">
      {/* <span style={{ marginBottom: "10px", marginTop: "50px" }}>{daysLeft} days away!</span> */}
   
      <>
        {days === 1 ? (
          <span style={{marginBottom: "10px", marginTop: "50px",marginRight:"10px",marginLeft:"10px", fontSize: "20px", color: "royalblue", fontWeight: "bold",textAlign:"center"}}>&#x1F389;&nbsp;Thanks for coming!&nbsp;&#x1F389;</span>
          ) : (
            <span style={{marginBottom: "10px", marginTop: "50px",marginRight:"10px",marginLeft:"10px", fontSize: "20px", color: "royalblue", fontWeight: "bold",textAlign:"center"}}>&#x1F389;&nbsp;Thanks for coming!&nbsp;&#x1F389;</span>
      )}
      </>
        
      </div>
      {/* <div className="center-stuff">
      <span style={{ marginBottom: "15px", marginTop: "0px" }}>To be exact:</span>
      </div> */}
      {false &&<div className="center-stuff">
        <>
        <div style={{margin:"15px", display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', padding: 0,textAlign: 'center' }}>
      <>{days===1 ? <i>{days} day,</i> : <i>{days} days,</i> }</>
      &nbsp;
      <>{hours===1 ? <i> {hours} hour,</i> : <i> {hours} hours,</i>}</> 
      &nbsp;
      <>{minutes===1 ? <i> {minutes} minute,</i> : <i> {minutes} minutes,</i>}</> 
      &nbsp;
      <>{seconds===1 ? <i> {seconds} second,</i> : <i> {seconds} seconds,</i>}</> 
      </div>
        </>
      </div>}
      <div>
      {theWidth>630 ?
      <>
      <div className="center-stuff">
      <strong>See you in 10 years?</strong>
      </div>
      <div className="center-stuff">
      <br/><br/><br/>
      {/* <a href="#" style={{fontSize:"18px",margin:"10px"}} onClick={toAgenda}>Agenda</a> */}
      </div>
      <div className="center-stuff">
      {/* <a href="#" style={{fontSize:"18px",marginBottom:"15px"}} onClick={toActivities}>Activities/Attractions</a> */}
      </div>
      </>
      :
      <>
      <div className="center-stuff">
      <strong>See you in 10 years?</strong>
      </div>
      <div className="center-stuff">
      <br/><br/><br/>
      {/* <a href="#" style={{fontSize:"18px",margin:"10px"}} onClick={toAgenda}>Agenda</a> */}
      </div>
      <div className="center-stuff">
      {/* <a href="#" style={{fontSize:"18px",marginBottom:"10px"}} onClick={toActivities}>Activities/Attractions</a> */}
      </div>
      </>
      }
      </div>
      </div>
      </div>
      </Card>
     </div> 
    }
    </>
  );
}

export default JeanHome;