// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// import firebase from 'firebase/app'
// import 'firebase/auth'
// import 'firebase/storage'
// import firestore from "firebase/firestore";

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "reunion-site-test.firebaseapp.com",
  projectId: "reunion-site-test",
  storageBucket: "reunion-site-test.appspot.com",
  messagingSenderId: "790829930712",
  appId: "1:790829930712:web:6f739e33d6865faf2bdfad"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const db = getFirestore();

export default app;