import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
// import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button, Alert, Spinner, Badge } from 'react-bootstrap'
import Dropdown from '../dropDown';
import { useNavigate  } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import goodfellasPainting from './goodfellas_painting.jpg';
// import garcia from './garcia_74.jpeg'
// import dimon from './jamie_dimon.jpeg'
import '../unused logic/carouselStyles.css'
import '../somecss.css'

import dimon from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/jamie_dimon.jpeg'
import goodfellasPainting from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/goodfellas_painting.jpg';
import garcia from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/garcia_74.jpeg';
import waves from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/wave_sunset.jpeg';
import clouds from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/clouds.jpg';
import stripes from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/stripes.png';
import sdyorkshire from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_yorkshire.jpeg';
import sdimage from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_home_3.jpeg';
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import cs_face from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_face.jpeg';

// import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/rt_head.png';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_ai_sign2.jpeg';




import { BsImages, BsPersonFill, BsVectorPen, BsTreeFill, BsCalendar2Day,BsFillHouseFill,
    BsHouseFill, BsFillDiagram3Fill, BsDiagram3, BsFillChatLeftDotsFill,
    BsFillBookFill,BsBook, BsFillPersonVcardFill, BsPersonVcard,BsFillCheckCircleFill} from "react-icons/bs";

    // https://commons.wikimedia.org/wiki/File:Elyu_3.jpg
    // https://commons.wikimedia.org/wiki/File:Clouds_in_Russia._img_423.jpg

    // CSS pallettes:
    // https://palettes.shecodes.io/palettes/267#palette
    // https://palettes.shecodes.io/palettes/615#palette
    // https://palettes.shecodes.io/palettes/6
    // https://palettes.shecodes.io/palettes/569
    // https://palettes.shecodes.io/palettes/582

function Home() {

  const { logout, currentUser } = useAuth();
  const  passwordForKate = process.env.REACT_APP_PASSWORD_FOR_KATE;
  const [hasPasswordForKate, setHasPasswordForKate] = useState(false);

    // const email = currentUser.email;
    // const firstTwoChars = email.slice(0, 8);
    // const lastTwoChars = email.slice(-8);
    // const maskedEmail = `${firstTwoChars}...`;

    const logOut = async () => {
      try {
        await logout()
      } catch(error) {
        alert("Failed to log out", error.message)
      }
    }
// navigate stuff below:
    const navigate = useNavigate();

    function toTree() {
        navigate('/family-tree');
    }
    function toAgenda() {
        navigate('/agenda');
    }
    function toPhotos() {
        navigate('/album-list');
    }
    function toFaqs() {
        navigate('/faqs');
    }
    function toWall() {
        navigate('/community-wall');
    }
    function toProfile() {
        navigate('/profile');
    }
    function toSignUp() {
      navigate('/sign-up');
  }
  function toLogin() {
    navigate('/login');
}

// icons in top right
    function toHome(){
        navigate('/home');
      }
      function toProfile(){
        navigate('/profile');
      }


      // picture resizing etc.
    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const x = theWidth/13.5;
const y = 50;

// hover underline styles and logic

const [agendaHover, setAgendaHover] = useState(false);
const [photosHover, setPhotosHover] = useState(false);
const [treeHover, setTreeHover] = useState(false);
const [historyHover, setHistoryHover] = useState(false);
const [wallHover, setWallHover] = useState(false);
const [profileHover, setProfileHover] = useState(false);
// const [] = useState(false);
// const [] = useState(false);

const agendaEnter = () => {
  setAgendaHover(true);
};
const photosEnter = () => {
  setPhotosHover(true);
};
const treeEnter = () => {
  setTreeHover(true);
};
const historyEnter = () => {
  setHistoryHover(true);
};
const wallEnter = () => {
  setWallHover(true);
};
const profileEnter = () => {
  setProfileHover(true);
};
// mouseleave can be the same for all
const handleMouseLeave = () => {
  setAgendaHover(false);
  setPhotosHover(false)
  setTreeHover(false);
  setHistoryHover(false);  
  setWallHover(false);
  setProfileHover(false);
};

// slide in effects...
const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Set a timeout to trigger the slide-in effect after a delay (e.g., 500ms)
  const timeout = setTimeout(() => {
    setIsVisible(true);
  }, 500);

  // Clear the timeout to avoid memory leaks
  return () => clearTimeout(timeout);
}, []);

  return (
    <>  
    {theWidth>700 ?
    <div>
    <div style={{position:"absolute",left:"4%", top:"1.5%",}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div style={{position:"absolute",left:"14%", top:"1.5%",}}>Profiles</div>
    <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",left:"15%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"3.5%", top:"1.5%",}}>Photos</div>
    <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"14.5%", top:"1.5%",}}>Blog</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"23.5%", top:"1.5%",}}>FAQs</div>
    <BsBook onClick={toFaqs} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} />
    </div>
    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"3%",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown  />
    </>
    }
    {/* <h5 style={{position:"absolute", left:"5%", top:"0%"}}><i>Riley Summit 2023</i></h5> */}
    {/* <h5 style={{position:"absolute", left:"10%", top:"3%"}}><i>2023</i></h5> */}
    {/* make these login/sign up buttons diff when users are logged in etc.. */}
    <div>
    <div>
    {currentUser ? 
      <>
      <div style={{position:"absolute", right:"2%", top:"1.5%"}}>
      <button className="button-71" onClick={logOut}>Log Out</button>
      </div>
      <div style={{position:"absolute", left:"2%", top:"1%",zIndex:-1}}>
      {/* <img src={rt} style={{width:"150px"}} /> */}
      <div 
//       style={{
//   fontFamily: 'cursive',
//   fontSize: '1.5em',
//   fontWeight: 'bold',
//   fontStyle: 'italic',
//   color: 'purple',
//   textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
//   border: '2px solid purple',
//   borderRadius: '10px',
//   padding: '10px',
//   background: 'linear-gradient(45deg, #f3ec78, #af4261)',
// }}
style={{
  fontFamily: 'cursive',
  fontSize: '1.5em',
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: '#283593', /* Royal Blue: You can adjust the color code to match the theme */
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  // border: '2px solid #283593',
  // borderRadius: '10px',
  padding: '10px',
  // background: 'linear-gradient(45deg, #BDBDBD, #CFD8DC)',
}}

>
  Riley Reunion
</div>
      <div>

      </div>
      </div>
      </>
    :
    <>
    <div style={{position:"absolute", right:"2%", top:"1.7%",zIndex:1}}>
      <button className="button-72" onClick={toLogin}>Login/Sign Up</button>
    </div>
    <div style={{position:"absolute", left:"2%", top:"1%"}}>
      {/* <button className="button-71" onClick={toSignUp}>Sign Up</button> */}
      <div style={{
  fontFamily: 'cursive',
  fontSize: '1.5em',
  fontWeight: 'bold',
  fontStyle: 'italic',
  color: '#283593', /* Royal Blue: You can adjust the color code to match the theme */
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
  // border: '2px solid #283593',
  // borderRadius: '10px',
  padding: '10px',
  // background: 'linear-gradient(45deg, #BDBDBD, #CFD8DC)',
}}>Riley Reunion</div>
    </div>
    <div onClick={toSignUp} style={{position:"absolute", right:theWidth>700 ?"50px" :"25px", top:"1%"}}>
      {/* <a style={{ fontSize: "20px" }} href="#" >Sign Up</a> */}
    </div>
    <div onClick={toLogin}  style={{position:"absolute", right:theWidth>700 ?"70px":"45px", top:"5%"}}>
      {/* <a style={{ fontSize: "20px" }} href="#" >Login</a> */}
    </div>
    </>
    }
    </div>
    </div>
    {/* <h5 style={{position:"absolute", left:"2%", top:"-2.7%"}}>
    <button className="button-71" onClick={toLogin}>Login</button>
    </h5> */}
    {/* rt photo: */}
    <div style={{position:"absolute", left:"5%", top:"2%"}}>
    {/* <BsHouseFill style={{fontSize:"50px"}} /> */}
    </div>
    {/*  */}
    <div className="title">
    <h1 className="the-titles">Home</h1>
    </div>
    {/* <BsHouseFill onClick={toProfile} style={{position:"absolute",top:"1%", right:"49.5%", fontSize:"20px"}} /> */}
<div style={{
          backgroundImage: `url(${newpic})`,
          backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
             }}>  
    <br/>
    {/* at 922 fix it to 922... at 530 fix it to */}
    {/* <div className="title">
    <h1 style={{color:"white", fontFamily:"Courier"}}>Riley Family Summit</h1>
    </div> */}
    {/* <br/> */}
    {/* <Dropdown /> */}
    {/* <img src={waves} /> */}
    {/* <BsHouseFill onClick={goHome} style={{cursor:"pointer",position:"absolute",right:"75px"}} />
    <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
    {/* <BsHouseFill style={{fontSize:"25px"}}/> */}
    {/* <div className="center-stuff"> */}
    {currentUser && <div className="center-stuff"><BsFillCheckCircleFill style={{color:"green",marginRight:"25px"}} />Logged In as:&nbsp;<div style={{textDecoration:"underline"}}><strong>{currentUser.email}</strong></div><BsFillCheckCircleFill style={{color:"green",marginLeft:"25px"}} /></div>}
    {/* <div className="album-list">
    <div className={isVisible ? "center-grid-stuff visible" : "center-grid-stuff"  }
    onClick={toAgenda}
    onMouseEnter={agendaEnter}
    onMouseLeave={handleMouseLeave}
      >
    <h3 onClick={toAgenda} 
    >Agenda</h3>
    <BsCalendar2Day style={{fontSize:"75px"}}  /><br/><br/><br/>
    </div>
    <div className={isVisible ? "center-grid-stuff visible" : "center-grid-stuff"  }
    onClick={toPhotos}
    onMouseEnter={photosEnter}
    onMouseLeave={handleMouseLeave}
    >
    <h3 onClick={toPhotos} 
    >Photos & History</h3>
    <BsImages style={{fontSize:"75px"}}  /><br/><br/><br/>
    </div>
    <div className={isVisible ? "center-grid-stuff visible" : "center-grid-stuff"  }
    onClick={toFamilyTree} onMouseEnter={treeEnter}
    onMouseLeave={handleMouseLeave}>
    <h3 onClick={toFamilyTree}
    >Family Tree</h3>
    <BsDiagram3 style={{fontSize:"75px"}}  /><br/><br/><br/>
    </div>
    <div className={isVisible ? "center-grid-stuff visible" : "center-grid-stuff"  }
    onClick={toWall}
    onMouseEnter={wallEnter}
    onMouseLeave={handleMouseLeave}>
    <h3 onClick={toWall}
    >Community Wall</h3>
    <BsVectorPen style={{fontSize:"75px"}}  /><br/><br/><br/>
    </div>
    <div className={isVisible ? "center-grid-stuff visible" : "center-grid-stuff"  }
    onClick={toProfile}
    onMouseEnter={profileEnter}
    onMouseLeave={handleMouseLeave}>
    <h3 onClick={toProfile}
    >Profile</h3>
    <BsPersonVcard style={{fontSize:"75px"}}  /><br/><br/><br/>
    </div>
    <div className={isVisible ? "center-grid-stuff visible" : "center-grid-stuff"  }
    onClick={toFaqs}
    onMouseEnter={historyEnter}
    onMouseLeave={handleMouseLeave}>
    <h3 onClick={toFaqs}
    >FAQs</h3>
    <BsBook style={{fontSize:"75px"}}  /><br/><br/><br/>
    </div>
    </div> */}

    {/* <br/><br/><br/><br/><br/><br/> */}
    {theWidth > 529 &&
    <div className={theWidth < 780 ? "quote-container-home" : theWidth < 1100 ? "quote-container-wide" : "quote-container-wider"}>
    <div className="quote">
    <h4 ><i>“When I left England I had no definite plans
    as to what I was going to do...and no pledges, except to my mother that I would return at the end of two years”</i><br/><br/>
    - R.T. Riley</h4>
    </div>
    </div>
    }
    </div>
    
    </>
  );
}

export default Home;