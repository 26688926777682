import React, { useEffect, useRef, useState, useContext } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, fetchSignInMethodsForEmail, signOut } from 'firebase/auth';
import { collection, doc, setDoc, getFirestore,addDoc, serverTimestamp, getDocs } from 'firebase/firestore';
import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import { Card, Button, Alert, Spinner } from 'react-bootstrap'
import LoadingSpinner from './loadingSpinner';
import Should from './shouldBeOnList';
import Dropdown from '../dropDown';
import { useNavigate  } from 'react-router-dom'
import {BsCaretDownFill, BsCaretUpFill, BsFillCheckCircleFill, BsFillXCircleFill,BsArrowReturnLeft, BsArrowReturnRight,
  BsXCircleFill, BsHandIndexFill, BsXCircle, BsCheckCircleFill, BsFillHouseFill} from "react-icons/bs";
import '../somecss.css'
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import g2siblings from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_g2_siblings-new.png';
import riley_clan from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_clan.png';
import signature from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sig-newest.png';


function SignUp({ katePass, showKate }) {
  const auth = getAuth(app);
  const db = getFirestore();
  const navigate = useNavigate();

  const  passwordForKate = process.env.REACT_APP_PASSWORD_FOR_KATE;
  const whitelistedEmails = process.env.REACT_APP_WHITELISTED_EMAILS.split(',');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [checkEmail, setCheckEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fetchedEmails, setFetchedEmails] = useState([]);

  useEffect(() => {
    const fetchEmailsFromFirestore = async () => {
      try {
        const emailsCollection = collection(db, 'whitelistedEmails'); // Replace with your actual collection name

        const querySnapshot = await getDocs(emailsCollection);

        const emails = [];
        querySnapshot.forEach((doc) => {
          emails.push(doc.data().email);
        });

        setFetchedEmails(emails);
      } catch (error) {
        console.error('Error fetching emails from Firestore:', error);
      }
    };

    fetchEmailsFromFirestore();
  }, []);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handlePasswordChangeRepeat = (event) => {
    setPasswordRepeat(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === passwordForKate) {
      katePass();
      setErrorMessage('');
      // alert("yep");
      navigate('/home');
    } else {
      setErrorMessage('Invalid password');
      alert(errorMessage);
    }
  };

  const handleSignup = async () => {
    setShowError(false);
    setError(null);
    setErrorMessage('');
    setCheckEmail(false);
    setLoading(true);
    if(password==passwordRepeat) {
      // making sure on the list:
      if(!fetchedEmails.includes(email.trim())){
        alert('Not on the list! Get someone on the list to add you');
        setLoading(false);
        return;
      }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Send email verification
      setLoading(false);
      await sendEmailVerification(auth.currentUser);
      // Successful registration; a verification email has been sent
      // console.log('User registered:', userCredential.user);
      setError(null); // Clear any previous errors
      setCheckEmail(true);
      // create profile doc
      const pushProfileDoc = async () => {
        const profilesCollection = collection(db, 'profiles');
        const userDoc = doc(profilesCollection, email);
          const userData = {
            username: "",
            age: 0,
            aboutme: "Nothing here...",
            email: email,
            generation:3,
            picSide: 0,
            picUp: 0,
            picZoom: 100,
            branch:"Edit to choose",
          };
          try {
            await setDoc(userDoc, userData);
            console.log('Document set successfully');
          } catch (error) {
            console.error('Error setting document: ', error);
          }
        };
        pushProfileDoc();
        await signOut(auth);
        console.log('User signed up successfully. Logged out.');
    } catch (error) {
      // Handle registration errors
      setLoading(false);
      console.error('Registration failed:', error.message);
      if (error.code === 'auth/email-already-in-use') {
        setError("This email is already signed up!");
        setShowError(true);
      } else {
        setError(error.message);
        setShowError(true);
        setLoading(false);
      }
    }
  } else {
    // alert('Passwords need to match');
    setError("Passwords need to match!");
    setShowError(true);
    setLoading(false);
  }
  };

  // copy pasta 


  // picture/width things:

    const [theWidth, setTheWidth] = useState(window.innerWidth);
    const [quote, setQuote] = useState();

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    const [showQ, setShowQ] = useState(false);
    function showQuestions() {
      setShowQ(!showQ);
    }


    function toHome() {
        navigate('/home');
    }
    function toLogin() {
        navigate('/login');
    }
    function shouldBe() {
      // navigate('/should-be-on-list');
      setShouldBeOnList(true);
  }

    function test(){
      alert('dfg');
    }

    const [onTheList, setOnTheList] = useState(false);
    function checkList() {
      setOnTheList(!onTheList);
    }

    // redirect to "not on the list but should be page?"

    function redirect() {
      alert('need to make this');
    }

    // triggering help + blur
    const [emailCheck, setEmailCheck] = useState('');
    const [checked, setChecked] = useState(false);
    const [helpPage, setHelpPage] = useState(false);
    const [shouldBeOnList, setShouldBeOnList] = useState(false);
    function triggerHelp() {
      setHelpPage(true);
    }
    function hideHelp() {
      setHelpPage(false);
    }

    useEffect(() => {
      function handleKeyPress(event) {
        if (event.key === 'Enter') {
          handleSignup();
        }
      }

      // Add event listener when the component mounts
      document.addEventListener('keydown', handleKeyPress);
  
      // Clean up by removing event listener when the component unmounts
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    }, [email,password]);

    // writing stuff to firestore...
    // const writeToFirestore = async () => {
    //   try {
    //     const emailsArray = whitelistedEmails;
  
    //     const emailsCollection = collection(db, 'whitelistedEmails');
  
    //     for (const email of emailsArray) {
    //       const docRef = await addDoc(emailsCollection, {
    //         email: email.trim(),
    //         timestamp: serverTimestamp(),
    //       });
  
    //       console.log(`Email ${email} written with ID: ${docRef.id}`);
    //     }
  
    //     console.log('Writing to Firestore completed successfully.');
    //   } catch (error) {
    //     console.error('Error writing to Firestore:', error);
    //   }
    // };


  return (
    <>
        <div className={loading || helpPage ? "blur-it" : ""}>
          
        <div style={{
          // backgroundImage: `url(${newpic})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height: theWidth,
             }}> 
    <div className="title">
    {theWidth>450 ?
        <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
        </div>
    :
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Reunion
      </div>
      }
    </div>
    {/* <button>Write to Firestore</button> */}
    {/* new login/home button styles */}
    <div style={{position:"absolute", right:"1%", top:"5px"}}>
      <button className="stories-button" onClick={toLogin}>Login</button>
    </div>
      <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}>
      <BsFillHouseFill fontSize={28} color="#0078d0" />
      </div>
   
{/* sign up UI: */}
    <div className="center-stuff">
    <br/>
    <div className="sign-up">
    <div className="center-stuff">
    <span style={{margin:"10px"}}><div className="the-titles">Sign Up</div></span>
    </div>
    <div className="center-stuff">
    <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"5px", marginTop:"15px"}} >Email:</div>
    </div>
    <div className="center-stuff">
    <input style={{margin:"5px", width:"200px"}} type="email" value={email} onChange={handleEmailChange} />
    </div>
    <div className="center-stuff">
    <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"5px"}} >Create Password:</div>
    </div>
    <div className="center-stuff">
    <input style={{margin:"5px", marginRight:"15px",marginLeft:"15px",width:"200px"}} type="password" value={password} onChange={handlePasswordChange} />
    </div>
    <div className="center-stuff">
    <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"5px"}} >Repeat Password:</div>
    </div>
    <div className="center-stuff">
    <input style={{margin:"5px", width:"200px"}} type="password" value={passwordRepeat} onChange={handlePasswordChangeRepeat} />
    </div>
    <div className="center-stuff">
    <button onClick={handleSignup} style={{margin:"10px", marginBottom:"15px",cursor:"pointer"}}>Submit</button>
    </div>
    <div className="center-stuff">
      <a style={{marginBottom:"10px"}} href="#" onClick={triggerHelp}>
        Having Trouble?
      </a>
      </div>
    </div>
    </div>
    {checkEmail && 
      <div className="center-stuff">
        <div className="check-email">
          <div style={{marginTop:"10px"}}>
          <div className="center-stuff"><BsFillCheckCircleFill style={{marginRight:"5px",color:"green"}} />Success<BsFillCheckCircleFill style={{marginLeft:"5px",color:"green"}} /></div>
          </div>
          <div className="center-stuff">
          <i style={{margin:"10px"}}>Check your email</i><br/>
          </div>
          <div className="center-stuff">
          <i style={{margin:"10px"}}>Then, login!</i>
          </div>
        </div>
        </div>
    }
    {showError && 
        <div className="center-stuff">
        <div className="check-email">
          <div style={{marginTop:"10px"}}>
          <div style={{margin:"5px"}}><div className="center-stuff"><BsFillXCircleFill style={{color:"maroon",marginRight:"25px"}} /> ERROR <BsFillXCircleFill style={{color:"maroon",marginLeft:"25px"}}  /></div></div>
          </div>
          <i style={{margin:"10px"}}>{error}</i><br/>
        </div>
        </div>
    }
    <div className="center-stuff">
      <img src={g2siblings} style={{width:theWidth<450?"75%":"50%",marginTop:theWidth<450?"25px":checkEmail || showError ?"5px" :"30px", borderRadius:"10px"}} />
    </div>
    {/* </div> */}
    {/* <br/><br/><br/><br/> */}
    {theWidth>850 
      ?
      <div className="center-stuff">
      {/* <div style={{position:"absolute",top:"45%",width:"300px",marginLeft:"100px"}}> */}
      <div style={{marginTop:"-475px",marginLeft:"590px",position:"absolute",top:"50rem"}}>
      <div className="speech-bubble-sign-wide">
      <p style={{margin:"5px"}}><i>Please employ a unique password, unused elsewhere</i></p>
      </div>
      </div>
      {/* </div> */}
      </div>
      :
      <div className="speech-bubble-sign">
      <p style={{margin:"5px"}}><i>Please employ a unique password, unused elsewhere</i></p>
      </div>
    }
    {theWidth>450 &&<div style={{position:"absolute", left:`${theWidth/100+7}%`, top:"225px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
     <img src={riley_clan} style={{width:`${theWidth/7+20}px`}} />
    </div>}   
    {theWidth>450 &&<div style={{position:"absolute", right:`${theWidth/200-11}%`, top:"225px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
     <img src={signature} style={{width:`${theWidth/5+20}px`}} />
    </div>}
    </div>
    </div>
    {loading && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }
    {/* HELP PAGE */}
    {helpPage &&
     <div style={{position: "absolute", top: "300px",left: "50%", transform: "translate(-50%, -50%)"}}>
      {/* <LoadingSpinner /> */}
      <Card>
      <div className="center-login-prompt" style={{cursor:"default"}}>
      <div style={{marginTop:"10px", color:"maroon",cursor:"pointer", position:"fixed",right:"10%"}} onClick={hideHelp}><BsXCircle style={{fontSize:"22px"}} /></div>
      <div style={{marginRight:"10px",marginLeft:"10px"}}>
      <div className="center-stuff">
      {/* FIO: how this shouldbeonList can trigger input form.... */}
      <div style={{marginBottom:"10px",marginTop:"35px"}}>Only certain emails can sign up</div>
      <div style={{marginBottom:"10px",marginTop:"10px"}}>Check if yours is on the list:</div>
      </div>
      <div className="center-stuff">
      <input style={{width:"250px"}}
        type="email"
        placeholder="Enter your email"
        value={emailCheck}
        onChange={(e) => setEmailCheck(e.target.value)} />
      </div>
      
      </div>
        {
          fetchedEmails.includes(emailCheck.trim())
         ?
        <div style={{marginBottom:"10px"}}><BsCheckCircleFill style={{color:"green",marginRight:"10px",marginTop:"10px"}} />On the list<BsCheckCircleFill style={{color:"green",marginLeft:"10px"}} /></div>
        :
        <>
        <div style={{marginBottom:"10px"}}><BsXCircleFill style={{color:"maroon",marginTop:"10px",marginRight:"10px"}} />Not on the list<BsXCircleFill style={{color:"maroon",marginLeft:"10px"}} /></div>
        <div style={{marginTop:"10px",textDecoration:"underline"}}>
        Not on the list but should be?
        </div>
        {theWidth>450 && <div style={{position:"absolute",top:"225px",right:"7.5%"}}>
        <BsArrowReturnLeft style={{fontSize:"36px"}} />
        </div>}
        {theWidth>450 && <div style={{position:"absolute",top:"285px",left:"7.5%"}}>
        <BsArrowReturnRight style={{fontSize:"36px"}} />
        </div>}
          <>
          <div>
          <Should />
          </div>
          </>
        
        </>
      }
      </div>
      
      </Card>
      
     </div> 
    }
    {/* check email notification here instead? */}
    
    
    </>
  );
}

export default SignUp;