import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate  } from 'react-router-dom'
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import LoadingSpinner from './loadingSpinner';
// import { collection, addDoc, getFirestore, onSnapshot } from "firebase/firestore";
import { collection, getFirestore, onSnapshot, getDocs, doc, updateDoc, where, query, arrayUnion, orderBy,deleteDoc } from "firebase/firestore";
// import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/rt_head.png';
import { listAll, getStorage, ref, getDownloadURL } from 'firebase/storage';
import { BsPersonFill,BsChatRightText} from "react-icons/bs";
import { renderToStaticMarkup } from "react-dom/server";


const Comments = ({ boolValue, loadChange, moreThanThree }) => {
    const db = getFirestore();
    const storage = getStorage();
    const navigate = useNavigate();
    // const { someParam } = useParams();
    const [theWidth, setTheWidth] = useState(window.innerWidth);
    const { currentUser } = useAuth();


    // const dizzyIcon = renderToStaticMarkup(<BsEmojiDizzy />);
    const personFill = renderToStaticMarkup(<BsPersonFill />);
    const svgString = `data:image/svg+xml,${encodeURIComponent(personFill)}`;
    // real:
    const [profiles, setProfiles] = useState([]); // State to hold profiles data
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  // for loading more button (disabled={someState})???:
  // const [someState, setSomeState] = useState(true);
  const [visibleComments, setVisibleComments] = useState(10); // State to track the number of visible comments


  const fetchProfiles = async () => {
    // Replace 'profiles' with your actual Firestore collection name
    const profilesSnapshot = await onSnapshot(collection(db, 'profiles'), (snapshot) => {
      const profilesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProfiles(profilesData);
    });
  };
  useEffect(() => {
    fetchProfiles();
  },[db])
  
  useEffect(() => {
    setLoading(true);
    loadChange(true);
    const unsubscribe = onSnapshot(query(collection(db, 'wall-test'), orderBy('timestamp', 'desc')), (snapshot) => {
        const commentsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(commentsData);
        const updatedComments = [...commentsData];
        updatedComments.forEach((comment) => {
          const matchingProfile = profiles.find((profile) => profile.email === comment.user);
          if (matchingProfile) {
            comment.username = matchingProfile.username;
          }
        });
        setComments(updatedComments);
        const timer = setTimeout(() => {
          setLoading(false);
          loadChange(false);
        }, 1000); 
                return () => {
          setLoading(false);
          loadChange(false);
        };
        
      });
  
      return () => {
        unsubscribe();
      };
    }, [db]);

    // width 
    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);
    // dfgdfgdfgdfgdfgd



    const [userComment, setUserComment] = useState('');

  const handleReply = (event) => {
      setUserComment(event.target.value);
  };

  function testingOk(someProp) {
    alert(someProp);
  }

  const [profilePics, setProfilePics] = useState([]);
  // useEffect fetching profile pics from storage...
  useEffect(() => {
  const encounteredEmails = [];

  const fetchPics = async () => {
    try {
      const profilePicPromises = comments.map(async (content) => {
        // if (!encounteredEmails.includes(content.user)) {
        //   setEncounteredEmails(prevEmails => [...prevEmails, content.user]);
        if (!encounteredEmails.includes(content.user)) {
          encounteredEmails.push(content.user);
        try {
          const profilePicRef = ref(storage, `profilepics/${content.user}`);
          const imageRefs = await listAll(profilePicRef);
          const downloadedImage = await Promise.all(
            imageRefs.items.map(async (imageRef, index) => {
              const downloadURL = await getDownloadURL(imageRef);
              return { email: content.user, src: downloadURL }; // Modified structure
            })
          );
          return downloadedImage;
        } catch (error) {
          console.error('Error downloading images:', error);
          return null;
        }
      }
      return null;
      });

      const profilePics = await Promise.all(profilePicPromises);
      // Flatten the array of arrays into a single array of objects
      const flattenedProfilePics = profilePics.flat().filter(pic => pic !== null);
      setProfilePics(flattenedProfilePics);
    } catch (error) {
      console.error('Error fetching profile pictures:', error);
    }
  };
  fetchPics();
  }, [comments, storage]);

  const theSecret = process.env.REACT_APP_SECRET;

  const encryptEmail = (email) => {
    try {
      const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
      return encodeURIComponent(encryptedEmail);
    } catch (error) {
      console.error('Encryption error:', error);
      return null;
    }
  };


    function toClickedProfile(userEmail) {
      if(currentUser && userEmail===currentUser.email){
        navigate(`/profile`);
      } else {
        const encryptedEmail = encryptEmail(userEmail);
        if (encryptedEmail) {
          navigate(`/profiles/${encryptedEmail}`);
        } else {
          console.error('Encryption failed');
        }
      }
    }

    // snatch the pic position values from firestore
    const [picPositions, setPicPositions] = useState([]);
      useEffect(() => {
        const fetchPicPositions = async () => {
          const positions = [];
          if (comments.length > 0) {
          const userEmails = comments.map((comment) => comment.user).filter(Boolean);
          const userDocRef = collection(db, 'profiles');
          // console.log(userEmails);
          const q = query(userDocRef, where('email', 'in', userEmails));
          const snapshot = await getDocs(q);
    
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              const data = doc.data();
              const { email, picUp, picSide, picZoom } = data;
    
              const picPosition = {
                email,
                picUp,
                picSide,
                picZoom,
              };
              positions.push(picPosition);
            });
          }
    
          setPicPositions(positions);
        } else {
          console.log("comments.length isn't greater than zero...should work in a sec")
        }
        };
    
        fetchPicPositions();
      }, [comments, profilePics,db]);

      const [showDivArray, setShowDivArray] = useState();
      const handleShowDiv = (index) => {
        // alert(index);
        setShowDivArray(index);
        setToggleReplies(false);
      };
      const cancelReply = () => {
        setShowDivArray(null);
      };
      const postReply = async (docId) => {
        // alert(docId)
        if(currentUser) {
            try {
              const replyData = {
                reply: userComment,
                email:currentUser.email,
              };
              const replyRef = doc(db, "wall-test", docId);
              setLoading(true);
              loadChange(true);
              await updateDoc(replyRef, {
                replies: arrayUnion(replyData),
              });
              setShowDivArray(null);
              console.log('Document updated successfully!');
              setLoading(false);
              loadChange(false);
              } catch (error) {
                setLoading(true);
                loadChange(true);
                console.error('Error updating document:', error);
                alert('Error updating document:', error);
                setLoading(false);
                loadChange(false);
              }
            } else {
              alert('You need to login first');
            }
      }

      // function testReply() {
      //   setTriggerReply(!triggerReply);
      // }

      const [toggleReplies, setToggleReplies] = useState(null);
      function showReplies(theIndex) {
        // setToggleReplies(true);
        // alert(theIndex)
        setToggleReplies(theIndex);
      }
      function hideReplies() {
        // setToggleReplies(true);
        // alert(theIndex)
        setToggleReplies(null);
      }

      useEffect(() => {
        if(comments && comments.length > 3) {
          // console.log("more than 3");
          moreThanThree(true);
        } else {
          moreThanThree(false);
          // console.log("less than 3");
        }
      },[comments])

      const loadMoreComments = () => {
        // Increase the number of visible comments by 10 or display all if remaining comments are less than 10
        setVisibleComments(prevVisibleComments => Math.min(prevVisibleComments + 10, comments.length));
      };

      // render with <a> tags dynamically:
      const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Function to replace URLs with <a> tags
  const renderCommentWithLinks = (comment) => {
    const parts = comment.split(urlRegex);

    return parts.map((part, index) => {
      if (part.match(urlRegex)) {
        // If part is a URL, render it as a link
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // If part is not a URL, render it as regular text
        return <span key={index}>{part}</span>;
      }
    });
  };

  // delete post:
  const deletePost = async (postId) => {
    // alert(postId);
    try {
      const postRef = doc(db, 'wall-test', postId);
      await deleteDoc(postRef);
      console.log('Document successfully deleted!');
    } catch (error) {
      console.error('Error deleting document: ', error);
    }
  };
  const [showFullComment, setShowFullComment] = useState();


  const showComment = (index) => {
    if(showFullComment===index){
      setShowFullComment(5000);
    } else {
      setShowFullComment(index);
    }
  } 
  

  return (
    <>
    <div className={loading ? "blur-it" : ""}>
    {/* {comments ?
      <ul>
        {comments.map(comment => (
          <li key={comment.id}>{comment.content}</li>
        ))}
      </ul>
      :
      <>
      <div>loading...</div>
      </>
    } */}
    {/* {JSON.stringify(comments)} */}
      <div className="center-stuff-wall">
      {/* make an addition to the following mapping
      where you search to see if the content.user email is...
      in profilePics and if so, then render one, and if not, render the other...
      instead of rendering through all profile pics???? cuz it's not working great */}
              {/* {comments.map((content,i) => { */}

      {comments.slice(0, visibleComments).map((content, i) => {
          const matchingProfilePic = profilePics.find(profilePic => profilePic.email === content.user);
          const positionDeets = picPositions.find((pos) => pos.email === content.user);
        
        return (
            <div key={i+1}>
            <div >
            {/* {JSON.stringify(picPositions)} */}
            {
              matchingProfilePic
              ?
              positionDeets &&
              <>
              <div key={i*2} style={{marginLeft:"15px",marginTop:"-20px", zIndex:-1}} >
              </div>
              </>
              :
              <>
              <div key={i*3} style={{marginLeft:"15px"}}>
                {/* <img alt="#" style={{width:"40px",border:"solid 1px black",borderRadius:"50%"}} src={svgString} /> */}
              </div>
              </>
            }
            
            </div>
            <div>
            <div
            key={i} >
            <div style={{marginTop:"20px"}}></div>
              <div 
              onClick={() => showComment(i)}
              style={{zIndex:1, backgroundColor:"white",border:"solid white 1px",
              boxShadow: '2px 2px 4px 4px rgba(0, 0, 0, 0.1)',height:showFullComment===i?"100%":"100px",borderRadius:"5px",
              overflow:"auto",textOverflow:"ellipsis",wordWrap:"break-word",padding:"2px",
              marginRight:"15px",marginLeft:"15px",cursor:"pointer" }}>
              <div>
                {/* <img alt="#" style={{width:"40px",border:"solid 1px black",borderRadius:"50%"}} src={svgString} /> */}
                {matchingProfilePic
              ?
              positionDeets &&
                      <div style={{zIndex:-1}} >
                      <div style={{width:"50px", height:"50px",border:"solid 1px black",borderRadius:"50%",cursor:"pointer",boxShadow: '2px 2px 4px 4px rgba(0, 0, 0, 0.1)',
                      display: 'flex',alignItems: 'center',justifyContent: 'center',overflow:"hidden"}} >
                      <img 
                      alt="#"
                        onClick={() => toClickedProfile(content.user)}                       
                        style={{
                          border:"solid 1px black",borderRadius:"50%",cursor:"pointer",objectFit: 'cover',
                          width:`${positionDeets.picZoom/2}px`, marginLeft: `${positionDeets.picSide}px`, marginTop: `${positionDeets.picUp/2}px`
                          }} 
                        src={matchingProfilePic.src} />
                      </div>
                      </div>
                      :
                      <>
                      <img alt="#" style={{width:"40px",border:"solid 1px black",borderRadius:"50%"}} src={svgString} />
                      </>
                }
                {profiles.map((profile) => {
              if (profile.email === content.user) {
                return <div key={profile.id}>
                <div style={{display:"flex",justifyContent:"left",marginTop:"-55px",marginLeft:"55px"}}><div className="wall-name">{profile.username ? profile.username :"Incomplete Profile"} says:</div></div>

                </div>;
              } else {
                return
                {/* <strong style={{display:"flex",justifyContent:"left",marginTop:"-50px",marginLeft:"55px",textDecoration:"underline"}}>{content.user}</strong> */}
              }
            })}
            
              </div>
              {
                content.timestamp ?
                <div style={{display:"flex",justifyContent:"left",marginTop:"-22px",marginLeft:"85%",fontSize:"12px"}}><i>{content.timestamp.toDate().toLocaleString('default', { month: 'long' }).toString()} {content.timestamp.toDate().getDate().toString()}</i></div>
              :
              <></>
              }
              {/* <div style={{display:"flex",justifyContent:"left",marginTop:"-22px",marginLeft:"85%",fontSize:"12px"}}><i>{content.timestamp.toDate().toLocaleString('default', { month: 'long' }).toString()} {content.timestamp.toDate().getDate().toString()}</i></div> */}
              <div><div style={{marginLeft:"55px",marginTop:"5px"}}>{renderCommentWithLinks(content.comment)}</div></div>
              </div>
            </div>
            </div>
            {
              currentUser && currentUser.email===content.user &&
              <button style={{position:"absolute",cursor:"pointer"}} onClick={() => deletePost(content.id)}>Delete</button>
            }

            {/* <button style={{marginLeft:`${theWidth/1.5}px`,marginBottom:"-20px",width:"100px"}} onClick={testReply}>Reply</button> */}
            {showDivArray !== i ?
              theWidth>900 ?
              <>
              <button style={{marginLeft:`${theWidth/1.3}px`,width:"100px",zIndex:boolValue!==true || theWidth>750 ? 1 : -1,cursor:"pointer",marginBottom:"15px"}} 
              onClick={() => handleShowDiv(i)}>Reply</button>
              </>
              :
              theWidth>650 ?
              <button style={{marginLeft:`${theWidth/1.4}px`,width:"100px",zIndex:boolValue!==true || theWidth>750 ? 1 : -1, cursor:"pointer",marginBottom:"15px"}} onClick={() => handleShowDiv(i)}>Reply</button>
              :
              theWidth>500 ?
              <button style={{marginLeft:`${theWidth/1.6}px`,width:"100px",zIndex:boolValue!==true || theWidth>750 ? 1 : -1,cursor:"pointer",marginBottom:"15px"}} onClick={() => handleShowDiv(i)}>Reply</button>
            :
            <button style={{marginLeft:`${theWidth/1.7}px`,width:"100px",zIndex:boolValue!==true || theWidth>750 ? 1 : -1,cursor:"pointer",marginBottom:"15px"}} onClick={() => handleShowDiv(i)}>Reply</button>
            :
            <></>}
            {/* {JSON.stringify(showDivArray)} */}
            {
              showDivArray===i &&
              <>
              <div className={theWidth<450?"reply-small":"reply"}>
                <textarea rows="4" cols={theWidth<450?"30":"40"} placeholder="Type here..."
                    value={userComment}
                    onChange={handleReply}
                />
              <div>
              <button style={{cursor:"pointer",zIndex:boolValue!==true || theWidth>750 ? 1 : -1}} onClick={cancelReply}>cancel</button><button style={{cursor:"pointer",zIndex:boolValue!==true || theWidth>750 ? 1 : -1}} onClick={() => postReply(content.id)}>post</button>
                </div>
                </div>
              </>
            }
            {/* rendering replies */}
            {content.replies && content.replies.length > 0 ? (
              <div>
              {showDivArray===i ?
                <></>
              :
              <div className="center-stuff">
              <button onClick={toggleReplies!==i ?() => showReplies(i) : hideReplies} 
              style={{
    cursor: "pointer",
    backgroundColor: "rgb(235, 233, 233)", // Adjust this color as needed
    color: "black",
    // padding: "4px 10px",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex:-1,
    marginTop:"-40px",
    // marginBottom:"15px",
    marginRight:theWidth<450?"100px":"0px",
    zIndex:boolValue!==true || theWidth>750 ? 2 : -1
              }}><BsChatRightText style={{ marginRight: "8px" }} />{toggleReplies===i ? "Hide Replies" : "Show Replies"} ({content.replies.length})</button>
      </div>
       }

              {/* <div style={{marginLeft:"50%",marginTop:"-15px",marginBottom:"-10px"}} ><BsFillArrowDownCircleFill style={{fontSize:"24px",color:"royalblue"}} /></div> */}
                {toggleReplies===i && content.replies.map((reply, replyIndex) => (
                  <div style={{marginBottom:"15px",marginTop:"-15px"}} key={replyIndex}>
                  <p style={{marginBottom:"-0.5px"}}></p>
                  <div className="user-replies">
                  {/* <p><strong>{reply.email} says:{` `}</strong>{reply.reply}</p> */}
                  {profiles.map((profile) => {
              if (profile.email === reply.email) {
                return <div key={profile.id}>
                <p style={{marginTop:"-5px"}}><div className="reply-name">{profile.username ? profile.username :"Incomplete Profile"} says:{` `}</div>{reply.reply}</p>
                </div>;
              } else {
                return
              }
            })}
                  </div>
                  {/* {JSON.stringify(reply)} */}
                  </div>
                ))}
              </div>
            ):
            <>
            {/* <div style={{marginLeft:"25px",zIndex:"-1"}}>d</div> */}
            </>
          }

            {/* {
              triggerReply &&
            <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
              <div>
              </div>            
            </div>
            } */}
          </div>
        )})}
      </div>
      {/* create a function that only allows x number of posts render...
      ...so user will have to press button to "load more" or something
      ...Also maybe make a border around "user:" AND the comment and then 
      ...dispose of the background white colour...
       */}

      {/* <textarea
        value={commentContent}
        onChange={(e) => setCommentContent(e.target.value)}
        placeholder="Write a comment"
      /> */}
      {/* <button onClick={handleCommentSubmit}>Comment</button> */}
      {/* LOADING SPINNER BELOW..triggered when albums are loading... */}
      <div>
        <div className="center-stuff">
          {visibleComments < comments.length && (
        <button className="read-more-button" onClick={loadMoreComments}>Load More ({comments.length-visibleComments})</button>
      )}
          <br/>
        </div>
      </div>
    </div>
    {loading && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }
    </>
  );
};

export default Comments;