import React, { useEffect, useState, useContext } from 'react';
import { useNavigate  } from 'react-router-dom'
import './somecss.css';
// import MyContext from './context';
import { BsVolumeOffFill, BsVolumeUpFill, BsList,BsImages, BsPersonFill, BsVectorPen, BsTreeFill, 
  BsHouseFill, BsFillDiagram3Fill, BsDiagram3, BsFillChatLeftDotsFill,
  BsFillBookFill,BsBook, BsFillPersonVcardFill, BsPersonVcard,BsCalendar2Day } from "react-icons/bs";
import { FaDoorClosed, FaLaptopHouse } from 'react-icons/fa';
// import UseComponentVisible from './componentVisible';


function Dropdown({ handleBoolChange }) {
  // context for zIndex state (and maybe more):
  // const {showButton, setShowButton} = useContext(MyContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate()
  // for different dropdown items to expand...
  const [init, setInit] = useState(false);
  const [one,setOne] = useState(false);
  const [two,setTwo] = useState(false);
  const [three,setThree] = useState(false);

   const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

//   const { ref, isComponentVisible, setIsComponentVisible} = UseComponentVisible(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
      setInit(!init);
    // setIsComponentVisible(true);
  };
  function itemHome() {
    navigate('/home');
  }
  function itemAgenda() {
    navigate('/agenda');
  }
  function itemTree() {
    navigate('/stories');
  }
  function profiles() {
    navigate('/profiles')
  }
  function toYourProfile() {
    navigate('/profile')
  }
  function stories() {
    navigate('/stories')
  }
  function itemTwo() {
    navigate('/album-example');
  }
  function itemHistory() {
    navigate('/faqs');
  }
  function itemFour() {
    navigate('/album-list');
  }
  function itemFive() {
    navigate('/group-password');
  }
  function itemWall() {
    navigate('/community-wall');
}
  function back() {
    setInit(true);
    setOne(false);
    setTwo(false);
    setThree(false);
    // setShowButton(1);
  }

  const [triggerState, setTriggerState] = useState(false);

  useEffect(() => {
    try {
    if(isOpen) {
      if(init) {
        // setTriggerState(true);
        // console.log("dfgdfgdfg")
        // handleBoolChange(true);
        handleBoolChange(true);
      } else {
        // setTriggerState(false);
        // handleBoolChange(false);
        handleBoolChange(false);

      }
    } 
    else {
      // setTriggerState(false);
      // handleBoolChange(false);
      handleBoolChange(false);
    }
  } catch(error) {
    console.log("error",error);
  }
  },[handleBoolChange, isOpen, init])

// make drop icon align with fullscreen icon...
// ... when browser width is narrow? make inline style adjustments?

  return (
    <>
      {/* <BsList 
      onClick={toggleDropdown} 
      style={{cursor:"pointer", position:"absolute", right:"20", 
      color:"DodgerBlue", fontSize: '200px'}} /> */}
    {/* <div style={{backgroundColor:"white",opacity:0.4}}> */}
    <BsList 
    onClick={toggleDropdown}
    style={{cursor:"pointer", position:"absolute", 
    top:theWidth<450?"15":"20",right:"25", color:"black", fontSize: '30px'}} />
    {/* </div> */}
    <div style={{ position:"absolute", 
    top:"8.9%",right:"1%", color:"black", fontSize: '18px', zIndex:2}}>
      {isOpen && (
        <>
        {init && 
              <>
              {true && (
              <ul className="dropdown-list">
              <div>
              <ul className="dropdown-item" onClick={itemHome}>
              <div className="the-titles-hover">
              <div>Home</div>
              </div>
              </ul>
              <ul className="dropdown-item" onClick={toYourProfile}>
              <div className="the-titles-hover">
              <div>Your Profile</div>
              </div>
              </ul>
              <ul className="dropdown-item" onClick={profiles}>
              <div className="the-titles-hover">
              <div>Profiles</div>
              </div>
              </ul>
              <ul className="dropdown-item" onClick={itemFour}>
              <div className="the-titles-hover">Photos etc.</div>
              </ul>
              <ul className="dropdown-item" onClick={stories}>
              <div className="the-titles-hover">Stories</div>
              </ul>
              <ul className="dropdown-item" onClick={itemWall}>
              <div className="the-titles-hover">Community Wall</div>
              </ul>
              <ul className="dropdown-item" onClick={itemAgenda}>
              <div className="the-titles-hover">Agenda</div>
              </ul>
              </div>
              </ul>
              )}
              </>
        }  
        </>
      )}
    </div>
    </>
  );
}

export default Dropdown;