import React from 'react';
import MyContext from './myContext';

export const MyProvider = ({ children }) => {
  // Your state and functions go here

  return (
    <MyContext.Provider 
    // value={/* Provide the values you want to share */}
    >
      {/* {children} */}
    </MyContext.Provider>
  );
};