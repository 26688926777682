import React, { useEffect, useRef, useState } from 'react';
import { useNavigate  } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import {BsArrowsFullscreen,BsFillArrowLeftCircleFill,BsFillArrowRightCircleFill} from "react-icons/bs";
import Slider from "react-slick";
import { isMobile } from 'react-device-detect';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { collection, addDoc, getFirestore, getDoc, setDoc, doc } from "firebase/firestore";
import garcia from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/garcia_74.jpeg';
import dimon from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/jamie_dimon.jpeg';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs.png';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_rt.jpeg';

import '../../somecss.css';


export default function ImageCarousel({images, onCarouselStateChange }){
  const db = getFirestore();
  const { currentUser } = useAuth();
  const { encryptedEmail, albumName } = useParams();
  const [showRender, setShowRender] = useState(true);
  const [totalSlides, setTotalSlides] = useState(0);
  const [render, setRender] = useState('');
  const [currentItemId, setCurrentItemId] = useState(0);
    const carousel = useRef();

    console.log(carousel);
    const settings = {
        customPaging: function (i) {
            return (
              <a>
                {/* <img
                  src={images[i].src}
                  height="100%"
                  width="100%"
                  alt={images[i].alt}
                /> */}
                <video height="100%" width="100%" controls>
                  <source src={images[i].src} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
              </a>
            );
          },
        autoplay: false,
        autoplaySpeed: 0,
        cssEase: "ease",
        fade: true,
        speed: 200,
        adaptiveHeight: true,
        infinite: true,
        // afterAll: setCurrentItemId(0),
        // dots: true
        slidesToShow: 1,
        arrows: false,
        slidesToScroll: 1,
        lazyLoad: true,
        beforeChange: (oldIndex, newIndex) => {
          if (isMobile) {
            // Your custom logic here, using the oldIndex and newIndex
            // console.log(`Slide changing from ${oldIndex} to ${newIndex} on mobile`);
            // Execute your logic here, for example:
            if (newIndex > oldIndex) {
              handleNext();
            } else if (newIndex < oldIndex) {
              handlePrev();
            }
          }
        },
      
      };
      const theSecret = process.env.REACT_APP_SECRET;

      const decryptEmail = (encryptedEmail) => {
        try {
          const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedEmail), theSecret);
          const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);
          return decryptedEmail;
        } catch (error) {
          console.error('Decryption error:', error);
          return null;
        }
      };

      useEffect(() => {
        if (carousel.current) {
          setTotalSlides(carousel.length);
        }
      }, []);

      const userEmail = decryptEmail(encryptedEmail);
      const double = decryptEmail(userEmail);

      // 
      const [annotation, setAnnotation] = useState('');

      const handleAnnotationChange = (event) => {
        setAnnotation(event.target.value);
      };


      // useEffect(() => {
      //   if (carousel.current) {
      //     setTotal(carousel.current.slick.slideCount);
      //   }
      // }, [carousel.current]);
      // const [images, setImages] = useState([
      //   {
      //     id: 1,
      //     src:garcia,
      //     alt: "garcia"
      //   },
      //   {
      //     id: 2,
      //     src:dimon,
      //     alt: "dimon"
      //   },
      //   {
      //     id: 3,t
      //     src:cs,
      //     alt: "c.s. riley"
      //   }
      // ]);
      const [fullScreen, setFullScreen] = useState(false);
      const [larger, setLarger] = useState(false);
      
      const [annotationExists, setAnnotationExists] = useState(false);
      const [someState, setSomeState] = useState(false);

      function toggleLarger(){
        setLarger(!larger);
        onCarouselStateChange(larger);
      }
      const navigate = useNavigate();

      function backToAlbums() {
        navigate('/album-list');
        onCarouselStateChange(false);
      }

      useEffect(() => {
        // This code block will run whenever images changes
        console.log('Images have changed:', images);
        setTotalSlides(images.length);
        // You can perform actions based on the images change here
        // For example, fetch data, update state, etc.
        
      }, [currentItemId, images, totalSlides]);

      const handleNext = () => {
      if (carousel.current) {
        carousel.current.slickNext();
        if(currentItemId===totalSlides-1) {
          setCurrentItemId(0);
        } else {
          setCurrentItemId(currentItemId+1)
        }
      }
    };
    const handlePrev = () => {
      if (carousel.current) {
        carousel.current.slickPrev();
        if(currentItemId===0) {
          setCurrentItemId(totalSlides-1);
        } else {
          setCurrentItemId(currentItemId-1)
        }
      }
    };


      // so users can click on arrows and trigger next/previous:
      useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'ArrowRight') {
            handleNext();
          } else if (event.key === 'ArrowLeft') {
            handlePrev();
          }
        };
    
        document.addEventListener('keydown', handleKeyDown);
    
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
        onCarouselStateChange(false);
      }, [currentItemId, totalSlides]);

      // const handleStateChange = () => {  
      //   // Call the parent component's function passed as a prop
      //   onCarouselStateChange(larger);
      // };
      function theTest() {
        alert(annotation)
      }
      const [doEdit, setDoEdit] = useState(false);
      function makeEdit() {
        // setDoEdit(true);
        setRender("");
        // setAnnotationExists(true);
        // setAnnotationExists(false);

      }

      const uploadAnnotation = async () => {
        if (annotation.trim().length === 0) {
          alert('Please enter some text before uploading');
          return; // Stop further execution
        }
        try {
          const userDocRef = doc(db, 'albums', double);
          const albumCollectionRef = collection(userDocRef, albumName);
      
          // Set the annotation for the specific item ID inside the albumName map
          await setDoc(doc(albumCollectionRef, currentItemId.toString()), { "anno": annotation });
          console.log('Annotation data successfully pushed to Firestore');
          setShowRender(true);
          setRender(annotation);
          setAnnotation('');
          // setAnnotationExists(true);
          // passing in params to make slick go to right photo:
            // const url = new URL(window.location.href);
            // const params = new URLSearchParams(url.search);
            // params.set('annotationMade', currentItemId);
            // url.search = params.toString();
            
            // Update the URL without triggering a page reload
            // window.history.replaceState({}, '', url.toString());

            // Now trigger a window reload
            // setShowRender(true);
            // setCurrentItemId(currentItemId+1);
            // setCurrentItemId(currentItemId-1);
            setSomeState(true);
            // window.location.reload();
        } catch (error) {
          console.error('Error pushing annotation data to Firestore:', error);
        }
      };
      



      const handleSlideChange = (currentSlideIndex) => {
        // if (carousel.current) {
        //   setTotal(carousel.slideCount);
        //   alert('dfgdfg')
        //   // setCurrentItemId(currentItemId+1)
        // }
          // setCurrentItemId(currentSlideIndex);
          setShowRender(true);
        
        
      };
      const handleAfterChange = (currentIndex) => {
        // The currentIndex here represents the updated slide index after change
        // setCurrentItemId(currentIndex % currentItemId);
        // if(currentItemId===totalSlides-1) {
          // setCurrentItemId(0);
          // setShowRender(true);
        // } else {
          // setCurrentItemId(currentIndex);
          setShowRender(true);
        // }
      };
      // useEffect that grabs image comment from firestore...
      useEffect(() => {
        const fetchData = async () => {
          try {
            const userDocRef = doc(db, 'albums', double);
            const albumCollectionRef = collection(userDocRef, albumName);
            const docRef = doc(albumCollectionRef, currentItemId.toString());
    
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              // alert('yup')
              setAnnotationExists(true);
              const data = docSnapshot.data();
              if (data && data.anno) {
                // alert('setting render')
                setRender(data.anno);
                console.log('Annotation fetched from Firestore:', data.annotation);
              }
            } else {
              // alert('no')
              console.log('No annotation found for the current item in Firestore');
              setRender("");
            }
          } catch (error) {
            // alert('error')
            setRender("");
            console.error('Error fetching annotation from Firestore:', error);
          }
        };
    
        fetchData();
      }, [double, albumName, currentItemId,someState]);


      // fetching number of photos:
      

      // double, albumName, currentItemId,someState

    // testing usEffect that changes when item.id changes

    function cancelAnnotation() {
        setAnnotationExists(true);
        setAnnotation('');
        // make so that if there is an annotation, and cancel is clicked ^ that happens
        // but otherwise, if no annotation, it justs
    }

    // setting the width:
    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);

    return(
        <>
        
        <div className="center-stuff">
        <div style={{marginLeft:"10px", marginRight:"10px",marginTop:"10px"}} onClick={() => handlePrev()}>
        <BsFillArrowLeftCircleFill style={{cursor:"pointer",}} size={32} />
        </div>
        {currentItemId+1}/{totalSlides}
        <div style={{marginLeft:"10px", marginRight:"10px",marginTop:"10px"}} onClick={() => handleNext()}>
        <BsFillArrowRightCircleFill style={{cursor:"pointer"}} size={32} />
        </div>        
        <div style={{cursor:"pointer",position:"absolute",left:"5%",top:"25px"}}>
          <button className="read-more-button" onClick={backToAlbums}>Back</button>
        </div>
        </div>
        {/* testing arrows on either side of image when browser=wide */}
        {/* lol didn't end up doing that... */}
        {/* done testing arrows */}
        {larger && 
        <>
          <div style={{position:"absolute",top:25,left:"45%",zIndex:1,cursor:"pointer"}}>
          <BsFillArrowLeftCircleFill onClick={() => carousel.current.slickPrev()} style={{cursor:"pointer",zIndex:2, color:"black",backgroundColor:"lightgrey",padding:'5px',borderRadius:"50%",marginRight:"25px"}} size={32} />
          <BsFillArrowRightCircleFill onClick={() => carousel.current.slickNext()} style={{cursor:"pointer",zIndex:2, color:"black",backgroundColor:"lightgrey",padding:'5px',borderRadius:"50%"}} size={32} />
          </div>
          <div style={{position:"absolute",top:25,right:55,zIndex:1}} onClick={toggleLarger}>
  </div>
        </>
        }
        <div className="center-stuff">
                {/*  */}        

                <div className={!larger ? 'slick-border' : 'slick-border-larger'}>
                        <Slider {...settings} ref={carousel} afterChange={handleAfterChange} onChange={handleSlideChange} >
                        {images.map((item) => (
                        <div key={item.id}>
                        {item.src && (item.src.includes('.mp4') ||
                        item.src.includes('.mov') ||
                        item.src.includes('.avi') ||
                        item.src.includes('.webm') ||
                        item.src.includes('.mkv')) 
                          ?
                          <video className={!larger ? 'fit-image' : 'fit-image-larger'} controls >
                          <source type="video/mp4" src={item.src}  alt={item.alt} onLoad={() => handleSlideChange(item.id)} />
                          Your browser does not support the video tag.
                        </video>
                          :
                          <img className={!larger ? 'fit-image' : 'fit-image-larger'} src={item.src}  alt={item.alt} onLoad={() => handleSlideChange(item.id)} />
                        }
                                                {/* !annotationExists && */}
                        { currentUser && currentUser.email===double && !render.length > 0 && 
                        <>
                        <div className="center-stuff">           
                          <textarea style={{marginTop:"10px"}} rows="4" cols="40" placeholder="Type here..."
                          value={annotation}
                          onChange={handleAnnotationChange}
                          />
                        </div>  
                        <div className="center-stuff">
                          {/* <button style={{marginLeft:"200px",marginTop:"10px", cursor:"pointer"}} onClick={cancelAnnotation}>Cancel</button> */}
                          <button style={{marginLeft:"10px",marginTop:"10px", cursor:"pointer"}} onClick={uploadAnnotation}>Annotate</button>
                        </div>
                        </>
                        }
                        {annotationExists && render.length > 0 && 
                        <>
                          <div className="center-stuff">
                          {showRender &&<div className="image-comment">
                            {render}
                          </div>}
                        </div>
                        <div className="center-stuff">
                          {currentUser && currentUser.email===double && 
                            <button style={{marginLeft:theWidth<450 ?"0px" :"350px",marginTop:"10px", cursor:"pointer"}} onClick={makeEdit}>Edit Annotation</button>
                          }
                          </div>
                        </>
                        }
                        </div>
                        ))}
                        </Slider>
                        {/* <br/> */}
                </div>
        </div>
        </>
    )
};