import React, { useEffect, useRef, useState, useContext } from 'react';
import { useNavigate  } from 'react-router-dom'
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';
import '../somecss.css'
import {BsCaretDownFill, BsCaretUpFill, BsFillCheckCircleFill, BsFillXCircleFill, 
  BsXCircleFill, BsHandIndexFill, BsXCircle, BsCheckCircleFill,BsFillHouseFill} from "react-icons/bs";

import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';


function ComingSoon({ katePass, showKate }) {
  const navigate = useNavigate();
  const auth = getAuth(app);
  const { login } = useAuth()

  const  passwordForKate = process.env.REACT_APP_PASSWORD_FOR_KATE;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // error
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false); 
  const [failureMessage, setFailureMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // real deal login here:
  const handleLog = async () => {
    setSuccess(false);
    setFailure(false);
    setLoading(true);
    try {
      // await login(email, password);
      await login(email, password).then(()=>{
        setLoading(false);
        navigate('/home');
      })
      // await signInWithEmailAndPassword(auth, email, password);
      console.log('User logged in successfully');
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setFailureMessage(error.message);
      console.error('Error logging in:', error.message);
      setFailure(true);
    }
  }

  // width/picture resizing and navigation below

    const [theWidth, setTheWidth] = useState(window.innerWidth);
    const [quote, setQuote] = useState();

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);


    function toHome() {
        navigate('/home');
    }
    function toSignUp() {
      navigate('/sign-up');
  }
  function toPhotos() {
    navigate('/album-list');
}
  const [emailSent, setEmailSent] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailReset, setEmailReset] = useState('');
  const [errorReset, setErrorReset] = useState(false);
  const [errorText, setErrorText] = useState('');

  // password reset
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, emailReset)
      setErrorReset(false);
        setEmailSent(true);
        // alert('sent email')
        // setMessage(error.message);
        // setPasswordError(true);
    } catch(error) {
      setEmailSent(false);
      setErrorReset(true);
      setErrorText(error);
    }
    
  };
  const [reset, setReset] = useState(false);
  function passwordResetStart() {
    setReset(true);
  }
  function hideReset() {
    setErrorText('');
    setErrorReset(false);
    setReset(false);
    setEmailReset('');
  }

  return (
    <>
    {/* <div className={loading || reset ? "blur-it" : ""}> */}
    <div className="title">
      <h1 className="the-titles" >Stories</h1>
    </div>
        <div style={{
          backgroundImage: `url(${newpic})`,
          backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: theWidth,
             }}> 
          {/* new sign up / home styles */}
          <div style={{position:"absolute", right:"2%", top:"11px"}}>
            {/* <button className="button-71" onClick={toPhotos}>Content</button> */}
          </div>
          <div onClick={toHome} style={{position:"absolute", left:"5%", top:"20px",cursor:"pointer"}}>
      <BsFillHouseFill fontSize={28} color="#0078d0" />
      </div>
        <div className="center-stuff">
        <div className="sign-up">
        <div className="center-stuff">
          <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"15px",padding:"15px"}} >Coming Soon!</div>
        </div>
        {/* <div className="center-stuff">
          <div style={{color:"black", fontFamily:"Helvetica Neue", margin:"5px",marginTop:"15px"}} >Other Versions:</div>
        </div> */}
    </div>
    </div>
    </div>
    {theWidth>850 ?
    <>
    </>
    :
    <div className="title">
    <h4 style={{marginTop:25, marginRight:50,marginLeft:50,marginBottom:25,fontFamily:"Helvetica Nue"}}>
   <i>
   “When I left England I had no definite plans as to what I was going to do (for I did not know myself) and no pledges, except to my mother that I would return at the end of two years”</i>
   <br/><br/> 
   - R.T. Riley
    </h4>
    </div>
    }
    </>
  );
}

export default ComingSoon;