import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../dropDown';
import LoadingSpinner from './loadingSpinner';
import {BsHouseFill, BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill} from "react-icons/bs";
import { useNavigate  } from 'react-router-dom'
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_riley.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import signature from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sig-newest.png';

function Activities() {
  const navigate = useNavigate();

  // testing function
  function testIt() {
    alert('Will make this button render the appropriate itinerary')
  }
  // naviagation:

  function toHome() {
    navigate('/home');
  }
  function toTree() {
    navigate('/stories');
  }
  function toProfile() {
    navigate('/profiles')
  }
  function toFaqs() {
    navigate('/faqs');
  }
  function toPhotos() {
    navigate('/album-list');
  }
  function toWall() {
    navigate('/community-wall');
}
function toAgenda() {
  navigate('/agenda');
}

  const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

const [showActivities, setShowActivities] = useState(true);
const [showAttractions, setShowAttractions] = useState(false);

function toActivities() {
    setShowActivities(true);
    setShowAttractions(false);
}
function toAttractions() {
    setShowActivities(false);
    setShowAttractions(true);
}
  return (
    <>
    {theWidth>750 ?
      <div>
      <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    {/* <div>
    <div style={{position:"absolute",left:"4%", top:"1.5%"}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div 
            className="profile-link"
    >Profile</div>
    <BsPersonVcard onClick={toProfile} 
    className="person-vcard"
    />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"3.5%", top:"1.5%",}}>Photos</div>
    <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"13.3%", top:"1.5%",}}>Agenda</div>
    <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"24.5%", top:"1.5%",}}>Tree</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} />
    </div> */}
    </div>

    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    {/* <BsHouseFill onClick={goHome} style={{cursor:"pointer",position:"absolute",right:"75px"}} /> */}
    {/* <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    {/* <h1 className="the-titles" >Agenda</h1> */}
    </div>
    <div style={{
          // backgroundImage: `url(${newpic})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
             }}>
             {theWidth>450 &&<div style={{position:"absolute", right:`${theWidth/100-15}%`, top:"200px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
              <img src={signature} style={{width:`${theWidth/5+20}px`}} />
            </div>}
            {theWidth>450 &&<div style={{position:"absolute", left:`${theWidth/100+7}%`, top:"200px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
              <img src={signature} style={{width:`${theWidth/5+20}px`}} />
            </div>}
    <div className="center-stuff"
    // style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",}}
    >
    <div>
    {/* <div style={{marginTop:"10px"}}>
    <div className="center-stuff">
    <a href="#" onClick={toAgenda}>Agenda</a>
    </div>
    </div> */}
    {/* <div className="center-stuff">
        <div className="center-stuff-agenda">
    <button disabled={showActivities} className="agenda-button" onClick={toActivities} style={{margin:"10px", cursor:"pointer"}}>Activities</button><button disabled={showAttractions} className="agenda-button" onClick={toAttractions} style={{margin:"10px", cursor:"pointer"}}>Attractions</button>
    </div>
    </div> */}
    <div className="reunion-schedule">
      {true &&
        <>
        <div className="center-stuff">
        <h2 className="the-a">Activities & Attractions</h2>
        </div>
        <div className="center-stuff">
        <i>
        <span style={{borderBottom:"1px solid black",padding:"10px"}}>
        (See Also:&nbsp;<a href="#" onClick={toAgenda}>Agenda</a>)
        </span>
        </i>
    </div>
        <div style={{borderBottom: "1px solid black",margin:"5px"}}>
        <h4 className="center-stuff">Omega Wildlife Park</h4>
        <p className="center-stuff"><i>Highly recommended; 3 miles travel</i></p>
        </div>
        <div>
        <h4 className="center-stuff" >Hiking Trails</h4>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >Mountain Biking</h4>
        <p className="center-stuff" ><i>Bicycles available for rent</i></p>
        </div>
        <div>
        <h4 className="center-stuff" >Health Club</h4>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >Pool Facility</h4>
        </div>
        <div>
        <h4 className="center-stuff" >Squash Court</h4>
        <p className="center-stuff" ><i>Booked from <strong>9 AM - 11 AM Saturday</strong> </i></p>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >Tennis/Badminton/Squash Courts</h4>
        <p className="center-stuff" ><i>Please call Rosalie to reserve: 819&nbsp;-&nbsp;423&nbsp;-&nbsp;3006</i></p>
        </div>
        <div>
        <h4 className="center-stuff" >Golf Course</h4>
        <p className="center-stuff" ><i>Course is open; for direct bookings: 819&nbsp;-&nbsp;423&nbsp;-&nbsp;4653</i></p>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >Driving Range</h4>
        </div>
        <div>
        <h4 className="center-stuff" >Arcade/Games Room</h4>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >Playground</h4>
        </div>
        <div>
        <h4 className="center-stuff" >Miniature Golf</h4>
        </div>
        <div style={{borderBottom: "1px solid black",margin:"5px",borderTop: "1px solid black"}}>
        <h4 className="center-stuff" >Gym and Fitness Facility</h4>
        </div>
        <div>
        <h4 className="center-stuff" >Volleyball</h4>
        <p className="center-stuff" ><i>Booked at <strong>11 AM Saturday</strong></i></p>
        </div>
        </>
      }
      
      <br/>
    </div>
    {/* <div className="center-stuff">
      <LoadingSpinner />
    </div> */}
    </div>
    </div>
    </div>
    </>
  );
}

export default Activities;