import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Select from 'react-select';
import CryptoJS from 'crypto-js';
// import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button, Alert, Spinner, Badge } from 'react-bootstrap'
import Dropdown from '../dropDown';
import { useNavigate  } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LoadingSpinner from './loadingSpinner';
// import goodfellasPainting from './goodfellas_painting.jpg';
// import garcia from './garcia_74.jpeg'
// import dimon from './jamie_dimon.jpeg'
import '../unused logic/carouselStyles.css'
import '../somecss.css'
import letter_r from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/letter_r.jpeg';
import {BsXCircle, BsFillUnlockFill,BsLockFill,BsUpload,BsArrow90DegUp,BsHouseFill, BsPersonVcard, BsCalendar2Day, BsImages, 
  BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill, BsArrowUpCircle,BsArrowDownCircle,BsPersonFill} from "react-icons/bs";
import reunion81 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_1981.png'
import newpic1 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic1.jpeg';
import newpic2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic2.jpeg';
import newpic3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic3.jpeg';
import newpic4 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic4.jpeg';
import newpic5 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic5.jpeg';
import reporter_81 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reporter_1981.pdf';
import reporter_01 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reporter_2001.pdf';
import jean from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/jean_reunion.png';
import { collection, addDoc, getFirestore, onSnapshot, getDoc, getDocs, doc, updateDoc, query, where } from "firebase/firestore";
import { listAll, getStorage, ref, getDownloadURL } from 'firebase/storage';
import { renderToStaticMarkup } from "react-dom/server";

function Profiles() {
// Lists all profiles that have been created
  const { currentUser } = useAuth();
  const db = getFirestore();
  const storage = getStorage();
  const personFill = renderToStaticMarkup(<BsPersonFill />);
    const svgString = `data:image/svg+xml,${encodeURIComponent(personFill)}`;
    const [loaded, setLoaded] = useState(false);

// navigate stuff below:
    const navigate = useNavigate();

    const [visibleProfiles, setVisibleProfiles] = useState(20); // State to track the number of visible comments


    function toTree() {
        navigate('/stories');
    }
    function toAgenda() {
        navigate('/agenda');
    }
    function toPhotos() {
        navigate('/album-list');
    }
    function toFaqs() {
        navigate('/faqs');
    }
    function toWall() {
        navigate('/community-wall');
    }
    function toSignUp() {
      navigate('/sign-up');
  }

// icons in top right
    function toHome(){
        navigate('/home');
      }
      function toProfile(){
        navigate('/profiles');
      }
      function toYourProfile(){
        navigate('/profile');
      }
      const theSecret = process.env.REACT_APP_SECRET;

      // encrypting and sending to OtherProfiles
      const encryptEmail = (email) => {
        try {
          const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
          return encodeURIComponent(encryptedEmail);
        } catch (error) {
          console.error('Encryption error:', error);
          return null;
        }
      };
    
      function toClickedProfile(dasEmail) {
        if(currentUser && currentUser.email===dasEmail){
          navigate("/profile");
          return;
        } else {
          const encryptedEmail = encryptEmail(dasEmail);
          if (encryptedEmail) {
            navigate(`/profiles/${encryptedEmail}`);
          } else {
            console.error('Encryption failed');
          }
        }
      
      }

      // picture resizing etc.
    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const x = theWidth/13.5;
const y = 50;

// hover underline styles and logi
// branch stuff:
const [g2Affiliation, setG2Affiliation] = useState('Conrad Stephenson Riley & Jean Isabel Culver');
const [loading, setLoading] = useState(false);


const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

// slide in effects...
const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Set a timeout to trigger the slide-in effect after a delay (e.g., 500ms)
  const timeout = setTimeout(() => {
    setIsVisible(true);
  }, 500);

  // Clear the timeout to avoid memory leaks
  return () => clearTimeout(timeout);
}, []);

// testing article data:
const articles = [
    {
      title: 'Example Name 1',
      author: 'Branch: Riley/Murgatroyed -> Riley/ -> Riley/Day -> Grant',
      date: 'Born: January 15, 2030',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ...',
      imageUrl: jean, // Replace with actual image path
    },
    {
      title: 'Example Name 2',
      author: 'Branch: Riley/Murgatroyed -> Riley/ -> Riley/Day -> Grant',
      date: 'January 10, 2024',
      content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
      imageUrl: jean, // Replace with actual image path
    },
    {
        title: 'Example Name 3',
        author: 'Branch: Riley/Murgatroyed -> Riley/ -> Riley/Day -> Grant',
        date: 'January 10, 2024',
        content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
        imageUrl: jean, // Replace with actual image path
      },
      {
        title: 'Example Name 4',
        author: 'Branch: Riley/Murgatroyed -> Riley/ -> Riley/Day -> Grant',
        date: 'January 10, 2024',
        content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
        imageUrl: jean, // Replace with actual image path
      },
      {
        title: 'Example Name 5',
        author: 'Branch: Riley/Murgatroyed -> Riley/ -> Riley/Day -> Grant',
        date: 'January 10, 2024',
        content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...',
        imageUrl: jean, // Replace with actual image path
      },
    // Add more articles as needed
  ];

  const options = [
    // { value: 'default', label: 'New Articles', isDisabled: true },
    { value: 'default', label: 'New Articles' },
    { value: 'in-memoriam', label: 'In Memoriam' },
    { value: '1981', label: 'Riley Reporter 1981' },
    { value: '2001', label: 'Riley Reporter 2001' },
  ];

 
  const handleOptionChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    if (selectedOption && (selectedOption.value === '1981')) {
        console.log('Option is 1981 or 2001');
        
      }  if (selectedOption && (selectedOption.value === '2001')) {
        
      }    if (selectedOption && (selectedOption.value === 'default')) {
        
      }  if (selectedOption && (selectedOption.value === 'in-memoriam')) {
        
      }      
      else {
        // Do something for other options
        console.log('Option is not 1981 or 2001');
        // Perform action Y
      }
  };
  

function toArticle(index) {
    // alert('this can render the article, ok? capiche?')
      navigate(`/example-${index+1}`);
}

const [selectedFilter, setSelectedFilter] = useState(null);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    // Perform filtering logic based on the selected filter
    // Update your list accordingly
  };

  const [branchChange, setBranchChange] = useState(false);

  const branchOptions = [
    { value: 'rsr', label: 'Robert Sanford Riley & Katharine Elizabeth Higgins' },
    { value: 'csr', label: 'Conrad Stephenson Riley & Jean Isabel Culver' },
    { value: 'lbr', label: 'Lavinia Bell Riley & Rev. E. Guthrie Perry'},
    // { value: 'hmr', label: 'Harriet Murgatroyd (Maud) Riley & Malcolm A. MacQueen' },
    { value: 'jhr', label: 'John Herbert Riley & Ivey C. Scott'},
    { value: 'hjr', label: 'Harold James Riley & Ruth M. Moore'},
  ];
  const handleBranchChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    if (selectedOption && (selectedOption.value === 'csr')) {
      setG2Affiliation('Conrad Stephenson Riley & Jean Isabel Culver');
      }  if (selectedOption && (selectedOption.value === 'rsr')) {
        setG2Affiliation('Robert Sanford Riley & Katharine Elizabeth Higgins');
      }    if (selectedOption && (selectedOption.value === 'lbr')) {
        setG2Affiliation('Lavinia Bell Riley & Rev. E. Guthrie Perry');
      }  if (selectedOption && (selectedOption.value === 'hmr')) {
        setG2Affiliation('Harriet Murgatroyd (Maud) Riley & Malcolm A. MacQueen');
      }  if (selectedOption && (selectedOption.value === 'jhr')) {
        setG2Affiliation('John Herbert Riley & Ivey C. Scott');
      }  if (selectedOption && (selectedOption.value === 'hjr')) {
        setG2Affiliation('Harold James Riley & Ruth M. Moore');
      }     
      else {
        // Do something for other options
        console.log('Option is not any of the listed ones');
        setBranchChange(false);
        // Perform action Y
      }
      setBranchChange(false);
  };


  // above here lies branches for g2 and g3
  const [branchData, setBranchData] = useState([]);  
  const [showProfiles,setShowProfiles] = useState(false);
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // fetching profile pics:
  const [profilePics, setProfilePics] = useState([]);
  // useEffect fetching profile pics from storage...
  useEffect(() => {
  const encounteredEmails = [];

  const fetchPics = async () => {
    try {
      setLoading(true);
      const profilePicPromises = branchData.map(async (content) => {
        // if (!encounteredEmails.includes(content.user)) {
        //   setEncounteredEmails(prevEmails => [...prevEmails, content.user]);
        if (!encounteredEmails.includes(content.id)) {
          encounteredEmails.push(content.id);
        try {
          const profilePicRef = ref(storage, `profilepics/${content.id}`);
          const imageRefs = await listAll(profilePicRef);
          const downloadedImage = await Promise.all(
            imageRefs.items.map(async (imageRef, index) => {
              const downloadURL = await getDownloadURL(imageRef);
              return { email: content.id, src: downloadURL }; // Modified structure
            })
          );
          return downloadedImage;
        } catch (error) {
          console.error('Error downloading images:', error);
          setLoading(false);
          alert('error loading profiles')
          return null;
        }
      }
      return null;
      });

      const profilePics = await Promise.all(profilePicPromises);
      // Flatten the array of arrays into a single array of objects
      const flattenedProfilePics = profilePics.flat().filter(pic => pic !== null);
      setProfilePics(flattenedProfilePics);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000); 
      return () => {
        setLoading(false);
      };
    } catch (error) {
      console.error('Error fetching profile pictures:', error);
    }
  };
  fetchPics();
  }, [branchData, storage]);

  //   // render changes to filters and the order and content of the profile renderings

  useEffect(() => {
    const profilesCollectionRef = collection(db, 'profiles');

    // Create a query to filter documents based on the "branch" field
    const branchQuery = query(profilesCollectionRef, where('branch', '==', g2Affiliation));

    // Set up a snapshot listener for real-time updates
    const unsubscribe = onSnapshot(branchQuery, (snapshot) => {

      const newData = [];

      snapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      // Update the local state with the filtered documents
      setBranchData(shuffleArray(newData));
      // shuffleArray(branchData);
    });

    // Cleanup function to unsubscribe from the snapshot listener
    return () => unsubscribe();
  },[g2Affiliation]);


  const loadMoreProfiles = () => {
    // Increase the number of visible comments by 10 or display all if remaining comments are less than 10
    setVisibleProfiles(prevVisibleComments => Math.min(prevVisibleComments + 10, branchData.length));
  };


  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <>  
  
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    </div>
    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>
    <div style={{marginLeft:"10px"}}>
        <button className="stories-button" onClick={toYourProfile}>View Your Profile</button>
      </div>
      <div className={loading ? "blur-it" : ""}>

          {/* testing FILTER STUFF below */}
    <div className="filter-section">
      <p style={{fontSize:"20px"}}>Filter by G2 branch:</p>
      <Select onChange={handleBranchChange} options={branchOptions} defaultValue={branchOptions[1]} isSearchable={true}/>
    </div>
    {/* <div className="filter-section">
      <p style={{fontSize:"20px"}}>Filter by G3 branch:</p>
      <Select onChange={handleBranchChangeG3} options={branchOptionsG3} defaultValue={branchOptionsG3[0]} isSearchable={true}/>
    </div> */}

    {/* testing above */}
    {/* toggle between "Riley Reporter", "New Articles", "In Memoriam" etc. */}
    {/* <select className="stories-button" defaultValue="default">
    <option value="default" disabled>Riley Reporter</option>
    <option value="1981">1981 Version</option>
    <option value="2001">2001 Version</option>
    </select> */}
    {/* {theWidth<750 && isUp ? <></> :<button disabled={newArticles} onClick={handleNewArticles} className="stories-button">New Articles</button>} */}
    {/* <div style={{marginTop:"10px",marginBottom:"10px"}}><Select  onChange={handleOptionChange} options={options} defaultValue={options[0]} isSearchable={true}/></div> */}
    {/* {theWidth<650 && isUp ? <></> : <button disabled={inMemoriam} onClick={handleInMemoriam} className="stories-button">In Memoriam</button>} */}
   {/* name, age, branch, about me ???  */}
   {branchData.length ===0 && <><div className="center-stuff"><i style={{margin:"25px"}}>No users have affiliated themselves with this branch yet</i></div></>}
      {branchData.slice(0, visibleProfiles).map((data, index) => {
        const matchingProfilePic = profilePics.find(pic => pic.email === data.email);
        return(
          <div key={index} className="profiles-container" onClick={() => toClickedProfile(data.email)}>
          {matchingProfilePic ? (
            <img  src={matchingProfilePic.src} alt={index} loading="lazy"  onLoad={handleImageLoad}
              style={{border:"solid black 0px",borderRadius:"50%",objectFit: 'cover',objectPosition:"top",
              width:"200px", height:"200px",marginTop:"20px",marginBottom:"20px",marginLeft:"10px",
              filter: `blur(${loaded ? '0px' : '20px'})`,transition: 'filter 1s ease'}} 
            />
          ) : (
            <div style={{marginTop:"50px"}}>
            <BsPersonFill style={{fontSize:"124px", width:"200px"}} />
            </div>
          )}


          <div className="long-profiles-details">
            <div>
            <h2>{data.username} 
            {/* <button onClick={() => toClickedProfile(data.email)} className="read-more-button">Go To Profile</button> */}
            </h2>
            </div>
            <p>Age: {data.age}</p>
            <p>Branch: {data.branch}</p>
            {/* <p>Phone number: {data.phone}</p> */}
            {/* <p>Address: {data.address}</p> */}
            <p>About: {data.aboutme.slice(0, 15)}...</p>
          </div>
          </div>
      )})}
    </div>
    <div className="center-stuff">
          {!loading && visibleProfiles < branchData.length && (
            <>
            <div style={{marginBottom:"20px"}}>
            <button className="read-more-button" onClick={loadMoreProfiles}>Load More ({branchData.length-visibleProfiles})</button>
            </div>
        </>
      )}
        </div>
    {loading && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }
    </>
  );
}

export default Profiles;