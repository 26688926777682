import React, { useEffect, useRef, useState }  from 'react';
import { useNavigate  } from 'react-router-dom'
import newpic1 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic1.jpeg';
import CryptoJS from 'crypto-js';
import Dropdown from '../dropDown';
import { useParams } from 'react-router-dom';
import { collection, addDoc, getFirestore, onSnapshot, getDoc, getDocs, doc, updateDoc, query, where } from "firebase/firestore";
import { listAll, getStorage, ref, getDownloadURL,getMetadata,list } from 'firebase/storage';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import {BsXCircle, BsFillUnlockFill,BsLockFill,BsUpload,BsArrow90DegUp,BsHouseFill, 
  BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill, 
  BsArrowUpCircle,BsArrowDownCircle,BsPersonFill} from "react-icons/bs";

const TheArticles = () => {
  const { currentUser } = useAuth();
    const { theEmail, theTitle } = useParams();
    const db = getFirestore();
    const storage = getStorage();
    // theEmail/:theTitle
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState(null);

  function toStories() {
    navigate('/stories');
  }
  function toTree() {
    navigate('/stories');
}
function toAgenda() {
    navigate('/agenda');
}
function toPhotos() {
    navigate('/album-list');
}
function toFaqs() {
    navigate('/faqs');
}
function toWall() {
    navigate('/community-wall');
}
function toSignUp() {
  navigate('/sign-up');
}

// icons in top right
function toHome(){
    navigate('/home');
  }
  function toProfile(){
    navigate('/profiles');
  }

  const theSecret = process.env.REACT_APP_SECRET;

  const decryptEmail = (theEmail) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(theEmail), theSecret);
      const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedEmail;
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  };
  const userEmail = decryptEmail(theEmail);

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);


//   fetch document in articles:
const [articleData, setArticleData] = useState(null);
const [imageUrl, setImageUrl] = useState(null);
const [imageArticleUrl, setImageArticleUrl] = useState(null);
const [imageUrls, setImageUrls] = useState([]);

useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Reference to the "articles" collection
        const articlesCollectionRef = collection(db, 'articles');
        // console.log(userEmail);
        // console.log(theTitle);
        // Create a query with conditions for userEmail and theTitle
        const q = query(
          articlesCollectionRef,
          where('author', '==', userEmail),
          where('title', '==', theTitle)
        );

        // Fetch the documents that match the conditions
        const querySnapshot = await getDocs(q);

        // Check if there's a matching document
        if (!querySnapshot.empty) {
          // Assuming there's only one matching document, use the first one
          const article = querySnapshot.docs[0].data();
          
          // Set state with the article data
          setArticleData(article);
        } else {
          // Handle case where no matching document is found
          console.log('No matching document found.');
        }
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    // Call the fetchArticle function
    fetchArticle();
  }, [userEmail, theTitle]);

//   fetch image from storage:
useEffect(() => {
    const fetchImage = async () => {
      try {
        // Fetch the list of items in the specified directory
        const storageRef = ref(storage, `articles/${userEmail}/${theTitle}`);
        const items = await list(storageRef);

        // Check if there are any items
        if (items.items.length > 0) {
          // Fetch the download URL of the first item
          const downloadURL = await getDownloadURL(items.items[0]);
          setImageUrl(downloadURL);
        } else {
          console.log('No items found in the directory.');
        }
        // Fetching article image if there's no article string
        if(!articleData) {
          console.log('image url being fetched');
          const storageRef = ref(storage, `articles/${userEmail}/articleimages/${theTitle}`);
          const items = await list(storageRef);
  
          // Check if there are any items
          if (items.items.length > 0) {
            // Fetch the download URL of the first item
            // const downloadURL = await getDownloadURL(items.items[0]);
            // setImageArticleUrl(downloadURL);
            const urls = await Promise.all(items.items.map(async (item) => {
              return getDownloadURL(item);
            }));
            setImageUrls(urls);
          } else {
            console.log('No items found in the directory.');
          }
        }

      } catch (error) {
        console.error('Error fetching article and image:', error);
      }
    }
    fetchImage();
  }, [userEmail, theTitle]);

  // fetch usernames:
  const [theUsername, setTheUsername] = useState('');
useEffect(() => {
  const fetchUserProfile = async () => {
    try {
      if (userEmail) {
        const userDocRef = doc(db, 'profiles', userEmail);
        const userDocument = await getDoc(userDocRef);

        if (userDocument.exists()) {
          // Document found, extract and set the username to the state
          const username = userDocument.data().username;
          setTheUsername(username);
        } else {
          // Document not found, handle accordingly
          console.log('User profile not found');
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  fetchUserProfile();
}, [userEmail]);

const encryptEmail = (email) => {
  try {
    const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
    return encodeURIComponent(encryptedEmail);
  } catch (error) {
    console.error('Encryption error:', error);
    return null;
  }
};
const encryptedEmail = encryptEmail(userEmail);
const double = decryptEmail(encryptedEmail);
const triple = encryptEmail(double);

function toClickedProfile() {
  if(currentUser && currentUser.email===userEmail) {
    navigate(`/profile`);
    return;
  } else {
    const encryptedEmail = encryptEmail(userEmail);
    if (encryptedEmail) {
      navigate(`/profiles/${encryptedEmail}`);
    } else {
      console.error('Encryption failed');
    }
  }
 
}

const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // useEffect snatching pdf if it exists
    useEffect(() => {
      const downloadPdf = async () => {
        // const encryptedEmail = encryptEmail(userEmail);
        try {
          // const pdfRef = ref(storage, `articles/${userEmail}/${theTitle}/pdf`);
          // const url = await getDownloadURL(pdfRef);
          // setPdfUrl(url);
          // or this:
           // Check if the PDF file exists
        // const metadata = await getMetadata(pdfRef);
        // if (metadata && metadata.size > 0) {
        //   const url = await getDownloadURL(pdfRef);
        //   setPdfUrl(url);
        // } else {
        //   console.log('PDF file does not exist.');
        // }
        // or this:
        const emailHash = CryptoJS.SHA256(userEmail).toString(CryptoJS.enc.Hex);
        console.log("hashed email:",emailHash);
        const storageRef = ref(storage, `articles/${emailHash}/${theTitle}/pdf`);
        const items = await list(storageRef);
        // Check if there are any items
        if (items.items.length > 0) {
          // Fetch the download URL of the first item
          console.log('dfgdfg');
          const downloadURL = await getDownloadURL(items.items[0]);
          setPdfUrl(downloadURL);
        } else {
          console.log('No ayayaya');
        }
        // 
        } catch (error) {
          console.error('pdf may not exist or there is an error');
          // You might want to handle the error here
        }
      };
  
      downloadPdf(); // Call the downloadPdf function when the component mounts
  
      // Cleanup function (optional)
      return () => {
        // You can add cleanup code here if needed
      };
    }, [theEmail, theTitle]);

    // function to new tab of pdf:
    function toPdf(theUrl) {
      if (theUrl) {
        window.open(theUrl, '_blank');
      } else {
        console.error('It failed');
      }
    }

  return (
    <>
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Stories</div>
    </div>
    </div>
    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown />
    </>
    }
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>
    <div className="article">
    {/* <div className="center-stuff"> */}
    {/* <div style={{position:"absolute",top:"0px"}}>
    <div>
    <button className="stories-button" onClick={toStories}>Back to Stories</button>
    </div>
    </div> */}
    <div className="center-stuff">
    <img src={imageUrl} alt="Article Cover" style={{maxHeight:"75vh",maxWidth:"100vw",border:"3px double black",marginTop:"5px"}} />
    </div>
        {/* {imageUrl && <>fdsfsd</>} */}
      <div className="article-details">
        <h1 className="center-stuff"><div style={{marginBottom:"-10px"}}>{theTitle}</div></h1>
        <p className="center-stuff">By:&nbsp;<a onClick={toClickedProfile} href="#"> {theUsername}</a></p>
        <p className="center-stuff"> {articleData && articleData.timestamp.toDate().toLocaleString()}</p>
        {pdfUrl &&<p className="center-stuff">&#11088; &nbsp;<a onClick={() => toPdf(pdfUrl)} href="#">Open PDF in new tab</a>&nbsp; &#11088;</p>}
        <div className="center-stuff">
          <button className="stories-button" onClick={toStories}>Back to Stories</button>
          </div>
        </div>
        <div className="center-stuff">
          <div style={{ maxWidth: "90vw", width: "100%", wordWrap: "break-word",  whiteSpace: "pre-line"}}>
          {articleData &&
            articleData.content &&
            articleData.content.split(/___LINE_BREAK___/).map((paragraph, index) => (
              <p key={index} style={{ textIndent: "1em" }}>
                {paragraph}
              </p>
            ))}
          </div>
      </div>
      {pdfUrl &&
        <div className="center-stuff">
        <iframe src={pdfUrl} type="application/pdf" className="custom-iframe"  />
        </div>
      }

      {imageUrls && <div className="center-stuff">
      {imageUrls.map((imageUrl, index) => (
      <img key={index} src={imageUrl} alt={`Image ${index + 1} of the article`} style={{ width: "100vw" }} />
    ))}
    {/* <img src={imageArticleUrl} alt="Image of the article" style={{width:"100vw"}} /> */}
    </div>}
    </div>
    </>
  );
};

export default TheArticles;