import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Select from 'react-select';
// import 'bootstrap/dist/css/bootstrap.css';
import { Card, Button, Alert, Spinner, Badge } from 'react-bootstrap'
import Dropdown from '../dropDown';
import { useNavigate  } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LoadingSpinner from './loadingSpinner';
// import goodfellasPainting from './goodfellas_painting.jpg';
// import garcia from './garcia_74.jpeg'
// import dimon from './jamie_dimon.jpeg'
import CryptoJS from 'crypto-js';
import Masonry from 'masonry-layout';
import '../unused logic/carouselStyles.css'
import '../somecss.css'
import letter_r from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/letter_r.jpeg';
import {BsXCircle, BsFillUnlockFill,BsLockFill,BsUpload,BsArrow90DegUp,BsHouseFill, 
  BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill, 
  BsArrowUpCircle,BsArrowDownCircle,BsPersonFill} from "react-icons/bs";
import reunion81 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reunion_1981.png'
import newpic1 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic1.jpeg';
import newpic2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic2.jpeg';
import newpic3 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic3.jpeg';
import newpic4 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic4.jpeg';
import newpic5 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic5.jpeg';
import reporter_81 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reporter_1981_compressed.pdf';
import reporter_01 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/reporter_2001-compressed.pdf';
import { collection, addDoc, getFirestore, onSnapshot, getDoc, getDocs, doc, updateDoc, query, where } from "firebase/firestore";
import { listAll, getStorage, ref, getDownloadURL } from 'firebase/storage';

function Stories() {

  const { currentUser } = useAuth();
  const db = getFirestore();
  const storage = getStorage();
// navigate stuff below:
    const navigate = useNavigate();

    function toTree() {
        navigate('/stories');
    }
    function toAgenda() {
        navigate('/agenda');
    }
    function toPhotos() {
        navigate('/album-list');
    }
    function toFaqs() {
        navigate('/faqs');
    }
    function toWall() {
        navigate('/community-wall');
    }
    function toSignUp() {
      navigate('/sign-up');
  }
  function toUploadArticle() {
    navigate('/stories-upload');
  }
  function toUploadObit() {
    navigate('/stories-upload-obit');
  }

// icons in top right
    function toHome(){
        navigate('/home');
      }
      function toProfile(){
        navigate('/profiles');
      }

    

      // picture resizing etc.
    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const x = theWidth/13.5;
const y = 50;

// hover underline styles and logic

const [agendaHover, setAgendaHover] = useState(false);
const [photosHover, setPhotosHover] = useState(false);
const [treeHover, setTreeHover] = useState(false);
const [historyHover, setHistoryHover] = useState(false);
const [wallHover, setWallHover] = useState(false);
const [profileHover, setProfileHover] = useState(false);

// reporter state:
const [reporter81, setReporter81] = useState(false);
const [reporter01, setReporter01] = useState(false);
const [newArticles, setNewArticles] = useState(true);
const [inMemoriam, setInMemoriam] = useState(false);
const [reporter, setReporter] = useState(false);

const [loading, setLoading] = useState(false);

// article stuff:
const [articleData, setArticleData] = useState([]);
const [articlePictures, setArticlePictures] = useState([]);
const [theUsernames, setTheUsernames] = useState([]);

// obit stuff:
const [obitData, setObitData] = useState([]);
const [obitPictures, setObitPictures] = useState([]);
// const [] = useState(false);
// const [] = useState(false);

const agendaEnter = () => {
  setAgendaHover(true);
};
const photosEnter = () => {
  setPhotosHover(true);
};
const treeEnter = () => {
  setTreeHover(true);
};
const historyEnter = () => {
  setHistoryHover(true);
};
const wallEnter = () => {
  setWallHover(true);
};
const profileEnter = () => {
  setProfileHover(true);
};

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

// slide in effects...
const [isVisible, setIsVisible] = useState(false);

useEffect(() => {
  // Set a timeout to trigger the slide-in effect after a delay (e.g., 500ms)
  const timeout = setTimeout(() => {
    setIsVisible(true);
  }, 500);

  // Clear the timeout to avoid memory leaks
  return () => clearTimeout(timeout);
}, []);

const openPDF = () => {
    window.open('/path/to/your/pdf/document.pdf', '_blank');
  };

// testing article data:

  const options = [
    // { value: 'default', label: 'New Articles', isDisabled: true },
    { value: 'default', label: 'New Articles' },
    { value: 'in-memoriam', label: 'In Memoriam' },
    { value: '1981', label: 'Riley Reporter 1981' },
    { value: '2001', label: 'Riley Reporter 2001' },
  ];

  function handleNewArticles() {
    setNewArticles(true);
    setReporter01(false);
    setReporter81(false);
    setIsUp(false);
    setInMemoriam(false);
  }
  function handleInMemoriam() {
    setNewArticles(false);
    setReporter01(false);
    setReporter81(false);
    setIsUp(false);
    setInMemoriam(true);
  }
  function handle1981() {
    setNewArticles(false);
    setReporter01(false);
    setReporter81(true);
    setInMemoriam(false);
  }
  function handle2001() {
    setNewArticles(false);
    setReporter01(true);
    setReporter81();
    setInMemoriam(false);
  }
  const handleOptionChange = (selectedOption) => {
    // console.log('Selected Option:', selectedOption);
    if (selectedOption && (selectedOption.value === '1981')) {
        // console.log('Option is 1981 or 2001');
        handle2001();
        setPdfLoadingState(true);
      }  if (selectedOption && (selectedOption.value === '2001')) {
        handle1981();
        setPdfLoadingState(true);
      }    if (selectedOption && (selectedOption.value === 'default')) {
        handleNewArticles();
        // setPdfLoadingState(true);
      }  if (selectedOption && (selectedOption.value === 'in-memoriam')) {
        handleInMemoriam();
        // setPdfLoadingState(true);
      }      
      else {
        // Do something for other options
        // console.log('Option is not 1981 or 2001');
        // Perform action Y
      }
  };
  const [loaded, setLoaded] = useState(false);

  const [isUp, setIsUp] = useState(false);

  function toggleUp() {
    setIsUp(true);
  }
  function toggleDown() {
    setIsUp(false);
  }

  function open1981NewTab() {
    window.open(reporter_81, '_blank');

  }
  function open2001NewTab() {
    // these open in main window...
    // window.open(reporter_01, '_self');
    // window.open(reporter_01, '_parent'); 
    // window.open(reporter_01, '_top'); 
    window.open(reporter_01, '_blank');
}
const theSecret = process.env.REACT_APP_SECRET;

    const encryptEmail = (email) => {
      try {
        const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
        return encodeURIComponent(encryptedEmail);
      } catch (error) {
        console.error('Encryption error:', error);
        return null;
      }
    };
function toArticle(theEmail, articleName) {
    // alert('this can render the article, ok? capiche?')
      const encryptedEmail = encryptEmail(theEmail);
      const sanitizedTitle = encodeURIComponent(articleName);
      if (encryptedEmail) {
        navigate(`/article/${encryptedEmail}/${sanitizedTitle}`);
      } else {
        console.error('Encryption failed');
      }
}
function toClickedProfile(dasEmail) {
  if(currentUser && currentUser.email===dasEmail){
    navigate("/profile");
    return;
  } else {
    const encryptedEmail = encryptEmail(dasEmail);
    if (encryptedEmail) {
      navigate(`/profiles/${encryptedEmail}`);
    } else {
      console.error('Encryption failed');
    }
  }

}

const [pop, setPop] = useState(false);
function emailPopUp() {
setPop(true);
}
function closePopUp() {
setPop(false);
}

// loading triggers for pdf loads
const [pdfLoadingState, setPdfLoadingState] = useState(false);

const handlePdfLoad = () => {
  // Set a short timeout to allow for rendering to complete
  setTimeout(() => {
    setPdfLoadingState(false);
  }, 2000); // Adjust the timeout duration as needed
};

const [maxLength, setMaxLength] = useState(20);


// fetching articles:
useEffect(() => {
  const articlesCollectionRef = collection(db, 'articles');

  // Create a query to filter documents based on the "branch" field
  // const branchQuery = query(profilesCollectionRef, where('branch', '==', g2Affiliation));

  // Set up a snapshot listener for real-time updates
  const unsubscribe = onSnapshot(articlesCollectionRef, (snapshot) => {

    const newData = [];

    snapshot.forEach((doc) => {
      newData.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    // Update the local state with the filtered documents
    setArticleData(newData);
  });

  // Cleanup function to unsubscribe from the snapshot listener
  return () => unsubscribe();
},[]);

// new useEffect looping through and getting collections inside the userEmail docs inside the articles colleciont:
const [visibleObits, setVisibleObits] = useState(3); // State to track the number of visible comments

// fetch article pictures:
useEffect(() => {

  const fetchPics = async () => {
    try {
      setPdfLoadingState(true);
      const articlePicPromises = articleData.map(async (content) => {
        
        try {
          const articleImageRef = ref(storage, `articles/${content.author}/${content.title}`);
          // const pdfRef = ref(storage, `articles/${content.author}/${content.title}/pdf`);
          // const pdfURL = await getDownloadURL(pdfRef);
          const imageRefs = await listAll(articleImageRef);
          const downloadedImage = await Promise.all(
            imageRefs.items.map(async (imageRef, index) => {
              const downloadURL = await getDownloadURL(imageRef);
              return { email: content.author, title:content.title,src: downloadURL}; // Modified structure
            })
          );
          return downloadedImage;
        } catch (error) {
          console.error('Error downloading images:', error);
          setPdfLoadingState(false);
          alert('error loading profiles')
          return null;
        }
      
      return null;
      });

      const articlePics = await Promise.all(articlePicPromises);
      // Flatten the array of arrays into a single array of objects
      const flattenedPics = articlePics.flat().filter(pic => pic !== null);
      setArticlePictures(flattenedPics);
      const timer = setTimeout(() => {
        setPdfLoadingState(false);
      }, 1000); 
      return () => {
        setPdfLoadingState(false);
      };
    } catch (error) {
      console.error('Error fetching profile pictures:', error);
    }
  };
  fetchPics();
  }, [articleData, storage]);

  // fetching obits from firestore and image from storage:
  // const imageRef = ref(storage, `obits/${currentUser.email}/${deceasedName}/image/${file.name}`);
  useEffect(() => {
    const articlesCollectionRef = collection(db, 'obits');
    const unsubscribe = onSnapshot(articlesCollectionRef, (snapshot) => {
      const newData = [];
      snapshot.forEach((doc) => {
        newData.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setObitData(newData);
    });
    return () => unsubscribe();
  },[inMemoriam]);

  useEffect(() => {
    const fetchObitPics = async () => {
      try {
        setPdfLoadingState(true);
        const obitPicPromises = obitData.map(async (content) => {
          try {
            const obitImageRef = ref(storage, `obits/${content.user}/${content.name}/image`);
            const imageRefs = await listAll(obitImageRef);
            const downloadedImage = await Promise.all(
              imageRefs.items.map(async (imageRef, index) => {
                const downloadURL = await getDownloadURL(imageRef);
                return { email: content.user, name:content.name,link:content.link,src: downloadURL }; // Modified structure
              })
            );
            return downloadedImage;
          } catch (error) {
            console.error('Error downloading images:', error);
            setPdfLoadingState(false);
            alert('error loading profiles')
            return null;
          }
        return null;
        });
  
        const obitPics = await Promise.all(obitPicPromises);
        // Flatten the array of arrays into a single array of objects
        const flattenedPics = obitPics.flat().filter(pic => pic !== null);
        setObitPictures(flattenedPics);
        const timer = setTimeout(() => {
          setPdfLoadingState(false);
        }, 1000); 
        return () => {
          setPdfLoadingState(false);
        };
      } catch (error) {
        console.error('Error fetching profile pictures:', error);
      }
    };
    fetchObitPics();
    }, [obitData, storage]);

    function toObitLink(theLink) {
      window.open(theLink, '_blank');
      // alert(theLink);
    }

    // fetching usernames:
    // useEffect(() => {
    //   const fetchUserProfiles = async () => {
    //     try {
    //       const profilesSnapshot = await getDocs(collection(db, 'profiles'));
    //       const userEmails = profilesSnapshot.docs.map(doc => doc.data().email);
    //       const q = query(collection(db, 'profiles'), where('email', 'in', userEmails));
    //       const snapshot = await getDocs(q);
  
    //       const uniqueUserProfiles = [];
  
    //       snapshot.forEach((doc) => {
    //         const username = doc.data().username;
    //         const email = doc.data().email;
  
    //         // Check if the username is unique and not already in userProfiles state
    //         if (!uniqueUserProfiles.some(user => user.username === username)) {
    //           uniqueUserProfiles.push({ email, username });
    //         }
    //       });
  
    //       setTheUsernames(uniqueUserProfiles);
    //     } catch (error) {
    //       console.error('Error fetching user profiles:', error);
    //     }
    //   };
  
    //   fetchUserProfiles();
    // }, [articleData]);
    function chunkArray(array, chunkSize) {
      const chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    }
    
    useEffect(() => {
      const fetchUserProfiles = async () => {
        try {
          const profilesSnapshot = await getDocs(collection(db, 'profiles'));
          const userEmails = profilesSnapshot.docs.map(doc => doc.data().email);
          const chunkedEmails = chunkArray(userEmails, 30); // Split userEmails into chunks of 30 or less
          
          const uniqueUserProfiles = [];
    
          // Fetch user profiles for each chunk of email addresses
          await Promise.all(chunkedEmails.map(async chunk => {
            const q = query(collection(db, 'profiles'), where('email', 'in', chunk));
            const snapshot = await getDocs(q);
    
            snapshot.forEach((doc) => {
              const username = doc.data().username;
              const email = doc.data().email;
    
              // Check if the username is unique and not already in userProfiles state
              if (!uniqueUserProfiles.some(user => user.username === username)) {
                uniqueUserProfiles.push({ email, username });
              }
            });
          }));
          
          setTheUsernames(uniqueUserProfiles);
        } catch (error) {
          console.error('Error fetching user profiles:', error);
        }
      };
    
      fetchUserProfiles();
    }, [articleData]);

    const handleImageLoad = () => {
      setLoaded(true);
    };

  return (
    <>  
  
    {theWidth>750 && !isUp ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Stories</div>
    </div>
    </div>
    :
    <>
    {!isUp &&
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    </>
    }
    <div>
    <div>
    </div>
    </div>
    
    {!isUp && 
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>
    }
    {/* {JSON.stringify(theUsernames)} */}
    {/* {JSON.stringify(articlePictures)} */}
    {/* toggle between "Riley Reporter", "New Articles", "In Memoriam" etc. */}
    <div style={{ display: 'flex', alignItems: 'center', justifyContent:"center"}}>
    {/* <select className="stories-button" defaultValue="default">
    <option value="default" disabled>Riley Reporter</option>
    <option value="1981">1981 Version</option>
    <option value="2001">2001 Version</option>
    </select> */}
    {/* {theWidth<750 && isUp ? <></> :<button disabled={newArticles} onClick={handleNewArticles} className="stories-button">New Articles</button>} */}
    <div style={{marginTop:"10px",marginBottom:"10px"}}><Select  onChange={handleOptionChange} options={options} defaultValue={options[0]} isSearchable={true}/></div>
    {/* {theWidth<650 && isUp ? <></> : <button disabled={inMemoriam} onClick={handleInMemoriam} className="stories-button">In Memoriam</button>} */}
    </div>
    <div className={pop || pdfLoadingState ? "blur-it" : ""}>
    {/* testing article data  */}
    {newArticles &&
    <div>
    <div className="center-stuff">
    <div><button className="stories-button" onClick={toUploadArticle}>Write an Article</button></div>
    </div>
    <div 
    // className="article-list"
    >
    {/* {JSON.stringify(articleData)} */}
    {/* <div className="masonry-grid"> */}
    <div className="center-stuff">
    {articleData.length===0 && <div style={{marginTop:"15px"}}>Nothing here yet - be the first and click the above button!</div>}
    </div>
      {articleData && articleData.map((article, index) => {
         // Find the corresponding articlePicture based on title and email
          const matchingArticlePicture = articlePictures.find(
            (articlePicture) =>
              articlePicture.title === article.title && articlePicture.email === article.author
          );
          const matchingUsernames = theUsernames.find(user => user.email === article.author);
          return (
          <div key={index} 
          // className="article-container"
          // className="masonry-sizer"
          className="profiles-container"
          onClick={()=>toArticle(article.author,article.title)}
          >
          {
          <img loading="lazy" onLoad={handleImageLoad}
          style={{border:"solid black 0px",borderRadius:"0%",objectFit: 'cover', width:"200px",
          filter: `blur(${loaded ? '0px' : '20px'})`,transition: 'filter 1s ease'}} 
          // onClick={()=>toArticle(article.author,article.title)}
          // className="article-image"
          // style={{height:"200px",objectFit:"contain"}}
          src={matchingArticlePicture && matchingArticlePicture ? matchingArticlePicture.src : 'defaultImageSrc'}
          alt={index}/>
          }
          <div 
          className="long-profiles-details"
          // style={{ fontSize: "24px", fontFamily: "Tahoma", overflow: "hidden", textOverflow: "ellipsis",  transition:"all 0.3s ease"}}
          >
          <h3>{article.title}</h3>
          <p>By:&nbsp;{matchingUsernames ? matchingUsernames.username : article.author}</p> 
          <div style={{ maxWidth: "90vw", width: "100%", wordWrap: "break-word",  whiteSpace: "pre-line", overflow:"hidden"}}>
          {
            article.content &&
            article.content.split(/___LINE_BREAK___/).map((paragraph, index) => (
              <p key={index} style={{ textIndent: "0em" }}>
                {paragraph}
              </p>
            ))}
          </div>
          {/* <a onClick={() => toClickedProfile(article.author)} href="#">{matchingUsernames ? matchingUsernames.username : article.author}</a> */}
          </div>
            <div>
            </div>
          </div>
      )})}
      </div>
      <br/>
    </div>
    }
    {reporter01 &&
    <>
      <iframe src={reporter_81} type="application/pdf" width="100%" height="600px" onLoad={handlePdfLoad} />
        {isUp 
        ?
        <>
        <div style={{position:"absolute", top:"2%",right:"5%"}}>
        {!pdfLoadingState && theWidth>450 && <BsArrowDownCircle onClick={toggleDown} style={{cursor:"pointer",fontSize:"28px"}} />}
        </div>
        <div style={{position:"absolute", top:"1.5%",left:"1%"}}>
        {!pdfLoadingState && theWidth>450 && <button onClick={open1981NewTab} className="read-more-button">Open In New Tab</button>}
        </div>
        </>
        :
        <>
        <div style={{position:"absolute", top:"75px",right:"5%"}}>
        {theWidth>450 && <>
          {!pdfLoadingState &&<BsArrowUpCircle onClick={toggleUp} style={{cursor:"pointer",fontSize:"28px"}} />}
        </>}
        </div>
        {theWidth>450 &&
        <div style={{position:"absolute", top:"75px",left:"2%"}}>
        {!pdfLoadingState && <button onClick={open1981NewTab} className="read-more-button">Open In New Tab</button>}
        </div>
        }
        </>
        }  
    </>
    }
    {reporter81 &&
    <>
    <embed src={reporter_01} type="application/pdf" width="100%" height="600px" onLoad={handlePdfLoad} />
    {isUp 
        ?
        <>
        
        <div style={{position:"absolute", top:"2%",right:"5%"}}>
        {!pdfLoadingState && theWidth>450 &&<BsArrowDownCircle onClick={toggleDown} style={{cursor:"pointer",fontSize:"28px"}} />}
        </div>
        <div style={{position:"absolute", top:"1.5%",left:"1%"}}>
        {!pdfLoadingState && theWidth>450 &&<button onClick={open2001NewTab} className="read-more-button">Open In New Tab</button>}
        </div>        
        </>
        :
        <>
        <div style={{position:"absolute", top:"75px",right:"5%"}}>
        {theWidth>300 && 
        <>
        {!pdfLoadingState &&<BsArrowUpCircle onClick={toggleUp} style={{cursor:"pointer",fontSize:"28px"}} />}
        </>
        }
        </div>
        {theWidth>350 &&
        <div style={{position:"absolute", top:"75px",left:"2%"}}>
        {!pdfLoadingState && theWidth>450 &&<button onClick={open2001NewTab} className="read-more-button">Open In New Tab</button>}
        </div>
        }        
        </>
        }  
    </>
    }
    {inMemoriam &&
    <>
    <div>
    {/* {JSON.stringify(obitData)} */}
    {/* <br/><br/> */}
    {/* {JSON.stringify(obitPictures)} */}
    <div><button className="stories-button" onClick={toUploadObit}>Post an Obituary</button></div>
    {/* copy pasta from profiles... */}
    {/* <div style={{marginLeft:"15px"}}>Obituaries:</div> */}
    {obitData.length ===0 && <><div className="center-stuff"><i>No one's posted an obituary yet</i></div></>}
      {obitData.slice(0, visibleObits).map((data, index) => {
        const matchingObitPic = obitPictures.find(pic => pic.email === data.user && pic.name ===data.name);
        const matchUser = theUsernames.find(user => user.email === data.user);
        return(
          <div key={index} className="profiles-container" 
          onClick={() => toObitLink(data.link)}
          >
          {matchingObitPic ? (
            <img  src={matchingObitPic.src} loading="lazy"
              style={{border:"solid black 0px",borderRadius:"0%",objectFit: 'cover',width:"200px", height:"200px",marginTop:"0px"}} 
            />
          ) : (
            <div style={{marginTop:"50px"}}>
            <BsPersonFill style={{fontSize:"124px", width:"200px"}} />
            </div>
          )}

          <div className="long-profiles-details">
            <div>
            <h2>{data.name} 
            {/* <button onClick={() => toClickedProfile(data.email)} className="read-more-button">Go To Profile</button> */}
            </h2>
            </div>
            <p>Uploaded by: {matchUser ? matchUser.username : data.user}</p>
            {/* <p>Branch: {data.link}</p> */}
          </div>
          </div>
      )})}
    {/* copy/pasta done.. */}
    </div>
    </>

    }
    </div>
    {pop &&
     <div style={{position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%)"}}>
      {/* <LoadingSpinner /> */}
      <Card>
      <div className="center-login-prompt" style={{cursor:"default"}}>
      <div style={{marginTop:"10px", color:"maroon",cursor:"pointer", position:"fixed",right:"10%"}} onClick={closePopUp}><BsXCircle style={{fontSize:"22px"}} /></div>
      <div style={{marginRight:"25px",marginLeft:"25px", marginTop:"15px"}}>
      <h4>Please email Jean Grant:</h4>
      </div>
      <div style={{marginRight:"15px",marginLeft:"15px"}}><a href="#">grant.jean.e@gmail.com</a></div>
      <h4 style={{marginRight:"25px", marginLeft:"25px"}}>Subject line: "Reunion Article"</h4>
      </div>
      </Card>
     </div> 
    }
    {pdfLoadingState && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }

    {/* pic aiming for Jean's specs */}
    {/* <div style={{ display: 'flex', alignItems: 'flex-start', height: '100vh' }}>
      <img
        src={reunion81}
        alt="Reunion Image"
        style={{ maxWidth: '80%', height: 'auto', marginRight: '10%',marginTop:"5%" }}
      />
      </div>  */}
    </>
  );
}

export default Stories;