import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Dropdown from '../../dropDown';
import CryptoJS from 'crypto-js';
import Select from 'react-select';
import Compressor from 'compressorjs';
import { useNavigate  } from 'react-router-dom'
import {BsHouseFill, BsPersonVcard,BsFillHouseFill} from "react-icons/bs";
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import '../../somecss.css'
import { sendPasswordResetEmail } from '@firebase/auth';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_riley.jpeg';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_rt.jpeg';
import LoadingSpinner from '../loadingSpinner';
// firebase 
import { getStorage, ref, uploadBytes, listAll } from 'firebase/storage';
import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext';

function Upload() {
  const { currentUser } = useAuth();
    const navigate = useNavigate();
    const storage = getStorage();
    const [files, setFiles] = useState([]);
    const folderPath = 'albums';
    const [albumName, setAlbumName] = useState('');
    const [loading, setLoading] = useState(false);

    function toHome() {
      navigate('/home');
    }
    function toTree() {
      navigate('/family-tree');
    }
    function toProfile() {
      navigate('/profile')
    }
    function toProfiles() {
      navigate('/profiles')
    }
    function toFaqs() {
      navigate('/faqs');
    }
    function toPhotos() {
      navigate('/album-list');
    }
    function toWall() {
      navigate('/community-wall');
    }
    function toAgenda() {
      navigate('/agenda');
    }
    function toTree() {
      navigate('/stories');
    }
    function toLogin() {
      navigate('/login');
  }


  const [albumTag, setAlbumTag] = useState('Family');

  const branchOptions = [
    { value: 'default', label: 'Family' },
    { value: 'historical', label: 'Historical' },
    { value: 'past-events', label: 'Past Events'},
    { value: 'other', label: 'Other' },
  ];
  const handleOptionChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    if (selectedOption && (selectedOption.value === 'default')) {
      setAlbumTag('Family');
      }  if (selectedOption && (selectedOption.value === 'historical')) {
        setAlbumTag('Historical');
      }    if (selectedOption && (selectedOption.value === 'past-events')) {
        setAlbumTag('Past-Events');
      }  if (selectedOption && (selectedOption.value === 'other')) {
        setAlbumTag('Other');
      }      
      else {
        // Do something for other options
        console.log('Option is not any of the listed ones');
        // Perform action Y
      }
  };

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    function backToPhotos() {
        navigate('/album-list');
    }
    function goHome(){
      navigate('/home');
    }
    function toProfile(){
      navigate('/profile');
    }

    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    function toLogin() {
      navigate('/login');
  }
  function toSignUp() {
    navigate('/login');
}
const [buttonDisabled, setButtonDisabled] = useState(true);
const [uploadProgress, setUploadProgress] = useState(0);

// encryption stuff (for pdfs cuz they're visible?)
const theSecret = process.env.REACT_APP_SECRET;

    const encryptEmail = (email) => {
      try {
        const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
        return encodeURIComponent(encryptedEmail);
      } catch (error) {
        console.error('Encryption error:', error);
        return null;
      }
    };

// firebase STORAGE UPLOAD testing logic:
// const handleFileChange = (e) => {
//   const selectedFiles = e.target.files;
//   setFiles(selectedFiles);
//   setButtonDisabled(true); // Disable the button when files are selected
//   processFiles(selectedFiles).then(() => {
//     setButtonDisabled(false); // Enable the button once files are processed
//   });
// };
const compressWithQuality = (file, quality, resolve, reject) => {
  new Compressor(file, {
    quality: quality,
    maxWidth:2000,
    // maxheight:500,
    success(result) {
      console.log('Original file size:', file.size, 'bytes');
      console.log('Compressed file size:', result.size, 'bytes');
      resolve(result);
    },
    error(error) {
      reject(error);
    },
  });
};
const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes

    if (fileSizeMB > 10) {
      // Compress with quality 0.4 for files larger than 5 MB
      compressWithQuality(file, 0.8, resolve, reject);
      console.log('over 10')
    } else if (fileSizeMB > 5) {
      // Compress with quality 0.4 for files larger than 5 MB
      console.log('over 5')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 2) {
      // Compress with quality 0.6 for files between 2 MB and 5 MB
      console.log('over 2')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 1) {
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      console.log('over 1mb')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.8) {
      console.log('over 0.8mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.6) {
      console.log('over 0.6mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.4) {
      console.log('over 0.4mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else {
      console.log('under 0.4mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    }
  });
};
// new many files one: 
// const handleFileChange = (e) => {
//   const newFiles = e.target.files;
//   setFiles((prevFiles) => [...prevFiles, ...newFiles]);
//   setButtonDisabled(true); // Disable the button when files are selected
//   processFiles([...files, ...newFiles]).then(() => {
//     setButtonDisabled(false); // Enable the button once files are processed
//   });
// };

// compressing logiv handleFileChange:
// const handleFileChange = async (e) => {
//   const newFiles = Array.from(e.target.files);
//   setButtonDisabled(true); // Disable the button when files are selected

//   try {
//     const compressedFiles = await Promise.all(
//       newFiles.map(async (file) => await compressImage(file))
//     );

//     // Process the compressed files (if needed)
//     await processFiles(compressedFiles);

//     // Update files with the compressed files after processing
//     setFiles((prevFiles) => [...prevFiles, ...compressedFiles]);
//   } catch (error) {
//     console.error('Error compressing images:', error);
//   } finally {
//     setButtonDisabled(false); // Enable the button once files are processed
//   }
// };
// dfgdfgdfgdf
const handleFileChange = async (e) => {
  const newFiles = Array.from(e.target.files);
  setButtonDisabled(true); // Disable the button when files are selected

  try {
    const compressedFiles = await Promise.all(
      newFiles.map(async (file) => {
        if (file.type.startsWith('image/')) {
          // Check if the file is an image
          return await compressImage(file);
        } else {
          console.warn('Skipping non-image file:', file.name);
          return file; // If not an image, return the original file
        }
      })
    );

    // Process the compressed files (if needed)
    await processFiles(compressedFiles);

    // Update files with the compressed files after processing
    setFiles((prevFiles) => [...prevFiles, ...compressedFiles]);
  } catch (error) {
    console.error('Error compressing images:', error);
  } finally {
    setButtonDisabled(false); // Enable the button once files are processed
  }
};




// dgdgfgdfgdfgdfgf
// const checkFolderExists = async (albumName, callback) => {
//   try {
//     const folderPathToCheck = `${folderPath}/${currentUser.email}/${albumName}`;
//     const parentDirectoryRef = ref(storage, folderPath);
//     const parentDirectoryItems = await listAll(parentDirectoryRef);
//     const folderExists = parentDirectoryItems.items.some(item => item.name === albumName);
//     if (folderExists) {
//       alert('Folder already exists');
//       callback(false);
//     } else {
//       callback(true);
//     }
//   } catch (error) {
//     console.error('Error checking folder:', error);
//     callback(false);
//   }
// };
// dfgdfgdfgdfgd
const handleUpload = async () => {
  if (currentUser) {
    const encryptedEmail = encryptEmail(currentUser.email);
    if (files.length > 0) {
      if (albumName.trim() === '') {
        alert('Please specify an album name.');
        return;
      }
      if (albumName.includes('/')) {
        alert(`Please don't use "/" in your album name...I won't let you`);
        return;
      }
      // checkFolderExists(albumName, (folderExists) => {
        // if (folderExists) {
          // Wrap the upload code in a try-catch block to handle any upload errors
          try {
            const doesAlbumExist = ref(storage, `${folderPath}/${albumTag}/${encryptedEmail}/${albumName}`);
            const albumExist = await listAll(doesAlbumExist);
            if(albumExist.items.length > 0){
              alert('You already made an album with that name!')
              // alert(albumName);
            } else{
              // alert('nope')
              // alert(albumName);
              setLoading(true);
                const uploadPromises = Array.from(files).map((file) => {
                  const storageRef = ref(storage, `${folderPath}/${albumTag}/${encryptedEmail}/${albumName}/${file.name}`);             
                  return uploadBytes(storageRef, file);
                });
                Promise.all(uploadPromises)
                  .then(() => {
                    console.log('Files uploaded successfully!');
                    backToPhotos();
                    setLoading(false);
                  })
                  .catch((error) => {
                    console.error('Error uploading files:', error);
                  });
            }
          } catch (error) {
            console.error('Error uploading files:', error);
            alert('Error uploading files:', error);
          }
        // } else {
        //   alert('Folder exists or an error occurred');
        // }
      // });
    } else {
      alert('Please select one or more files to upload.');
    }
  } else {
    alert('Login first!');
  }
};
// dfgdfgdfg
const handleAlbumName = (e) => {
  setAlbumName(e.target.value);
};

const testing = async () => {
  const data = new TextEncoder().encode('testing');
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashHex = Array.from(new Uint8Array(hashBuffer))
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');
  alert(hashHex);
};

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

const processFiles = (files) => {
  return new Promise((resolve) => {
    // Simulating file processing with a timeout
    const totalFiles = files.length;
    let filesProcessed = 0;

    const processingInterval = setInterval(() => {
      if (filesProcessed === totalFiles) {
        clearInterval(processingInterval);
        resolve(); // Resolve the promise when processing is done
      } else {
        filesProcessed++;
        const progress = Math.floor((filesProcessed / totalFiles) * 100);
        setUploadProgress(progress);
      }
    }, 500); // Simulated processing time, adjust as needed
  });
};


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 250, // You can adjust this value as needed
  }),
};
  return (
    <>
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    {/* <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/> */}
    <div onClick={toProfiles} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    {/* <div style={{position: "relative",marginBottom: "25px"}}> */}
    <div className="the-titles-hover">Profiles</div>
    {/* </div> */}
    </div>
    {/* <BsPersonVcard onClick={toProfile} style={{backgroundColor:"transparent", cursor:"pointer",position:"absolute",left:"15%",top:"4.5%", fontSize:"20px"}} /> */}
    <div onClick={toWall} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    {/* <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} /> */}
    <div onClick={toPhotos} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Photos</div>
    </div>
    {/* <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} /> */}
    <div onClick={toAgenda} style={{position:"absolute",right:"13.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    {/* <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  /> */}
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    {/* <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} /> */}
    </div>
    :
    <>  
      <div onClick={goHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
      <Dropdown handleBoolChange={handleBoolChange} />
    </>
}
<div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>   
    <div className={loading ? "blur-it" : ""}>
    {/* <BsHouseFill onClick={goHome} style={{cursor:"pointer",position:"absolute",right:"75px"}} /> */}
    {/* <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
    {/* <img src={image} /> */}
    {/* <div className="title">
    <h1 className="the-titles">Upload Content</h1>
    </div>     */}

    <div style={{
          // backgroundImage: `url(${newpic})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
             }}>
    <div style={{position:"absolute",left:"2%", top:"100px", cursor:"pointer"}}>
    {!loading && 
      <button className="read-more-button" onClick={backToPhotos}>Back to Photos</button>
    }
    </div>
    <div className="center-stuff">
    {!currentUser
    ?
    <h3>You must login to upload</h3>
    :
    
    <div>
    {/* {!isLoggedIn ?
    <>
    <h4 className="center-stuff-agenda">You need to be logged in to upload photos</h4>
    <>
    <div className="center-stuff-agenda">Log in here:</div>
    <div className="center-stuff-agenda"><button className="buttonStyle" onClick={toLogin}>Login</button></div>
    <div className="center-stuff-agenda">Sign up here:</div>
    <div className="center-stuff-agenda"><button className="buttonStyle" onClick={toSignUp}>Sign Up</button></div>
    </>
    <div className="center-stuff-agenda"><img src={cs}/></div>
    </>

    :

    <div>dfgdfg</div>
    } */}
    {/* testing firebase storage upload logic here: */}
    
      {/* sdfgdfgdfgdfgd */}
    <div>
      <div style={{ display: 'flex', flexDirection: 'column',marginTop:"25px"}}>
      <div className="center-stuff"><div style={{margin:"10px"}} >Upload Photos/Videos/PDFs</div></div>
      {/* <label>
      <div>
      <input
          type="file"
          onChange={handleFileChange}
          multiple
          style={{margin:"10px"}}
        />
      </div>
      </label> */}
      {/* new styles: */}
      <div className="center-stuff">
      <label htmlFor="fileInput" className="stories-button">
      Choose Files
          <input
              id="fileInput"
              type="file"
              onChange={handleFileChange}
              multiple
              style={{ display: 'none' }}
          />
      </label>
      </div>
      <div className="selected-files">
      {files.length > 0 && (
        <>
        <div className="center-stuff">Selected files:{' '}{`(${files.length} total)`}</div>
        <div className="center-stuff">
        <p style={{margin:"10px"}}>
          {Array.from(files)
            .slice(0, 3)
            .map((file, index) => file.name)
            .join(', ')}
          {files.length > 3 && ` ... (the remaining ${files.length-3} etc.)`}
        </p>
        </div>
        </>
      )}
    </div>
      {/* <input style={{margin:"10px"}} type="file" onChange={handleFileChange} multiple /> */}

      <div className="center-stuff">
      <div style={{margin:"10px"}}>Album Name:</div>
      </div>
      <div className="center-stuff">
      <input maxLength={45} style={{margin:"10px",width:"275px"}} type="text" placeholder="Name your album..." value={albumName} onChange={handleAlbumName} />
      </div>
      <div className="center-stuff">
      <div style={{margin:"10px"}}>Choose a Tag:</div>
      </div>
      <div className="center-stuff">
      <Select  styles={customStyles} onChange={handleOptionChange} options={branchOptions} defaultValue={branchOptions[0]} isSearchable={true}/>
      </div>
      <div className="center-stuff">
      <div style={{margin:"10px",cursor:"pointer"}}>
      <button className="upload-button" disabled={buttonDisabled} onClick={handleUpload}>Upload</button>
      </div>
      </div>

      <div className="center-stuff">
        <div style={{ width: '50%', marginTop: '5px', borderRadius:"25px" }}>
        <progress value={uploadProgress} max={100} style={{ width: '100%', height: '20px' }} />
        </div>
      </div>
      </div>
    </div>
    {/* dfgdfgdfgfd */}
    <div className="center-stuff">
    {/* CS Riley + Speech Bubble */}
        <div className="speech-bubble-r">
          <p style={{margin:"5px"}}><i>We accept images, videos or pdfs! Annotate your photos/videos after you upload them if you wish.</i></p>
        </div>
        {/* <div className="triangle"></div> */}
      </div>
      {/* dfgdfgdfgdfg */}
    <div className="center-stuff-agenda"><img style={{width:"75%",marginTop:"0px"}} src={rt}/></div>
    {/* testing storage upload done */}
    </div>
    }
    </div>
    {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
    </div>
    </div>
    {loading && 
    <>
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    </>
    }
    </>
  );
}

export default Upload;