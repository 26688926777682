import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../../dropDown';
import ImageCarousel from './images';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import garcia from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/garcia_74.jpeg';
import dimon from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/jamie_dimon.jpeg';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs.png';
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import paint from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-9.jpeg';
import {BsUpload,BsArrow90DegUp,BsHouseFill, BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3,BsFillHouseFill, BsBook, BsVectorPen} from "react-icons/bs";
import { useNavigate  } from 'react-router-dom';
import { listAll, getStorage, ref, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, getDoc, doc, getFirestore, serverTimestamp } from "firebase/firestore";

import '../../somecss.css';



function AlbumExample() {
  const db = getFirestore();
  const { currentUser } = useAuth();
  const storage = getStorage();
  const navigate = useNavigate();
  const { encryptedEmail, albumTag, albumName } = useParams();
  const truncatedAlbumName = albumName.substring(0, 15); // Extract first 5 characters
  // make dynamic truncation ^^^^ (toss a variable ^^^^ there) ??? 
  const [images, setImages] = useState([]);
  const [carouselState, setCarouselState] = useState(true);

  const handleCarouselStateChange = (newState) => {
    // Update the parent component's state based on the data received from the child component
    setCarouselState(newState);
  };
  const theSecret = process.env.REACT_APP_SECRET;

  const decryptEmail = (encryptedEmail) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedEmail), theSecret);
      const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedEmail;
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  };
  
  const userEmail = decryptEmail(encryptedEmail);
  const double = decryptEmail(userEmail);

  const redoemail = (email) => {
    try {
      const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
      return encodeURIComponent(encryptedEmail);
    } catch (error) {
      console.error('Encryption error:', error);
      return null;
    }
  };

  // const redone = redoemail(userEmail);

  // console.log(userEmail);
// useEffect(() => {
//   console.log(hashedEmail);
// },[hashedEmail]);

  // const [images, setImages] = useState([
  //   {
  //     id: 1,
  //     src:garcia,
  //     alt: "garcia"
  //   },
  //   {
  //     id: 2,
  //     src:dimon,
  //     alt: "dimon"
  //   },
  //   {
  //     id: 3,
  //     src:cs,
  //     alt: "c.s. riley"
  //   }
  // ]);

  const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

   // Regular Shortcuts:
   function toHome() {
    navigate('/home');
  }
  function toTree() {
    navigate('/family-tree');
  }
  function toProfile() {
    navigate('/profile')
  }
  function toHistory() {
    navigate('/history');
  }
  function toPhotos() {
    navigate('/album-list');
  }
  function toWall() {
    navigate('/community-wall');
  }
  function toAgenda() {
    navigate('/agenda');
  }
  function toTree() {
    navigate('/family-tree');
  }

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);

  const encryptEmail = (email) => {
    try {
      const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
      return encodeURIComponent(encryptedEmail);
    } catch (error) {
      console.error('Encryption error:', error);
      return null;
    }
  };


    function toClickedProfile() {
      if(currentUser && currentUser.email===double) {
        navigate('/profile');
        return;
      } else {
        const encryptedEmail = encryptEmail(double);
        if (encryptedEmail) {
          navigate(`/profiles/${encryptedEmail}`);
        } else {
          console.error('Encryption failed');
        }
      }
    }
  

  // get the right album's images:
  useEffect(() => {
    const downloadImagesForAlbum = async () => {
      const theEmail = encryptEmail(userEmail);
      try {
        if(userEmail) {
        // Construct the path to the album's images in Firebase Storage
        const albumImagesRef = ref(storage, `albums/${albumTag}/${userEmail}/${albumName}`);

        // List all images in the album
        const imageRefs = await listAll(albumImagesRef);

        const downloadedImages = await Promise.all(
          imageRefs.items.map(async (imageRef, index) => {
            const downloadURL = await getDownloadURL(imageRef);
            return {id:index, src: downloadURL, alt: imageRef.name };
          })
        );

        setImages(downloadedImages);
      }} catch (error) {
        console.error('Error downloading images:', error);
      }
    };

    downloadImagesForAlbum();
  }, []);

  const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

function annotate() {
  alert('dfg');
} 

const titleStyle = {
  width: '80%', // or any width you prefer
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontFamily: 'Helvetica Neue',
};

// co
const maxCharactersDesktop = 50; // Set your maximum characters for larger screens
const maxCharactersMobile = 20; // Set your maximum characters for smaller screens
const maxCharacters = theWidth > 600 ? maxCharactersDesktop : maxCharactersMobile;
const truncatedAlbumNameAgain = albumName.length > 50 ? albumName.substring(0, maxCharacters) + '...' : albumName;

// fetching usernames for uploaded folks:
const [theUsername, setTheUsername] = useState('');
useEffect(() => {
  const fetchUserProfile = async () => {
    try {
      if (double) {
        const userDocRef = doc(db, 'profiles', double);
        const userDocument = await getDoc(userDocRef);

        if (userDocument.exists()) {
          // Document found, extract and set the username to the state
          const username = userDocument.data().username;
          setTheUsername(username);
        } else {
          // Document not found, handle accordingly
          console.log('User profile not found');
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  fetchUserProfile();
}, [db, double]);
  return (
    <>
    {theWidth>750 ?
    <div>
    {/* <div onClick={toHome} style={{position:"absolute", left:"5%", top:"3%",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div> */}
    <Dropdown handleBoolChange={handleBoolChange} />
    {/* <div style={{position:"absolute",left:"4%", top:"1.5%",}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div style={{position:"absolute",left:"14%", top:"1.5%",}}>Profile</div>
    <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",left:"15%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"4.5%", top:"1.5%",}}>Tree</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"13.5%", top:"1.5%",}}>Agenda</div>
    <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"23.5%", top:"1.5%",}}>History</div>
    <BsBook onClick={toHistory} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} /> */}
    </div>
    :
    <>
    {/* <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div> */}
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    {/* <BsHouseFill onClick={goHome} style={{cursor:"pointer",position:"absolute",right:"75px"}} /> */}
    {/* <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
    <div className="title">
    {theWidth > 0 ?
    <>
      <div>
        <div
        style={{fontFamily:"Tahoma",fontSize:"24px",marginTop:"15px",maxWidth: "50vw",
        // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    textAlign: "center",
    // margin: "auto",
    }}
        >
        {albumName}</div>
        </div>
    </>
    :
    <>
      <h1 style={{fontFamily:"Helvetica Neue"}}>{truncatedAlbumNameAgain} ...</h1>
    </>
    }
    </div>
    <div style={{
          // backgroundImage: `url(${paint})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // make height go away when full-screen is click in "images"
        // height:carouselState===true ? theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300 : "",
             }}>
    {/* <div className="center-stuff">
     stuff 
    </div> */}
    <div>
    <ImageCarousel images={images} onCarouselStateChange={handleCarouselStateChange} />
    <br/>
    <>
      {theUsername ?
      <div className="center-stuff">
      Uploaded by:&nbsp;<a href="#" onClick={toClickedProfile}>{theUsername}</a>
      </div>
      :
      <div className="center-stuff">
      Uploaded by a user that has not finished their profile
      </div>
      }
      <br/><br/>
    </>
    <div></div>
    {/* <div>{JSON.stringify(images)}</div> */}
    </div>
    </div>
    </>
  );
}

export default AlbumExample;