import React, { useEffect, useRef, useState } from 'react';
import ReactFlow, { Controls, Background, useStore, 
  ReactFlowProvider, fitView, useReactFlow, reactFlowInstance, useNodesState } from 'reactflow';
import 'reactflow/dist/style.css';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import NodesList, {theNodes, someNodes} from './nodes.js';
import EdgesList, {theEdges, someEdges} from './edges.js';
import Dropdown from '../../dropDown';
import {BsCaretDownFill, BsCaretUpFill, BsHouseFill, BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill} from "react-icons/bs";

import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { useNavigate  } from 'react-router-dom'

// buttons
import TreeButton from '../../tree-buttons/treeButton';
import G2HJR from '../../tree-buttons/g2HJR&RMM';
import G2JHR from '../../tree-buttons/g2JHR&ICS';
import G2MR from '../../tree-buttons/g2MR&MAM';
import G2LBR from '../../tree-buttons/g2LBR&REGP';
import G2CSR from '../../tree-buttons/g2CSR&JIC';
import G2RSR from '../../tree-buttons/G2RSR&KEH';

// people/nodes:
// G1
import HMurgatroyd from '../../people/g1/HMurgatroyd';
import RTRiley from '../../people/g1/RTRiley';
// G2
import CSRiley from '../../people/g2/CSRiley';
import JICulver from '../../people/g2/JICulver';
import TBRiley from '../../people/g2/TBRiley';
import WBRiley from '../../people/g2/WBRiley';
import LBRiley from '../../people/g2/LBRiley';
import REGPerry from '../../people/g2/REGPerry';
import GRRiley from '../../people/g2/GRRiley';
import HMRiley from '../../people/g2/HMRiley';
import MAMacQueen from '../../people/g2/MAMacQueen';
import RSRiley from '../../people/g2/RSRiley';
import KEHiggins from '../../people/g2/KEHiggins';
import JHRiley from '../../people/g2/JHRiley';
import ICScott from '../../people/g2/ICScott';
import HJRiley from '../../people/g2/HJRiley';
import RMMoore from '../../people/g2/RMMoore';
// G3 
  // Robert Sanford's side:
  import rsjr from '../../people/g3/rs_riley/rsJunior';
  import KSRiley from '../../people/g3/rs_riley/KSRiley';
  import ChapinRiley from '../../people/g3/rs_riley/ChapinRiley';
  import CMRiley from '../../people/g3/rs_riley/CMRiley';
  import RLRiley from '../../people/g3/rs_riley/RLRiley';
  // CS Riley:
  import WCRiley from '../../people/g3/cs_riley/WCRiley';
  import RonTRiley from '../../people/g3/cs_riley/RonTRiley';
  import JERiley from '../../people/g3/cs_riley/JERiley';
  import ConSanfRiley from '../../people/g3/cs_riley/ConSanfRiley';
  import GAFRiley from '../../people/g3/cs_riley/GAFRiley';
  import NARiley from '../../people/g3/cs_riley/NARiley';
  import JDRiley from '../../people/g3/cs_riley/JDRiley';
  import RobSanf from '../../people/g3/cs_riley/RobSanf';
  // Lavinia Bell Riley
  import EAPerry from '../../people/g3/lb_riley/EAPerry';
  import RRPerry from '../../people/g3/lb_riley/RRPerry';
  import HMPerry from '../../people/g3/lb_riley/HMPerry';
  import JGPerry from '../../people/g3/lb_riley/JGPerry';
  // John Herbert Riley's Side:
  import HWRiley from '../../people/g3/jh_riley/HWRiley';
  import ECRiley from '../../people/g3/jh_riley/ECRiley';
  // Harold James side:
  import HaroldJ from '../../people/g3/hj_riley/HaroldJ';
  import RMRiley from '../../people/g3/hj_riley/RMRiley';
  import TMRiley from '../../people/g3/hj_riley/TMRiley';
  import JMRiley from '../../people/g3/hj_riley/JMRiley';

// G 4 !!!!!!!
  // Rob Sanford (1906) side:
  import RSRiley3 from '../../people/g4/rs_riley/rsriley3';
  import MPRiley from '../../people/g4/rs_riley/MPRiley';
  import WDRiley from '../../people/g4/rs_riley/WDRiley';
  import DPRiley from '../../people/g4/rs_riley/DPRiley';
  // Katharine S. Riley's side:
  import SSBurrage from '../../people/g4/ks_riley/SSBurrage';
  import AABurrage from '../../people/g4/ks_riley/AABurrage';
  import BBurrage from '../../people/g4/ks_riley/BBurrage';
  import WSBurrage from '../../people/g4/ks_riley/WSBurrage';
  import LBBurrage from '../../people/g4/ks_riley/LBBurrage';
  import NRBurrage from '../../people/g4/ks_riley/NRBurrage';
  import GBurrage from '../../people/g4/ks_riley/GBurrage';
  // Chapin Riley Side:
  import CynthiaRiley from '../../people/g4/chapin_riley/CynthiaRiley';
  import SCRiley from '../../people/g4/chapin_riley/SCRiley';
  import HARiley from '../../people/g4/chapin_riley/HARiley';
  // Dr. Conrad Milton Riley's side:
  import KCRiley from '../../people/g4/dr_cm_riley/KCRiley';
  import PLRiley from '../../people/g4/dr_cm_riley/PLRiley';
  import ChrisMRiley from '../../people/g4/dr_cm_riley/CMRiley';
  import RSBennett from '../../people/g4/rl_riley/RSBennett';
  import RCBennett from '../../people/g4/rl_riley/RCBennett';
  import BSBennett from '../../people/g4/rl_riley/BSBennett';
  import PSBennett from '../../people/g4/rl_riley/PSBennett';
  import RLBennett from '../../people/g4/rl_riley/RLBennett';
  // William Culver Side:
  import WCulverRiley from '../../people/g4/wc_riley/WCulverRiley';
  import EHRiley from '../../people/g4/wc_riley/EHRiley';
  import CHRiley from '../../people/g4/wc_riley/CHRiley';
  // Ronald Thomas Side:
  import rtjr from '../../people/g4/RonTRiley/rtjr';
  import JerMRiley from '../../people/g4/RonTRiley/JMRiley';
  import PARiley from '../../people/g4/RonTRiley/PARiley';
  import GMBlack from '../../people/g4/JERiley/GMBlack';
  import CMBlack from '../../people/g4/JERiley/CMBlack.js';
// Conrad Sanford Side:
import con_s_riley from '../../people/g4/consanf_riley/con_s_riley';
import DARiley from '../../people/g4/consanf_riley/DARiley';
// George Albert side:
import ALRiley from '../../people/g4/GAFRiley/ALRiley';
// Nancy Riley side:
import shosler from '../../people/g4/NARiley/sh_osler';
import slosler from '../../people/g4/NARiley/sl_osler';
import ggosler from '../../people/g4/NARiley/GGOsler';
        // John Derek Riley Side
  import hjeanriley from '../../people/g4/jd_riley/HJRiley';
  import DDRiley from '../../people/g4/jd_riley/DDRiley';
  import DDerekRiley from '../../people/g4/jd_riley/DDerekRiley';
  import LARiley from '../../people/g4/jd_riley/LARiley';
  // Rob Sanford Riley side:
  import HughSRiley from '../../people/g4/rsanf_riley/HughSRiley';
  import GPSRiley from '../../people/g4/rsanf_riley/GPSRiley';
  import NMRiley from '../../people/g4/rsanf_riley/NMRiley';
  // Edmund Alexander Perry side:
  import RCPerry from '../../people/g4/ea_perry/RCPerry';
  import DEPerry from '../../people/g4/ea_perry/DEPerry';
  // Harriet Maud Perry
  import JMLederman from '../../people/g4/hm_perry/JMLederman';
  import RGLederman from '../../people/g4/hm_perry/RGLederman';
  import KJLederman from '../../people/g4/hm_perry/KJLederman';
  // Harold William Riley side:
  import MichaelCRiley from '../../people/g4/hw_riley/MichaelCRiley';
  import SPRiley from '../../people/g4/hw_riley/SPRiley';
    // Eleanor Collum Riley Side
    import AEPalmour from '../../people/g4/ec_riley/AEPalmour';
    import KRPalmour from '../../people/g4/ec_riley/KRPalmour';
    // Harold James side:
    import JanetMRiley from '../../people/g4/harold_j_riley/JanetMRiley';
    import RuthMRiley from '../../people/g4/harold_j_riley/RuthMRiley';
    // Ruth Murgatroyed / Sally / Dowler side
    import JMDowler from '../../people/g4/rm_sally_dowler/JMDowler';
    import JADowler from '../../people/g4/rm_sally_dowler/JADowler';
    import SRDowler from '../../people/g4/rm_sally_dowler/SRDowler';
    // John Moore Riley side
    import JCMRIley from '../../people/g4/jm_riley/JCMRIley';
    import NMcKivorRiley from '../../people/g4/jm_riley/NMcKivorRiley';
    import TCRiley from '../../people/g4/jm_riley/TCRiley';

    // G 5 !!!!!!!!!!!!!!!!!!!!!!!
      // Rob Sanford III side:
    import tk_riley from '../../people/g5/rsr_3/tk_riley';
    import rene_t_riley from '../../people/g5/rsr_3/rene_t_riley';
    import cp_riley from '../../people/g5/rsr_3/cp_riley';
      // margaret putnam riley side:
    import bc_parker from '../../people/g5/mp_riley/bc_parker';
    import cg_parker from '../../people/g5/mp_riley/cg_parker';
      // Dr William Dixon Riley side:
      import heatherRiley from '../../people/g5/dr_wdr/heather_riley';
      import kimRiley from '../../people/g5/dr_wdr/kim_riley';
      import kprlazo from '../../people/g5/dr_wdr/kprlazo';
      import peterRiley from '../../people/g5/dr_wdr/peter_riley';
      // David Price Riley
      import jw_riley from '../../people/g5/David_PRiley/jw_riley';
      import seth from '../../people/g5/David_PRiley/seth_riley';
      // Sally Sanford Burrage side
      import sa_ney from '../../people/g5/ss_burrage/sa_ney';
      import pa_ney from '../../people/g5/ss_burrage/pa_ney';
      import eb_ney from '../../people/g5/ss_burrage/eb_ney';
      // Side: Anita Alden Burrage
        import j_bers from '../../people/g5/aa_burrage/j_bers';
        import r_bers from '../../people/g5/aa_burrage/r_bers';
      // Side: Walter Swan Burrage
      import aa_burrage from '../../people/g5/ws_burrage/aa_burrage';
      import ks_burrage from '../../people/g5/ws_burrage/ks_burrage';
      import ab_burrage from '../../people/g5/ws_burrage/ab_burrage';
      import cl_burrage from '../../people/g5/ws_burrage/cl_burrage';
      import sd_burrage from '../../people/g5/ws_burrage/sd_burrage';
      // Side: Nicholas Rand Burrage
      import ms_burrage from '../../people/g5/nr_burrage/ms_burrage';
      import jc_burrage from '../../people/g5/nr_burrage/jc_burrage';
      // Side: Gabriella Burrage
      import mharoutunian from '../../people/g5/g_burrage/m_haroutunian';
      import mischa from '../../people/g5/g_burrage/mischa_h';
      // Cynthia Riley
      import khfehsenfeld from '../../people/g5/cynthia_riley/kh_fehsenfeld';
      import cafehsenfeld from '../../people/g5/cynthia_riley/ca_fehsenfeld';
      import dtfehsenfeld from '../../people/g5/cynthia_riley/dt_fehsenfeld';
      // Side: Kitty Chapin Clark
      import pclark from '../../people/g5/kc_clark/p_clark';
      import dclark from '../../people/g5/kc_clark/d_clark';
      // Side: Patricia Lyman Riley
      import etlenthall from '../../people/g5/pl_riley/et_lenthall';
      import bklenthall from '../../people/g5/pl_riley/bk_lenthall';
      import jplenthall from '../../people/g5/pl_riley/jp_lenthall';
      // Janet J. Riley side:
      import ra_aardal from '../../people/g5/jj_riley/ra_aardal';
      import rr_aardal from '../../people/g5/jj_riley/rr_aardal';
      // Side: Robert Sanford Bennett
      import a_meany from '../../people/g5/rs_bennett/a_meany';
      import sr_bennett from '../../people/g5/rs_bennett/sr_bennett';
      // Side: Rebecca Chapin Bennett
      import ks_wallace from '../../people/g5/rc_bennett/ks_wallace';
      import cj_wallace from '../../people/g5/rc_bennett/cj_wallace';
      // Side: Bruce Shaw Bennett
      import jm_bennett from '../../people/g5/bs_bennett/jm_bennett';
      import ff_bennett from '../../people/g5/bs_bennett/ff_bennett';
      import la_bennett from '../../people/g5/bs_bennett/la_bennett';
      // Side: Peter Smith Bennett
      import erb_bennett from '../../people/g5/ps_bennett/erb_bennett';
      import pa_bennett from '../../people/g5/ps_bennett/pa_bennett';
      import cp_bennett from '../../people/g5/ps_bennett/cp_bennett';
      // Side: William Culver Riley
      import ch_riley from '../../people/g5/wc_riley/ch_riley';
      import sh_riley from '../../people/g5/wc_riley/sh_riley';
      import mh_riley from '../../people/g5/wc_riley/mh_riley';
      // Side: Ronald THomas Riley
      import rt_riley from '../../people/g5/rt_riley/rt_riley';
      import gm_riley from '../../people/g5/rt_riley/gm_riley';
      import mg_riley from '../../people/g5/rt_riley/mg_riley';
      // Side: Jeremy Montegu Riley
      import ts_riley from '../../people/g5/jm_riley/ts_riley';
      import lk_riley from '../../people/g5/jm_riley/lk_riley';
      import pa_riley from '../../people/g5/jm_riley/pa_riley';
      // Side: George Montegu Black III (1940-)
      import tj_black from '../../people/g5/gm_black/tj_black';
      import ac_black from '../../people/g5/gm_black/ac_black';
      import ms_black from '../../people/g5/gm_black/ms_black';
      import gm_black4 from '../../people/g5/gm_black/gm_black4';
      // Side: Conrad Moffat Black (1944-)
      import jdc_black from '../../people/g5/cm_black/jdc_black';
      import awe_black from '../../people/g5/cm_black/awe_black';
      import jpl_black from '../../people/g5/cm_black/jpl_black';
      // Side: Conrad Sanford Riley
      import je_riley from '../../people/g5/cs_riley/je_riley';
      import cs_riley5 from '../../people/g5/cs_riley/cs_riley';
      // Side: Dennis Albert Riley (1949-)
      import dr_marshall from '../../people/g5/da_riley/dr_marshall';
      // Side: Ailsa Lynn Riley 
      import pgh_gisle from '../../people/g5/al_riley/pgh_gisle';
      import ce_van_tienhoven from '../../people/g5/al_riley/ce_van_tienhoven';
      import cag_van_tienhoven from '../../people/g5/al_riley/cag_van_tienhoven';
      // Side: Sanford Lake Osler
      import mf_osler from '../../people/g5/sl_osler/mf_osler';
      import lm_osler from '../../people/g5/sl_osler/lm_osler';
      // Side: Susan Harty Osler (1951-)
      import gc_matthews from '../../people/g5/sh_osler/gc_matthews';
      import sh_matthews from '../../people/g5/sh_osler/sh_matthews';
      import tj_matthews from '../../people/g5/sh_osler/tj_matthews';
      // Side: Gillian Gordon Osler (1959-)
      import mko_fortier from '../../people/g5/gg_osler/mko_fortier';
      import ncr_fortier from '../../people/g5/gg_osler/ncr_fortier';
      // Side: Helena Jean Riley (1949-)
      import riley from '../../people/g5/hj_riley/riley';
      import derek from '../../people/g5/hj_riley/derek';
      import lauren from '../../people/g5/hj_riley/lauren';
      // Side: David Derek Riley (1951-)
      import andrew_riley from '../../people/g5/dd_riley/andrew_riley';
      import kate_riley from '../../people/g5/dd_riley/kate_riley';
      // Mom's side:
      import jean from '../../people/g5/la_riley/bean.js';
      import tom from '../../people/g5/la_riley/tom.js';
      import me from '../../people/g5/la_riley/me.js';
      import chris from '../../people/g5/la_riley/chris.js';
      // Sandy and Debbie's side
      import jess_m_riley from '../../people/g5/hs_riley/jess_m_riley';
      import meg_m_riley from '../../people/g5/hs_riley/meg_m_riley';
      import jr_sanford_riley from '../../people/g5/hs_riley/jr_sanford_riley';
      // Side: George Patrick Stephenson Riley
      import dj_riley from '../../people/g5/gps_riley/dj_riley';
      import hea_riley from '../../people/g5/gps_riley/hea_riley';
      import catherine_p_riley from '../../people/g5/gps_riley/catherine_p_riley';

      // Side: Nancy Meribeth Riley
      import bsr_murray from '../../people/g5/nm_riley/bsr_murray';
      import chr_murray from '../../people/g5/nm_riley/chr_murray';
      import cmr_murray from '../../people/g5/nm_riley/cmr_murray';
// Side: Robert Christie Perry
import ca_perry from '../../people/g5/rc_perry/ca_perry';
import re_perry from '../../people/g5/rc_perry/re_perry';
import kj_perry from '../../people/g5/rc_perry/kj_perry';
// Side: Donald Edmund Perry
import jh_perry from '../../people/g5/de_perry/jh_perry';
import ad_perry from '../../people/g5/de_perry/ad_perry';
import mja_perry from '../../people/g5/de_perry/mja_perry';
import he_perry from '../../people/g5/de_perry/he_perry';

// Side: Robert Guthrie Lederman
import rgm_lederman from '../../people/g5/rg_lederman/rgm_lederman';
import jp_lederman from '../../people/g5/rg_lederman/jp_lederman';
   // Side: Susan Patton Riley
  import cj_hoeschen from '../../people/g5/sp_riley/cj_hoeschen';
  import sj_hoeschen from '../../people/g5/sp_riley/sj_hoeschen';
      // Side: Ann Elizabeth Palmour
      import jdj_palmour from '../../people/g5/ae_palmour/jdj_palmour';
      import jba_palmour from '../../people/g5/ae_palmour/jba_palmour';
  // Side: Katharine Riley Palmour
  import php_daffurn from '../../people/g5/kr_palmour/php_daffurn';
  import tr_standrew_daffurn from '../../people/g5/kr_palmour/tr_standrew_daffurn';
  // Side: Janet Margaret Riley
  import jr_bourbon from '../../people/g5/janet_m_riley/jr_bourbon';
  import jf_bourbon from '../../people/g5/janet_m_riley/jf_bourbon';
     // Side: Ruth Moore Riley 
     import rj_jackson from '../../people/g5/rm_riley/rj_jackson';
     import vm_jackson from '../../people/g5/rm_riley/vm_jackson';
 // Side: James Andrew Dowler
 import pm_dowler from '../../people/g5/ja_dowler/pm_dowler';
 import tr_dowler from '../../people/g5/ja_dowler/tr_dowler';
 import mj_dowler from '../../people/g5/ja_dowler/mj_dowler';
    // Side: Nancy McKivor Riley
    import rw_mcleod from '../../people/g5/n_mckivor_riley/rw_mcleod';
    import jg_mcleod from '../../people/g5/n_mckivor_riley/jg_mcleod';
    import esh_mcleod from '../../people/g5/n_mckivor_riley/esh_mcleod';


















// dfgdfgdgdfgdfgdfgdfgdfgdfg

import TestOne from '../../people/TestOne';
import testTwo from '../../people/testTwo';
import testThree from '../../people/testThree';
import testFour from '../../people/testFour';
import testFive from '../../people/testFive';
import mating from './mating';

import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_rt.jpeg';

// const nodes = [
//   { id: 'A', data: { label: 'Node A' }, position: { x: 100, y: 100 } },
//   { id: 'B', data: { label: 'Node B' }, parentNode: 'A', extent: 'child', position: { x: 200, y: 200 } },
//   { id: 'C', data: { label: 'Node C' }, parentNode: 'A', extent: 'child', position: { x: 300, y: 200 } },
//   { id: 'D', data: { label: 'Node D' }, parentNode: 'B', extent: 'child', position: { x: 250, y: 300 } },
//   { id: 'E', data: { label: 'Node E' }, parentNode: 'C', extent: 'child', position: { x: 350, y: 300 } },
// ];

const nodeTypes = {
  // buttons
  treeButton: TreeButton,
  g2hjr: G2HJR,
  g2jhr: G2JHR,
  g2mr: G2MR,
  g2lbr: G2LBR,
  g2csr: G2CSR,
  g2rsr: G2RSR,

  // g1
  hmurgatroyd: HMurgatroyd,
  rtriley:RTRiley,
  // g2
  csriley:CSRiley,
  jiculver:JICulver,
  tbriley:TBRiley,
  wbriley:WBRiley,
  lbriley:LBRiley,
  regperry:REGPerry,
  grriley:GRRiley,
  hmriley:HMRiley,
  mamacqueen:MAMacQueen,
  rsriley:RSRiley,
  kehiggins:KEHiggins,
  jhriley:JHRiley,
  icscott:ICScott,
  hjriley:HJRiley,
  rmmoore:RMMoore,
  // g3
    // rob sanford's side:
  rsjr:rsjr,
  ksriley:KSRiley,
  chapin:ChapinRiley,
  cmriley:CMRiley,
  rlriley:RLRiley,
    // cs riley's side:
  wcriley:WCRiley,
  rontriley:RonTRiley,
  jeriley:JERiley,
  consanfriley:ConSanfRiley,
  gafriley:GAFRiley,
  nariley:NARiley,
  jdriley:JDRiley,
  robsanf:RobSanf,
  // Lavinia Bell's side:
  eaperry:EAPerry,
  rrperry:RRPerry,
  hmperry:HMPerry,
  jgperry:JGPerry,
  // John Herbert Riley's side:
  hwriley:HWRiley,
  ecriley:ECRiley,
  haroldj:HaroldJ,
  rmriley:RMRiley,
  tmriley:TMRiley,
  jmriley:JerMRiley,

  // G4 !!!!
  // Rob Sanf side:
  rsriley3:RSRiley3,
  mpriley:MPRiley,
  wdriley:WDRiley,
  dpriley:DPRiley,
  // KSRiley side:
  ssburrage:SSBurrage,
  aaburrage:AABurrage,
  bburrage:BBurrage,
  wsburrage:WSBurrage,
  lbburrage:LBBurrage,
  nrburrage:NRBurrage,
  gburrage:GBurrage,
  // chapin riley side:
  cynthiariley:CynthiaRiley,
  scriley:SCRiley,
  hariley:HARiley,
  // Dr. CMRiley's side:
  kcriley:KCRiley,
  plriley:PLRiley,
  chrismriley:ChrisMRiley,
  // Rosamund Lavinia side:
  rsbennett:RSBennett,
  rcbennett:RCBennett,
  bsbennett:BSBennett,
  psbennett:PSBennett,
  rlbennett:RLBennett,
  // William Culver Side:
  wculverriley:WCulverRiley,
  ehriley:EHRiley,
  chriley:CHRiley,
  // Ron Thomas Side:
  rtjr:rtjr,
  jermriley:JerMRiley,
  pariley:PARiley,
  // Jean Elizabeth Side:
  gmblack:GMBlack,
  cmblack:CMBlack,
  consriley:con_s_riley,
  dariley:DARiley,
  // George Albert Fletchler Riley's side:
  alriley:ALRiley,
  // Nancy Riley side:
  shosler:shosler,
  slosler:slosler,
  ggosler:ggosler,
  // John Derek Riley Side
  hjeanriley:hjeanriley,
  ddriley:DDRiley,
  dderekriley:DDerekRiley,
  lariley:LARiley,
  // Rob Sanford Riley side:
  hughsriley:HughSRiley,
  gpsriley:GPSRiley,
  nmriley:NMRiley,
  // Edmund Alexander Perry side:
  rcperry:RCPerry,
  deperry:DEPerry,
  // Harriet Maud Perry 
  jmlederman:JMLederman,
  rglederman:RGLederman,
  kjlederman:KJLederman,
    // Harold William Riley side:
  michaelcriley:MichaelCRiley,
  spriley:SPRiley,
    // Eleanor Collum Riley Side
  aepalmour:AEPalmour,
  krpalmour:KRPalmour,
  // Harold James side:
  janetmriley:JanetMRiley,
  ruthmriley:RuthMRiley,
  // ruth murgatroyd (sally) dowler side
  jmdowler:JMDowler,
  jadowler:JADowler,
  srdowler:SRDowler,
  // John Moore Riley side
  jcmriley:JCMRIley,
  nmckivorriley:NMcKivorRiley,
  tcriley:TCRiley,
  // G 5 !!!!!!!!!!!!!!!!!!
  // Rob Sanford Riley III side:
  tkriley:tk_riley,
  renetriley:rene_t_riley,
  cpriley:cp_riley,
  // margaret putnam riley side:
  bcparker:bc_parker,
  cgparker:cg_parker,
  // Dr William Dixon Riley side:
  heatherriley:heatherRiley,
  kimriley:kimRiley,
  peterriley:peterRiley,
  krplazo:kprlazo,
  // David Prince Riley
  jwriley:jw_riley,
  sethriley:seth,
  // Sally Sanford Burrage side
  ebney:eb_ney,
  saney:sa_ney,
  paney:pa_ney,
  // Side: Anita Alden Burrage
  jbers:j_bers,
  rbers:r_bers,
  // Side: Walter Swan Burrage
  aaburrage:aa_burrage,
  ksburrage:ks_burrage,
  abburrage:ab_burrage,
  clburrage:cl_burrage,
  sdburrage:sd_burrage,
  // Side: Nicholas Rand Burrage
  msburrage:ms_burrage,
  jcburrage:jc_burrage,
  mischa:mischa,
  mharoutunian:mharoutunian,
  // Side: Cynthia Riley
  khfehsenfeld:khfehsenfeld,
  dtfehsenfeld:dtfehsenfeld,
  cafehsenfeld:cafehsenfeld,
  // Side: Kitty Chapin Clark
    pclark:pclark,
    dclark:dclark,
  // Side: Patricia Lyman Riley
  etlenthall:etlenthall,
  bklenthall:bklenthall,
  jplenthall:jplenthall,
  // side: Janet J. Riley 
  raaardal:ra_aardal,
  rraardal:rr_aardal,
  // Side: Robert Sanford Bennett
  ameany:a_meany,
  srbennett:sr_bennett,
  // Side: Rebecca Chapin Bennett
  kswallace:ks_wallace,
  cjwallace:cj_wallace,
  // Side: Bruce Shaw Bennett
  jmbennett:jm_bennett,
  ffbennett:ff_bennett,
  labennett:la_bennett,
  // Side: Peter Smith Bennett
  erbbennett:erb_bennett,
  pabennett:pa_bennett,
  cpbennett:cp_bennett,  
  // Side: William Culver Riley
  chriley:ch_riley,
  shriley:sh_riley,
  mhriley:mh_riley,
  // Side: Ronald THomas Riley
  rtriley5:rt_riley,
  gmriley:gm_riley,
  mgriley:mg_riley,
  // Side: Jeremy Montegu Riley
  tsriley:ts_riley,
  lkriley:lk_riley,
  pariley:pa_riley,
  // Side: George Montegu Black III (1940-)
  tjblack:tj_black,
  acblack:ac_black,
  msblack:ms_black,
  gmblack4:gm_black4,
  // Side: Conrad Moffat Black (1944-)
  jdcblack:jdc_black,
  aweblack:awe_black,
  jplblack:jpl_black,
  // Side: Conrad Sanford Riley
  jeriley:je_riley,
  csriley5:cs_riley5,
  // Side: Dennis Albert Riley (1949-)
  drmarshall:dr_marshall,
  // Side: Ailsa Lynn Riley 
  pghgisle:pgh_gisle,
  cevantienhoven:ce_van_tienhoven,
  cagvantienhoven:cag_van_tienhoven,
  // Side: Sanford Lake Osler
  mfosler:mf_osler,
  lmosler:lm_osler,
  // Side: Susan Harty Osler (1951-)
  gcmatthews:gc_matthews,
  shmatthews:sh_matthews,
  tjmatthews:tj_matthews,
  // Side: Gillian Gordon Osler (1959-)
  mkofortier:mko_fortier,
  ncrfortier:ncr_fortier,
  // Side: Helena Jean Riley (1949-)
  riley:riley,
  derek:derek,
  lauren:lauren,
  // Side: David Derek Riley (1951-)
  andrewriley:andrew_riley,
  kateriley:kate_riley,
  // Mom's side
  jean:jean,
  tom:tom,
  me:me,
  chris:chris,
  // Sandy and Debby's side:
  jessmriley:jess_m_riley,
  megmriley:meg_m_riley,
  jrsanfordriley:jr_sanford_riley,
  // Side: George Patrick Stephenson Riley
  djriley:dj_riley,
  heariley:hea_riley,
  catherinepriley:catherine_p_riley,
// Side: Nancy Meribeth Riley
  bsrmurray:bsr_murray,
  chrmurray:chr_murray,
  cmrmurray:cmr_murray,
// Side: Robert Christie Perry
  caperry:ca_perry,
  reperry:re_perry,
  kjperry:kj_perry,
// Side: Donald Edmund Perry
  jhperry:jh_perry,
  adperry:ad_perry,
  mjaperry:mja_perry,
  heperry:he_perry,
// Side: Robert Guthrie Lederman
  rgmlederman:rgm_lederman,
  jplederman:jp_lederman,
   // Side: Susan Patton Riley
  cjhoeschen:cj_hoeschen,
  sjhoeschen:sj_hoeschen,
      // Side: Ann Elizabeth Palmour
  jdjpalmour:jdj_palmour,
  jbapalmour:jba_palmour,
  // Side: Katharine Riley Palmour
  phpdaffurn:php_daffurn,
  trstandrewdaffurn:tr_standrew_daffurn,
  // Side: Janet Margaret Riley
  jrbourbon:jr_bourbon,
  jfbourbon:jf_bourbon,
     // Side: Ruth Moore Riley 
    rjjackson:rj_jackson,
    vmjackson:vm_jackson,
 // Side: James Andrew Dowler
  pmdowler:pm_dowler,
  trdowler:tr_dowler,
  mjdowler:mj_dowler,
    // Side: Nancy McKivor Riley
    rwmcleod:rw_mcleod,
    jgmcleod:jg_mcleod,
    eshmcleod:esh_mcleod,



  // other:
    one:TestOne,
    two:testTwo,
    three:testThree,
    four:testFour,
    five:testFive,
    mating:mating
};


// to make full screen, place button top right (saying full screen)..
// button triggers height and width params in style in app.js????
function Flow({sharedState}) {
  const { theSharedState } = useAuth();
  const navigate = useNavigate();
  // 
  const reactFlowWrapper = useRef(null);
  // const reactFlowInstance = useReactFlow()

  // const nodes = useStore((state) => state.nodes);
  const reactFlowInstance = useReactFlow();

  const [theWidth, setTheWidth] = useState(window.innerWidth);
  // const [viewportX, setViewportX] = useState(theWidth-514);
  const [viewportX, setViewportX] = useState(500);

  const [viewportY, setViewportY] = useState(100);
  const [viewportZoom, setViewportZoom] = useState(0.25);

  useEffect(()=>{
    const handleResize = () => {
      setTheWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // 
    // 
  },[]);
  reactFlowInstance.getViewport();
  
  
// Custom Node Testing:

// useEffect(() => {
//   const onChange = (event) => {
//   const color = event.target.value;
//   setBgColor(color); 
//   }
// },[]);

// Custom Node Testing End
  // make 514=175...so for every "1" added to width "1" gets added..
  // to "viewPortX"

  // useEffect(() => {
  //   if (someNodes.length > 0) {
  //     const reactFlowInstance = reactFlowWrapper.getViewport();
  //     reactFlowInstance.zoomOut();
  //   }
  // }, [someNodes]);

  // reactFlowInstance.setViewport({
  //   x:makeTotalFull ? (theWidth/2)-60 : 175,
  //   y:viewportY,
  //   zoom:viewportZoom
  // })
  
// x:(theWidth/2)-60, WORKS for fullscreen centering the tree

  // center the tree in "border version":
  // make the x/y numbers above "state" and manipulate them in the below functions?
  function centerTree() {
    // alert('dfgfd')
    // reactFlowInstance.getViewport();
    // reactFlowInstance.zoomOut();

    // reactFlowInstance.setViewport({x:175,y:100,zoom:0.5})

  }
  // center the tree in full-screen version:
  function centerTreeFullScreen() {
    // alert('dfgfd')
    // reactFlowInstance.getViewport();
    // reactFlowInstance.zoomOut();

    // reactFlowInstance.setViewport({x:175,y:50,zoom:0.5})

  }

const [makeFull, setMakeFull] = useState(false);
const [makeTotalFull, setMakeTotalFull] = useState(true);
const [notWide, setNotWide] = useState(false);

reactFlowInstance.setViewport({

  x:makeTotalFull ? (theWidth/2.2) : 400,
  y:viewportY,
  zoom:viewportZoom
})
  // useEffect(() => {
  // },[])
  // setTimeout(reactFlowInstance.fitView());
  // Create a match function
  function myFunction(x) {
    if (x.matches) {
      document.body.style.backgroundColor = "yellow";
      setNotWide(true);
    } else {
      document.body.style.backgroundColor = "pink";
      setNotWide(false);
    }
  }
  const mmObj = window.matchMedia("(max-width: 600px)")

function fullScreen() {
    setMakeTotalFull(!makeTotalFull);

// if(theWidth < 800) {
//   alert('widen your browser');
// } else {
//   // setMakeFull(!makeFull);
//   setMakeTotalFull(!makeTotalFull);
// }
  
}
// 13.5 over 950?
const x = theWidth/13.5;
const y = 50;
// useEffect(() => {
  
// },[]);

function toHome() {
  navigate('/home');
}
function toTree() {
  navigate('/family-tree');
}
function toProfile() {
  navigate('/profile')
}
function toFaqs() {
  navigate('/faqs');
}
function toPhotos() {
  navigate('/album-list');
}
function toWall() {
  navigate('/community-wall');
}
function toAgenda() {
  navigate('/agenda');
}

// scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

const [hideBar, setHideBar] = useState(false);

function toggleHide() {
setHideBar(!hideBar);
}

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

// Add the match function as a listener for state changes:
  return (
    <>
    {/* <br/> */}
    {/* <div className="rt-the-container">
      <img src={rt} 
      style={{
        width: theWidth<880 ?`${y}%` :`${x}%`,transitionProperty:"width",
        transitionDuration:"0.5s"
      }}
      />
    </div> */}
    { !makeTotalFull ?
    <>
            {/* border version of the tree here.... */}
      <Dropdown handleBoolChange={handleBoolChange} />
      {/* <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
      <h3 className="title">Family Tree</h3>
      <div className="center-stuff">
      <div ref={reactFlowWrapper} className="tree-border">
      <AiOutlineFullscreen onClick={fullScreen} style={{cursor:"pointer", float:"right", 
      marginRight:"0.5%",marginTop:"0.4%",
      color:"black", fontSize: '25px'}}></AiOutlineFullscreen>
      <div style={{borderBottom:"dashed black"}}>
      <div style={{textAlign: "center", marginTop:"1%", marginBottom:"1%",marginLeft:"40px"}}>
      <span>R.T. Riley and Harriet Murgatroyd Family Tree</span>
      </div>
      </div>
      <ReactFlow 
        onConnect={centerTree}
        nodes={sharedState} 
        edges={someEdges}
        // onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        minZoom={0.05}
        />
        </div>
        </div>
        </>

        :

        <>
        {/* full screen version of the tree here.... */}
      <div ref={reactFlowWrapper} className="tree-border-full">
      {theWidth>700 ?
    !hideBar && <div>
    <div style={{position:"absolute",left:"4%", top:"1.5%",}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div style={{position:"absolute",left:"14%", top:"1.5%",}}>Profile</div>
    <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",left:"15%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"3.5%", top:"1.5%",}}>Photos</div>
    <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"13.3%", top:"1.5%",}}>Agenda</div>
    <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"24.5%", top:"1.5%",}}>Tree</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} />
    </div>
    :
    !hideBar && 
    <>
    <Dropdown />
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"4%",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    </>
    }
      {/* <AiOutlineFullscreenExit onClick={fullScreen} style={{cursor:"pointer", float:"right", 
      marginRight:"0.5%",marginTop:"-5px",
      color:"black", fontSize: '25px'}}></AiOutlineFullscreenExit> */}
      {!hideBar && 
      <BsCaretUpFill onClick={toggleHide} 
      style={{cursor:"pointer",position:"absolute", top:"10%", left:"50%", transform:"translate(-50%, -50%)"}}
      />
      }
      <div style={{borderBottom:"dashed black"}}>
      <div style={{textAlign: "center"}}
      // marginTop:"-8px", marginBottom:"5px",marginLeft:"40px"}}
      >
      {!hideBar ? 
      <>
      <h1 style={{fontFamily:"Helvetica Neue"}}>Family Tree</h1>
      </>
      :
      <BsCaretDownFill onClick={toggleHide} 
      style={{cursor:"pointer",position:"relative"}}
      />
      }
      </div>
      </div>
      <ReactFlow 
        onConnect={centerTree}
        nodes={sharedState} 
        edges={someEdges}
        // onNodesChange={onNodesChange}
        nodeTypes={nodeTypes}
        minZoom={0.05}
        theSharedState={theSharedState}
        />
        </div>
        </>
      }
    </>
  );
}

export default Flow;

// 
// 
// 
// 

// import { useCallback } from 'react';
// import ReactFlow, {
//   MiniMap,
//   Controls,
//   Background,
//   useNodesState,
//   useEdgesState,
//   addEdge,
// } from 'reactflow';

// import 'reactflow/dist/style.css';

// const initialNodes = [
//   { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
//   { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
// ];

// const initialEdges = [{ id: 'e1-2', source: '1', target: '2' }];

// function Flow() {
//   const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
//   const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

//   const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

//   return (
//     <ReactFlow
//       nodes={nodes}
//       edges={edges}
//       onNodesChange={onNodesChange}
//       onEdgesChange={onEdgesChange}
//       onConnect={onConnect}
//     >
//       <MiniMap />
//       <Controls />
//       <Background />
//     </ReactFlow>
//   );
// }

// export default Flow;