import React, { useEffect, useRef, useState, useContext } from 'react';
import Dropdown from '../dropDown';
import { useNavigate  } from 'react-router-dom'
import '../somecss.css'
import LoadingSpinner from './loadingSpinner';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs.png';
import yorkshirePainting from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/yorkshirePainting.jpeg';
import yorkshire1800 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/yorkshire1800.jpeg';
import waves from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/wave_sunset.jpeg';
import clouds from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/clouds.jpg';
import sdyorkshire from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_yorkshire.jpeg';
import sdimage from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_home_3.jpeg';
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import stripes from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/stripes.png';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_rt.jpeg';
import rt_head from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/rt_head.png';
import riley_clan from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_clan.png';
// import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/r-pic-4.jpeg';
// r-pics up for it : 4, 5 (for now but change for real),
// 11 is workable and looks accurate?
// 12 is dece but the kid is too cocksure?
// 13 could work but not colourful? and is the vibe right in other ways?
// 15 might work
function GroupPassword({ katePass, showKate }) {
  const navigate = useNavigate();
  const  passwordForKate = process.env.REACT_APP_PASSWORD_FOR_KATE;
  const [hasPasswordForKate, setHasPasswordForKate] = useState(false);

  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    setPassword(event.target.value);
  };
  function goHome() {
    navigate('/home');
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === passwordForKate) {
      setLoading(true);
      katePass();
      localStorage.setItem('groupPassword', password);
      setErrorMessage('');
      // alert("yep");
      setTimeout(() => {
        setLoading(false);
        navigate('/home');
      }, 3000); 
    } else {
      setErrorMessage('Invalid password');
      alert(errorMessage);
    }
  };

    const [theWidth, setTheWidth] = useState(window.innerWidth);
    const [quote, setQuote] = useState();

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    useEffect(() => {
      // Check if 'passwordForKate' exists in localStorage
      const storedPasswordForKate = localStorage.getItem('groupPassword');
      
      if (storedPasswordForKate=== passwordForKate) {
        // 'passwordForKate' exists in localStorage, set state to true
        setHasPasswordForKate(true);
      } else {
        // 'passwordForKate' doesn't exist in localStorage, set state to false
        setHasPasswordForKate(false);
      }
    }, []);

  return (
    <>
    <div className={loading ? "blur-it" : ""}>
    <div className="title">
             {/* <h1 className="the-titles">Riley Family Summit</h1> */}

             <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
              Riley Reunion
            </div>
             </div>
        <div style={{
          backgroundImage: `url(${newpic})`,
          backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        // width:"900px",
        // minHeight:"100px"
        width: '100%',
        height: theWidth,
        // maxHeight:"800px",
        // maxWidth:"800px",
        // border:"solid black 1px"
             }}> 
             
             <div style={{position:"absolute",top:"3px",left:"5%"}}>   
              <img src={riley_clan} style={{width:theWidth<450?"35px":"50px"}} />
             </div>
             {/* <div style={{marginLeft:"5%"}}>   
              <img src={riley_clan} style={{width:"50px"}} />
            </div> */}
    <div className="text-overlay">
    <br/><br/><br/><br/><br/><br/><br/>
    <div className="group-password">
    {hasPasswordForKate
    ?
    <>
    <div style={{color:"black", fontFamily:"Helvetica Neue",margin:"20px"}} >Your device is remembered</div>
    <div className="center-stuff">
    <div style={{marginBottom:"20px"}}>
    <button onClick={goHome} 
      // style={{cursor:"pointer",width:"100px"}}
      className="stories-button"
      >Go to Home</button>
    </div>
    </div>
    {/* </form> */}
    </>
    :
    <>
    <div style={{color:"black", fontFamily:"Helvetica Neue",margin:"20px"}} >Group Password:</div>
    <form onSubmit={handleSubmit}>
    <input type="password" value={password} onChange={handleInputChange} /><br/><br/>
    <button type="submit" style={{cursor:"pointer"}}>Submit</button><br/><br/>
    </form>
    </>
    }
    </div>
    </div>
    </div>
    
    {theWidth>850 ?
    <>
    {/* <div className="cloud">
      <span className="text-inside-cloud">
      <i>“When I left England I had no definite plans
      as to what I was going to do...
    and no pledges, except to my mother that I would return at the end of two years”</i>
      </span>
    </div>
    <div className="cloud-first">
    <span className="text-inside-cloud"> R.T.
    </span>
    </div>
    <div className="cloud-second"></div>
    <div style={{position:"absolute", top:"220px",right:"-135px"}}>
    <img src={rt_head} style={{width:"20%"}} />
    </div> */}
    </>
    :
    <div className="title">
    <div style={{marginTop:25, marginRight:50,marginLeft:50,marginBottom:25,fontFamily:"Tahoma",fontSize:"18px"}}>
   <i>“When I left England I had no definite plans as to what I was going to do (for I did not know myself) and no pledges, except to my mother that I would return at the end of two years”</i>
   <br/><br/> 
   - R.T. Riley
    </div>
    </div>
    }
    {theWidth > 850 &&
    <div className="quote-container-wide">
    <div className="quote">
    <h4 ><i>“When I left England I had no definite plans
    as to what I was going to do...and no pledges, except to my mother that I would return at the end of two years”</i><br/><br/>
    - R.T. Riley</h4>
    </div>
    </div>
    }
    </div>
    {loading && 
    <>
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <div style={{position:"absolute",top: "25%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <div>
        <h1 style={{fontFamily: 'cursive', fontSize: '3.5em', fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '10px'}}>
        <div>Welcome</div>
        </h1>
        </div>
      </div>
      <LoadingSpinner />
    </div>
    </>
    }
    </>
  );
}

export default GroupPassword;