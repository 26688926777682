import React, { useEffect, useRef, useState } from 'react';
import { Card, Button, Alert, Spinner } from 'react-bootstrap'
import Select from 'react-select';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Dropdown from '../../dropDown';
import LoadingSpinner from '.././loadingSpinner';
import CryptoJS from 'crypto-js';
// import { Document, Page, pdfjs } from 'react-pdf';
// import { Image } from '@react-pdf/image';


// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';

import { useNavigate  } from 'react-router-dom'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import goodfellasPainting from './goodfellas_painting.jpg';
// import garcia from './garcia_74.jpeg'
// import dimon from './jamie_dimon.jpeg'
import {BsFillHouseFill,BsUpload,BsXCircle,BsArrow90DegUp,BsHouseFill, BsPersonVcard, BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen} from "react-icons/bs";

import '/Users/Ian/ProjectFolder/family-tree-june/src/unused logic/carouselStyles.css'
import '../../somecss.css'

import dimon from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/jamie_dimon.jpeg'
import goodfellasPainting from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/goodfellas_painting.jpg';
import garcia from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/garcia_74.jpeg'

import clouds from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/clouds.jpg'
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import { BsPlusLg } from "react-icons/bs";

// firebase stuff
import { collection, getFirestore, onSnapshot, getDocs, doc, updateDoc, where, query, arrayUnion } from "firebase/firestore";
import { listAll, getStorage, ref, getDownloadURL, getMetadata } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';


// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/legacy/build/pdf.worker.min.js',
//      import.meta.url,
//    ).toString();

function AlbumList() {

  const db = getFirestore();
  const { currentUser } = useAuth();
  const functions = getFunctions(app);
  // const proxyFunction = functions.httpsCallable('proxy');

    const navigate = useNavigate();
    const storage = getStorage();
    const [loginFirst, setLoginFirst] = useState(false);
    const [profiles, setProfiles] = useState([]); // State to hold profiles data
  
    const theFilters = ['Family','Historical','Past-Events','Other'];

    const [selectedFilter, setSelectedFilter] = useState('All');

    const fetchProfiles = async () => {
      // Replace 'profiles' with your actual Firestore collection name
      const profilesSnapshot = await onSnapshot(collection(db, 'profiles'), (snapshot) => {
        const profilesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProfiles(profilesData);
      });
    };
    useEffect(() => {
      // fetchProfiles();
    },[db])

    // Photo Specific Links:
    function toUpload() {
      if(currentUser) {
        navigate('/upload');
      } else { 
        // alert('Log in first!');
        setLoginFirst(true);
      }
    }
    function hideLogin() {
      setLoginFirst(false);
      // alert('dfg');
    }
    // const encryptEmail = (email) => {
    //   const encryptedEmail = CryptoJS.AES.encrypt(email, 'secret12345').toString();
    //   return encodeURIComponent(encryptedEmail);
    // };
    // const hashEmail = (email) => {
    //   return CryptoJS.SHA256(email).toString();
    // };
    const theSecret = process.env.REACT_APP_SECRET;

    const encryptEmail = (email) => {
      try {
        const encryptedEmail = CryptoJS.AES.encrypt(email, theSecret).toString();
        return encodeURIComponent(encryptedEmail);
      } catch (error) {
        console.error('Encryption error:', error);
        return null;
      }
    };
  
    // function toExampleAlbum(theEmail, albumName) {
    //   const hashedEmail = hashEmail(theEmail);
    //   navigate(`/album/${hashedEmail}/${albumName}`);
    // }
  
  
    function toExampleAlbum(theEmail, albumName, theBucket) {
      const encryptedEmail = encryptEmail(theEmail);
      if (encryptedEmail) {
        navigate(`/album/${encryptedEmail}/${theBucket}/${albumName}`);
      } else {
        console.error('Encryption failed');
      }
    }
    function toPdf(theUrl) {
      if (theUrl) {
        window.open(theUrl, '_blank');
      } else {
        console.error('It failed');
      }
    }
  
      function toClickedProfile(userEmail) {
        const encryptedEmail = userEmail;
      if (encryptedEmail) {
        navigate(`/profiles/${encryptedEmail}`);
      } else {
        console.error('Encryption failed');
      }
      }
    // Regular Shortcuts:
    function toHome() {
      navigate('/home');
    }
    function toTree() {
      navigate('/stories');
    }
    function toProfile() {
      navigate('/profiles')
    }
    function toFaqs() {
      navigate('/faqs');
    }
    function toPhotos() {
      navigate('/album-list');
    }
    function toWall() {
      navigate('/community-wall');
    }
    function toAgenda() {
      navigate('/agenda');
    }
    function toTree() {
      navigate('/stories');
    }
    function toLogin() {
      navigate('/login');
    }
// I'm going to
const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);

    // firebase STORAGE download tests
    const [storagePhoto, setStoragePhoto] = useState();
    const [storagePhotos, setStoragePhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pdfLoading, setPdfLoading] = useState(true);
    // the single photo one:
        // useEffect(() => {
        //   const downloadPhoto = async () => {
        //     try {
        //       setLoading(true);
        //       const photoRef = ref(storage, 'testing/Screen Shot 2023-02-13 at 11.24.28 AM.png');
        //       const downloadURL = await getDownloadURL(photoRef);
        //       console.log('Download URL:', downloadURL);
        //       setStoragePhoto(downloadURL);
              // const timer = setTimeout(() => {
              //   setLoading(false);
              // }, 1000); 
              // return () => {
              //   setLoading(false);
              // };
        //     } catch (error) { 
        //       console.error('Error downloading photo:', error);
        //       alert('Error downloading photo:', error);
        //     }
        //   };
        //   downloadPhoto();
        // },[])
    // the arbitrary one for a particular folder:
    // useEffect(() => {
    //   const downloadPhotos = async () => {
    //     try {
    //       const imageRefs = await listAll(ref(storage, 'testing'));
          
    //       const downloadedPhotos = await Promise.all(
    //         imageRefs.items.map(async (imageRef) => {
    //           const downloadURL = await getDownloadURL(imageRef);
    //           return { name: imageRef.name, url: downloadURL };
    //         })
    //       );
    //       setStoragePhotos(downloadedPhotos);
    //       console.error('working...',downloadedPhotos);
    //     } catch (error) {
    //       console.error('Error downloading photos:', error);
    //     }
    //   };
    //   downloadPhotos();
    // }, []);

    // The one for cover photos : 
    useEffect(() => {
      if(selectedFilter==="All"){
        return;
      }
      const downloadFirstImagesInUserAlbums = async () => {
        try {
          setLoading(true);
          // Get a reference to the 'albums' path
          const albumsRef = ref(storage, `albums/${selectedFilter}`);
  
          // List all user emails
          const userEmailRefs = await listAll(albumsRef);
  
          const firstImagesInAlbums = await Promise.all(
            userEmailRefs.prefixes.map(async (userEmailRef) => {
              // List all user albums
              const userAlbumRefs = await listAll(userEmailRef);
  
              return Promise.all(
                userAlbumRefs.prefixes.map(async (albumRef) => {
                  // List all images in the user album
                  const imageRefs = await listAll(albumRef);
  
                  if (imageRefs.items.length > 0) {
                    // Get length of album:
                    const lengthRef = imageRefs.items.length;
                    // Get the first image in the album
                    const firstImageRef = imageRefs.items[0];
                    // last modified stuff
                    const metadata = await getMetadata(firstImageRef);
                    const lastModified = metadata.updated;
                    // console.log(JSON.stringify(firstImageRef));
                    const downloadURL = await getDownloadURL(firstImageRef);
                    return { email: userEmailRef.name, album: albumRef.name, url: downloadURL, 
                      lastModified,bucket: selectedFilter, length:lengthRef,
                  };
                    // setLoading(false);
                  }
  
                  return null; // Return null if the album is empty
                })
              );
            })
          );
  
          // Flatten the array of arrays into a single array
          const validPhotos = firstImagesInAlbums.flat().filter((photo) => photo !== null);
  
          setStoragePhotos(validPhotos);
          // console.error('working...', validPhotos);
          const timer = setTimeout(() => {
            setLoading(false);
          }, 1000); 
          return () => {
            setLoading(false);
          };
        } catch (error) {
          // console.error('Error downloading photos:', error);
          const timer = setTimeout(() => {
            setLoading(false);
          }, 1000); 
          return () => {
            setLoading(false);
          };
        }
      };
  
      downloadFirstImagesInUserAlbums();
    }, [selectedFilter]);

    // for ALLL:
    useEffect(() => {
      const fetchData = async () => {
        try {
          if (selectedFilter === 'All') {
            setLoading(true);
  
            const allPhotos = [];
  
            for (const filter of theFilters) {
              const albumsRef = ref(storage, `albums/${filter}`);
              const userEmailRefs = await listAll(albumsRef);
  
              const firstImagesInAlbums = await Promise.all(
                userEmailRefs.prefixes.map(async (userEmailRef) => {
                  const userAlbumRefs = await listAll(userEmailRef);
  
                  return Promise.all(
                    userAlbumRefs.prefixes.map(async (albumRef) => {
                      const imageRefs = await listAll(albumRef);
  
                      if (imageRefs.items.length > 0) {
                        const lengthRef = imageRefs.items.length;
                        const firstImageRef = imageRefs.items[0];
                        const metadata = await getMetadata(firstImageRef);
                        const lastModified = metadata.updated;
                        const downloadURL = await getDownloadURL(firstImageRef);
  
                        const photoObject = {
                          email: userEmailRef.name,
                          album: albumRef.name,
                          url: downloadURL,
                          lastModified,
                          bucket: filter, // Add the new "bucket" property
                          length:lengthRef,
                        };
  
                        allPhotos.push(photoObject);
                      }
  
                      return null;
                    })
                  );
                })
              );
  
              // Flatten the array of arrays into a single array for each filter
              const validPhotos = firstImagesInAlbums.flat().filter((photo) => photo !== null);
  
              // Do something with the validPhotos array for each filter if needed
              // For example, you might want to set state or perform some other action
  
              // Clear the firstImagesInAlbums array for the next iteration
              firstImagesInAlbums.length = 0;
            }
  
            // Combine allPhotos from different filters into a single array
            const combinedPhotos = allPhotos.flat().filter((photo) => photo !== null);
  
            setStoragePhotos(combinedPhotos);
  
            const timer = setTimeout(() => {
              setLoading(false);
            }, 1000);
  
            return () => {
              setLoading(false);
              clearTimeout(timer);
            };
          }
        } catch (error) {
          // Handle the error
          console.error('Error downloading photos:', error);
  
          const timer = setTimeout(() => {
            setLoading(false);
          }, 1000);
  
          return () => {
            setLoading(false);
            clearTimeout(timer);
          };
        }
      };
  
      fetchData();
    },[selectedFilter])

    const handleBoolChange = (newValue) => {
      // setBoolValue(newValue);
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [displayedPhotos, setDisplayedPhotos] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState()
    const totalPages = Math.ceil(storagePhotos.length / itemsPerPage);

    useEffect(() => {
      if (theWidth > 1200) {
        setItemsPerPage(19);
      } else if (theWidth >= 1050) {
        setItemsPerPage(13);
      }
        else if (theWidth >= 781) {
          setItemsPerPage(7);
        }
       else if (theWidth >= 530) {
        setItemsPerPage(10);
      } else {
        setItemsPerPage(10);
      }
      const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // last modified stuff:
    // const sortedPhotos = storagePhotos.slice().sort((a, b) => b.lastModified - a.lastModified);
    // fdgdfg
    const sortedPhotos = storagePhotos.slice().sort((a, b) => {
      const dateA = new Date(a.lastModified);
      const dateB = new Date(b.lastModified);
      return dateB - dateA;
    });
    const currentPhotos = sortedPhotos.slice(indexOfFirstItem, indexOfLastItem);
    // const currentPhotos = storagePhotos.slice(indexOfFirstItem, indexOfLastItem);
    setDisplayedPhotos(currentPhotos);
    },[theWidth,currentPage,storagePhotos])

    // useEffect to take pdfs and convert to images
    

  // Get photos for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPhotos = storagePhotos.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
    };
  
    const prevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };
    const handlePdfLoad = () => {
      // Set a short timeout to allow for rendering to complete
      setTimeout(() => {
        setPdfLoading(false);
      }, 1000); // Adjust the timeout duration as needed
    };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    // Perform filtering logic based on the selected filter
    // Update your list accordingly
  };
  const options = [
    { value: 'family', label: 'Family' },
    { value: 'historical', label: 'Historical' },
    { value: 'past-events', label: 'Past-Events' },
    { value: 'other', label: 'Other' },
  ];
  const handleOptionChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    setSelectedFilter(selectedOption.label);
    // if (selectedOption && (selectedOption.value === 'family')) {
    //   setSelectedFilter(selectedOption.label);
    //   }  if (selectedOption && (selectedOption.value === 'historical')) {
    //     setSelectedFilter(filter);
    //   }    if (selectedOption && (selectedOption.value === 'past-events')) {
    //     setSelectedFilter(filter);
    //   }  if (selectedOption && (selectedOption.value === 'other')) {
    //     setSelectedFilter(filter);
    //   }      
    //   else {
    //     console.log('Something wrong with handleOptionChange thingy');
    //   }
  };
   

  return (
    <>
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    {/* <div style={{position:"absolute",left:"4%", top:"1.5%",}}>Home</div>
    <BsHouseFill onClick={toHome}  style={{cursor:"pointer",position:"absolute",left:"5%", top:"4.5%", fontSize:"20px"}}/>
    <div style={{position:"absolute",left:"14%", top:"1.5%",}}>Profile</div>
    <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",left:"15%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",left:"24.5%", top:"1.5%",}}>Wall</div>
    <BsVectorPen onClick={toWall} style={{cursor:"pointer",position:"absolute",left:"25%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"3.5%", top:"1.5%",}}>Photos</div>
    <BsImages onClick={toPhotos} style={{cursor:"pointer",position:"absolute",right:"5%",top:"4.5%", fontSize:"20px"}} />
    <div style={{position:"absolute",right:"13.3%", top:"1.5%",}}>Agenda</div>
    <BsCalendar2Day onClick={toAgenda} style={{cursor:"pointer",position:"absolute",right:"15%", top:"4.5%", fontSize:"20px"}}  />
    <div style={{position:"absolute",right:"24.5%", top:"1.5%",}}>Tree</div>
    <BsDiagram3 onClick={toTree} style={{cursor:"pointer",position:"absolute",right:"25%", top:"4.5%", fontSize:"20px"}} /> */}
    </div>
    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    {/* <BsHouseFill onClick={goHome} style={{cursor:"pointer",position:"absolute",right:"75px"}} /> */}
    {/* <BsPersonVcard onClick={toProfile} style={{cursor:"pointer",position:"absolute",right:"100px"}} /> */}
    <div className="title">
    {/* <h1 className="the-titles">Content</h1> */}
    {theWidth<350?
      <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Reunion
      </div>
    :
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    }
    
    </div>
    {/* testing FILTER STUFF below */}
    {/* <div className="filter-section"> */}
      {theWidth<500 ?
      <>
      <div style={{marginLeft:"15%",marginRight:"15%"}}>
      <Select style={{width:"50px"}}  onChange={handleOptionChange} options={options} defaultValue={options[0]} isSearchable={true}/>
      </div>
      </>
      :
      <>
      <div className="filter-section">
      <p style={{fontSize:"18px"}}>Filter by:</p>
      <button
        className={selectedFilter === 'All' ? 'active' : ''}
        onClick={() => handleFilterChange('All')}
      >All</button>
      <button
        className={selectedFilter === 'Family' ? 'active' : ''}
        onClick={() => handleFilterChange('Family')}
      >
        Family
      </button>
      <button
        className={selectedFilter === 'Historical' ? 'active' : ''}
        onClick={() => handleFilterChange('Historical')}
      >
        Historical
      </button>
      <button
        className={selectedFilter === 'Past-Events' ? 'active' : ''}
        onClick={() => handleFilterChange('Past-Events')}
      >
        Past Events
      </button>
      <button
        className={selectedFilter === 'Other' ? 'active' : ''}
        onClick={() => handleFilterChange('Other')}
      >
        Other
      </button>
    </div>
    </>
      }
    {/* testing above */}
    {/* <div className="center-stuff"> */}
    <div className={loading || loginFirst ? "blur-it" : ""}>
    <div style={{
          // backgroundImage: `url(${newpic})`,
          // backgroundColor:"white",
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
             }}>
    {!loading ? 
      <>
      <div className="center-stuff" >
        <div style={{position:"absolute",top:theWidth<450?"100px":"122px"}}>
          {/* <button className="my-button" onClick={prevPage} disabled={currentPage === 1}> */}
          {/* </button> */}
          <a style={{marginLeft:"20px", marginRight:"10px",cursor: currentPage === 1 ? "not-allowed" : "pointer"}} onClick={prevPage} disabled={currentPage === 1} href="#">Previous</a>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <a style={{marginLeft:"10px",cursor: totalPages === 1 || currentPage === totalPages ? "not-allowed" : "pointer"}} onClick={nextPage} disabled={currentPage === totalPages || totalPages === 1} href="#">Next</a>

          {/* <button className="my-button" onClick={nextPage} disabled={currentPage === totalPages}>
          </button> */}
        </div>
      </div>
      </>
      :
      <>
      </>
    }
    <div className="album-list">
    {/* {JSON.stringify(pdfUrls)} */}
    <Card>
    <div className="old-list-tag" onClick={toUpload}>Upload Content</div>
    <div className="history-grid-stuff" onClick={toUpload}>
    <br/><br/>
    <div><BsPlusLg className="history-grid-icon"  /></div><br/><br/><br/>
    </div>
    </Card>
    
    {/* mapping the albums dynamically: */}
{/* {JSON.stringify(displayedPhotos)} */}
    {/* testing firebase storage renderings: */}
    {/* mapping the downloaded images from /testing */}
    {displayedPhotos.map((photo, indexFirst) => (
      <div key={indexFirst}>
    <Card key={photo.name} onClick={photo.url && photo.url.includes('.pdf') ? () => toPdf(photo.url) : () => toExampleAlbum(photo.email,photo.album,photo.bucket)}>
    <div className="list-tag">{photo.album && photo.album} ({photo.length})</div>
    <div className="history-grid-stuff" 
    // onClick={photo.url && photo.url.includes('.pdf') ? () => toPdf(photo.url) : () => toExampleAlbum(photo.email,photo.album,photo.bucket)} 
    >
    {/* <Card.Body onClick={photo.url && photo.url.includes('.pdf') ? () => toPdf(photo.url) : () => toExampleAlbum(photo.email,photo.album)}><div className="list-tag">{photo.album}</div></Card.Body> */}
    {photo.url && (photo.url.includes('.mp4') ||
            photo.url.includes('.mov') ||
            photo.url.includes('.avi') ||
            photo.url.includes('.webm') ||
            photo.url.includes('.mkv')) ?
    <video className="grid-image">
      <source type="video/mp4" src={photo.url} onClick={() => toExampleAlbum(photo.email,photo.album,photo.bucket)}  />
      Your browser does not support the video tag.
    </video>
    : 
    <>
    {photo.url && photo.url.includes('.pdf') ? 
            <>  
            {/* {console.log(photo.url)} */}
            {/* <button onClick={() => convertPdfToImage(photo.email,photo.album,photo.url)}>test</button> */}
            <div className="grid-image" 
            // onClick={() => toPdf(photo.url)}
            >
            {/* <iframe 
            // onClick={() => toPdf(photo.url)} 
            title="PDF Viewer" 
            // src={`${photo.url}#page=1`} 
            // src={`https://drive.google.com/file/d/${FILE_ID}/preview`}
            src={`https://docs.google.com/gview?url=${encodeURIComponent(photo.url)}&embedded=true`}
            width="100%" height="300px" /> */}
            {pdfLoading && <div className="center-stuff"><div style={{position:"absolute",top:"40%"}}>Loading PDF...</div></div>}
            <embed
                className="disable-pointer-events"
                src={photo.url}
                type="application/pdf"
                width="100%"
                height="100%"
                onLoad={handlePdfLoad} // Set loading state to false when PDF is loaded
          />

            {/* {photo.url} */}
            {/* <Document 
            file=""
            // file={`https://us-central1-reunion-site-test.cloudfunctions.net/proxy?url=${encodeURIComponent(photo.url)}`}
        // file={proxyFunction(photo.url)}
              onLoadSuccess={onDocumentLoadSuccess}
              >
              {pdfLoaded && <Page pageNumber={1} />}
          </Document> */}
            </div>
            </>
    :
            <img loading="lazy" src={photo.url} onClick={() => toExampleAlbum(photo.email,photo.album,photo.bucket)} className="grid-image" />
    }
    </>
    }
    {profiles.map((profile) => {
              if (profile.email === photo.email) {
                return <div key={profile.id}>
                {/* By: <a style={{zIndex:2}} href="#" onClick={() => toClickedProfile(profile.email)}>{profile.username}</a> */}
                </div>;
              } else {
                return
              }
            })}
    </div>
    </Card>
    </div>
    ))}
    </div>
    {/* <button>Testing Storage</button> */}
    {/* </div> */}
    </div><br/>
    </div>
    {/* LOADING SPINNER BELOW..triggered when albums are loading... */}
    {loading && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }
    {loginFirst &&
     <div style={{position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%)"}}>
      {/* <LoadingSpinner /> */}
      <Card>
      <div className="center-login-prompt" style={{cursor:"default"}}>
      <div style={{marginTop:"10px", color:"maroon",cursor:"pointer", position:"fixed",right:"10%"}} onClick={hideLogin}><BsXCircle style={{fontSize:"22px"}} /></div>
      <div style={{marginRight:"10px",marginLeft:"10px", marginTop:"15px"}}>{theWidth<450?<h2>First:</h2>:<h2>You must login first!</h2>}</div>
      <div style={{marginRight:"10px",marginLeft:"10px", marginBottom:"10px"}}><button className="stories-button" onClick={toLogin}>Login</button></div>
      </div>
      </Card>
     </div> 
    }
    
    </>
  );
}

export default AlbumList;