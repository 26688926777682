import React, { useEffect, useRef, useState } from 'react';
import Dropdown from '../dropDown';
import CryptoJS from 'crypto-js';
import {BsFillUnlockFill,BsLockFill,BsUpload,BsArrow90DegUp,BsHouseFill, BsPersonVcard, 
  BsCalendar2Day, BsImages, BsDiagram3, BsBook, BsVectorPen,BsFillHouseFill,BsPersonFill} from "react-icons/bs";
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import { useNavigate, useParams  } from 'react-router-dom'
import { collection, addDoc, getFirestore, onSnapshot, getDoc, getDocs, doc, updateDoc } from "firebase/firestore";
import { listAll, getStorage, ref, getDownloadURL,uploadBytes, deleteObject } from 'firebase/storage';
// photos:
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import paint from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/paint-3.jpeg';
import beer from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/beer_meme.jpeg';
import { Alert } from 'bootstrap';
import LoadingSpinner from './loadingSpinner';


function OtherProfiles() {
  // profile-pics - > email - > file
  const storage = getStorage();
  const db = getFirestore();
  const navigate = useNavigate();
  const { encryptedEmail } = useParams();
  const { logout, currentUser } = useAuth();
  const theSecret = process.env.REACT_APP_SECRET;

  const decryptEmail = (encryptedEmail) => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(decodeURIComponent(encryptedEmail), theSecret);
      const decryptedEmail = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedEmail;
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  };
  
  const paramEmail = decryptEmail(encryptedEmail);

  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const [profilePic, setProfilePic] = useState();

  const [selectedFile, setSelectedFile] = useState(null);

  // zoom in for images being edited:
const [zoom, setZoom] = useState(100);
const [positionX, setPositionX] = useState(0); // Horizontal position (left/right)
const [positionY, setPositionY] = useState(0);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  // Profile Attributes:
  const [name, setName] = useState('test name');
  const [email, setEmail] = useState('test email');
  const [age, setAge] = useState(100);
  const [generation, setGeneration] = useState(2);
  const [aboutMe, setAboutMe] = useState('');
  const [pic, setPic] = useState();
  // Edit Toggle State:
  const [editing, setEditing] = useState(false);
  const [editData, setEditData] = useState([]);
  // Edit stuff
  const [newName, setNewName] = useState('');
  const [selectedAge, setSelectedAge] = useState(1);
    
  // change components:
  const handleAboutChange = (event) => {
    setAboutMe(event.target.value);
  };
  const handlePicChange = (event) => {
    setPic(event.target.value);
  };
  const handleNameChange = (event) => {
    setNewName(event.target.value);
  }
  const changeAge = (event) => {
    const newAge = parseInt(event.target.value, 10); // Parse the value to an integer
    setSelectedAge(newAge);
  };
  // 

  function updatePhoto() {
    // setEnlarged(true);
  }
  function expandPhoto() {
    setEnlarged(true);
  }
  function shrinkPhoto() {
    setEnlarged(false);
  }
  function toHome() {
    navigate('/home');
  }
  function toTree() {
    navigate('/stories');
  }
  function toProfile() {
    navigate('/profiles')
  }
  function toFaqs() {
    navigate('/faqs');
  }
  function toPhotos() {
    navigate('/album-list');
  }
  function toWall() {
    navigate('/community-wall');
  }
  function toAgenda() {
    navigate('/agenda');
  }
  function toTree() {
    navigate('/stories');
  }
  function toLogin() {
    navigate('/login');
}

  const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    // enlarged
    const [enlarged, setEnlarged] = useState(false);

  // scroll to top
useEffect(() => {
  window.scrollTo(0, 0); // Scrolls to the top when the component mounts
}, []);

// rendering userData from firestore:
useEffect(() => {
        const userDocRef = collection(db, 'profiles');
        const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
          let theUserData = null; // Initialize theUserData to null
          snapshot.docs.forEach((doc) => {
            const data = doc.data();
            if(paramEmail){
              if (doc.id === paramEmail) {
                theUserData = {
                  id: doc.id,
                  ...data,
                };
              } 
            } 
          });
          if (theUserData !== null) {
            setUserData(theUserData);
          }
          return () => {
            unsubscribe();
          };
        });    

  // Profile Pic logic:
  const downloadImage = async () => {
    try {
      const profilePicRef = ref(storage, `profilepics/${paramEmail}`);
      const imageRefs = await listAll(profilePicRef);
      const downloadedImage = await Promise.all(
        imageRefs.items.map(async (imageRef, index) => {
          const downloadURL = await getDownloadURL(imageRef);
          return {id:0, src: downloadURL, alt: imageRef.name };
        })
      );
      setProfilePic(downloadedImage[0].src);
      } catch (error) {
        console.error('Error downloading images:', error);
      }
  }
  
  downloadImage();
}, [paramEmail, db]);


const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};
const [publicEmail, setPublicEmail] = useState();

useEffect(() => {
  const fetchPublicEmail = async () => {
    const profilesCollection = collection(db, 'profiles');
    const userDoc = doc(profilesCollection, paramEmail);
    try {
      const docSnap = await getDoc(userDoc);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const publicMailValue = userData.publicMail;
        setPublicEmail(!publicMailValue);
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Error fetching publicMail: ', error);
    }
  };
  fetchPublicEmail();
}, [paramEmail]);

  return (
    <>
    <div className={enlarged || loading ? "blur-it" : ""}>
    <div>
    {theWidth>750 ?
    <>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"17.5px",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfile} style={{position:"absolute",left:"14%", top:"17.5px",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toTree} style={{position:"absolute",right:"24.5%", top:"17.5px",cursor:"pointer"}}>
    <div className="the-titles-hover">Stories</div>
    </div>
    </>
    :
    <>
    <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
    <Dropdown handleBoolChange={handleBoolChange} />
    </>
    }
    <div className="title">
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Riley Reunion
      </div>
    </div>
    <div style={{
          // backgroundImage:`url(${newpic})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // width: '100%',
        // height: theWidth > 922 ? theWidth : theWidth > 530 ? 922 : 1300
             }}>  
    <div className="center-stuff">
    <div className="profile-border">
    <div>
    <div style={{margin:"5px"}}>
    {!paramEmail ?
      <div className="center-column">
      <div style={{margin:"15px"}}>Problem Encountered, sorry</div>
      {/* <button className="button-71" onClick={toLogin}>Login</button> */}
      </div>
      :
      <div>
      <br/>
      {/* <div className="center-stuff"><a href="#">{paramEmail}</a></div> */}
      {/* {JSON.stringify(profilePic)} */}
      </div>
      }
    </div>
      {/* {JSON.stringify(userData)} */}
      <div>
      {paramEmail && <div className="title">
      <div onClick={profilePic && expandPhoto} style={{cursor:profilePic &&"pointer",width: '100px',height: '100px', border:"4px double black", borderRadius: '50%',overflow: 'hidden',
      position: 'relative',margin: '0 auto',display: 'flex',alignItems: 'center',justifyContent: 'center',filter: 'blur(0)'}}>
      <>
      {profilePic ? 
      <>
      <img src={profilePic}  
      style={{
        width:userData.picZoom?`${userData.picZoom}px`:"100px",
        marginLeft:userData.picSide?`${userData.picSide}px`:"0px", 
      marginTop:userData.picUp?`${userData.picUp}px`:"0px",
      objectFit: 'cover',filter: 'blur(0)'}} 
      />
            </>
            :
            <div style={{marginTop:"0px"}}>
            <BsPersonFill style={{fontSize:"72px", width:"200px"}} />
            </div>
      }
    </>

      </div>
      </div>}
      
      {paramEmail && <>
      <div style={{display:"flex",flexDirection: "column",justifyContent:"center"}}>
      <div className="profile-text">
      <div className="prof-blue">Name:</div>
      </div>
      <div style={{marginLeft:"15px"}}>{userData.username}</div>
      <div className="profile-text">
      <div className="prof-blue">Age:</div>
      </div>
        <div style={{marginLeft:"15px"}}>{userData.age}</div>
      </div>
      <>
      <div className="profile-text">
      <div className="prof-blue">
        Branch affiliation:
        </div>
      </div>
      <div style={{margin:"15px"}}>{userData.branch}</div>
      </>
      <>
      <div className="profile-text">
      <div className="prof-blue">
      Phone number:
      </div>
      </div>
      <div style={{margin:"15px"}}>{userData.phone}</div>
      </>
      <>
      <div className="profile-text">
      <div className="prof-blue">
      Address:
      </div>
      </div>
      <div style={{margin:"15px"}}>{userData.address}</div>
      </>
      <>
        <div className="profile-text">
        <div className="prof-blue">Email:</div>
        </div>
        {userData.publicMail==true ?
        <div style={{marginLeft:"15px"}}>{userData.email}</div>
        :
        <div className="blur-email">
        <div style={{marginLeft:"15px"}}>{userData.email}</div>
        </div>
        }
      </>
        <div className="profile-text"><div className="prof-blue">About:</div></div>
        <div className="center-stuff">
        <div style={{padding:"5px",width:`${theWidth/1.3}px`,border:"double 3px black",borderRadius:"5px", height:"150px",overflow:"auto",textOverflow:"ellipsis",wordWrap:"break-word",marginLeft:"15px", marginRight:"15px"}}> {userData.aboutme}</div>
        </div> 
        </>
      }
      </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    
    {/* enlarged photo: */}
    {loading && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }
    
    {/* {enlarged && <div onClick={shrinkPhoto} style={{cursor:"pointer", width: '500px',height: '500px', border:"4px solid black", borderRadius: '0%',overflow: 'hidden',
      position: 'absolute',top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: '0 auto',display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex:"1"}}>
      <img src={profilePic}  style={{width: '100%',height: '100%',objectFit: 'cover'}} />
    </div>} */}

    {enlarged && <div onClick={shrinkPhoto} style={{cursor:"pointer", border:"4px double black", borderRadius: '0%',overflow: 'hidden',
      position: 'absolute',top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      margin: '0 auto',display: 'flex',alignItems: 'center',justifyContent: 'center',zIndex:"1"}}>
      <img src={profilePic}  style={{width: '400px',objectFit: 'cover'}} />
    </div>}
    
    </>
  );
}

export default OtherProfiles;