import React, { useEffect, useRef, useState, useContext } from 'react';
import Dropdown from '../dropDown';
import { Card, Button, Alert, Spinner } from 'react-bootstrap'
import LoadingSpinner from './loadingSpinner';
import { useNavigate  } from 'react-router-dom'
import { getAuth, signOut, sendEmailVerification, signInWithEmailAndPassword, sendPasswordResetEmail, EmailAuthProvider } from 'firebase/auth';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';
import '../somecss.css'
import {BsCaretDownFill, BsCaretUpFill, BsFillCheckCircleFill, BsFillXCircleFill, 
  BsXCircleFill, BsHandIndexFill, BsXCircle, BsCheckCircleFill,BsFillHouseFill} from "react-icons/bs";
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_riley.jpeg';
import { collection, doc, setDoc, getFirestore } from 'firebase/firestore';

import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import cs_face from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_face.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import harriet from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/harriet.png';
import so_you_are_sanford_riley from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/so_you_are_sanford_riley_compressed.png';
import riley_clan from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_clan.png';
import signature from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sig-newest.png';



function Login({ katePass, showKate }) {
  const db = getFirestore();
  const navigate = useNavigate();
  const auth = getAuth(app);
  const { login, currentUser } = useAuth()

  const  passwordForKate = process.env.REACT_APP_PASSWORD_FOR_KATE;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [newVerification, setNewVerification] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  // error
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false); 
  const [failureMessage, setFailureMessage] = useState('');
  const [loading, setLoading] = useState(false);


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // real deal login here:
  const handleLog = async () => {
    setSuccess(false);
    setFailure(false);
    setLoading(true);
    try {
      await login(email, password).then(async()=>{
        // setLoading(false);
        const user = auth.currentUser;

      if (user && user.emailVerified) {
        setLoading(false);
        setSuccess(true);
        navigate('/home'); // Assuming this is the successful login navigation logic
      } else {
        setLoading(false);
        await sendEmailVerification(user);
        await signOut(auth);
        setNewVerification(true);
        setFailureMessage('Check your email! Verify it before logging in.');
        setFailure(true);
        // Prompt the user to verify their email
      }
        // navigate('/home');
      });
      console.log('User logged in successfully');
      setSuccess(true);
    } 

  //   setSuccess(false);
  // setFailure(false);
  // setLoading(true);

  // try {
  //   const user = auth.currentUser;
  //   await login(email, password);

  //   if (user && user.emailVerified) {
  //     setLoading(false);
  //     setSuccess(true);

  //     const pushProfileDoc = async () => {
  //       const profilesCollection = collection(db, 'profiles');
  //       const userDoc = doc(profilesCollection, email);
  //       const userData = {
  //         username: "",
  //         age: 0,
  //         aboutme: "Nothing here...",
  //         email: email,
  //         generation: 3,
  //         picSide: 0,
  //         picUp: 0,
  //         picZoom: 100,
  //       };

  //       try {
  //         await setDoc(userDoc, userData);
  //         console.log('Document set successfully');
  //       } catch (error) {
  //         console.error('Error setting document: ', error);
  //       }
  //     };
  //     pushProfileDoc();
  //     navigate('/home');
  //   } else {
  //     setLoading(false);
  //     await signOut(auth);
  //     setNewVerification(true);
  //     setFailureMessage('Please verify your email before logging in.');
  //     setFailure(true);
  //   }
  // }
    
    catch (error) {
      setLoading(false);
      setFailureMessage(error.message);
      console.error('Error logging in:', error.message);
      setFailure(true);
    }
  }

  // width/picture resizing and navigation below

    const [theWidth, setTheWidth] = useState(window.innerWidth);
    const [quote, setQuote] = useState();

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);


    function toHome() {
        navigate('/home');
    }
    function toSignUp() {
      navigate('/sign-up');
  }
  const [emailSent, setEmailSent] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailReset, setEmailReset] = useState('');
  const [errorReset, setErrorReset] = useState(false);
  const [errorText, setErrorText] = useState('');

  // password reset
  const handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, emailReset)
      setErrorReset(false);
        setEmailSent(true);
        // alert('sent email')
        // setMessage(error.message);
        // setPasswordError(true);
    } catch(error) {
      setEmailSent(false);
      setErrorReset(true);
      setErrorText(error);
    }
    
  };
  const [reset, setReset] = useState(false);
  function passwordResetStart() {
    setReset(true);
  }
  function hideReset() {
    setErrorText('');
    setErrorReset(false);
    setReset(false);
    setEmailReset('');
  }

  // const handleSendVerificationEmail = async () => {
  //     try {
  //       await sendEmailVerification(email);
  //       setVerifyError('New verification email sent!');
  //       console.log('New verification email sent!');
  //     } catch (error) {
  //       setVerifyError(error.message);
  //       console.error('Error sending verification email:', error.message);
  //     }
  // };

  const handleSendVerificationEmail = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setVerifyError('New verification email sent!');
        console.log('New verification email sent!');
      } else {
        setVerifyError('User not found. Please log in again.');
      }
    } catch (error) {
      setVerifyError(error.message);
      console.error('Error sending verification email:', error.message);
    }
  };

  // resend
  
  const handleResendVerification = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        await sendEmailVerification(user);
        console.log('New verification email sent!');
        // Set state or perform other actions indicating the email was sent
      } else {
        // Handle case where user is not found
      }
    } catch (error) {
      console.error('Resending verification failed:', error.message);
      // Handle errors and set error state or perform other actions
    }
  };

  useEffect(() => {
    function handleKeyPress(event) {
      if (event.key === 'Enter') {
        handleLog();
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('keydown', handleKeyPress);

    // Clean up by removing event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [email,password]);

  return (
    <>
    <div className={loading || reset ? "blur-it" : ""}>
        <div style={{
          // backgroundImage: `url(${newpic})`,
          // backgroundPosition: 'center',
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        // width:"900px",
        // minHeight:"100px"
        // width: '100%',
        // height: theWidth,
        // maxHeight:"800px",
        // maxWidth:"800px",
        // border:"solid black 1px"
             }}> 
    {/* <div className="center-stuff">
      <h1 style={{color:"black", fontFamily:"Tahoma", marginBottom:"20px"}} >Login</h1>
    </div> */}
    <div className="title">
    {theWidth>450 ? <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
              Riley Reunion
            </div>
    :
    <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
        Reunion
      </div>
    }
    </div>
          {/* new sign up / home styles */}
          <div style={{position:"absolute", right:"1%", top:"5px"}}>
            <button className="stories-button" onClick={toSignUp}>Sign Up</button>
          </div>
          <div onClick={toHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}>
      <BsFillHouseFill fontSize={28} color="#0078d0" />
      </div>
          {/* new styles ending */}
    {/* <div style={{position:"absolute", right:"2%", top:"1%"}}>
    <div>
    <button className="buttonStyle" onClick={toHome}>Back to Home</button><br/>
    <button className="buttonStyle" onClick={toSignUp}>Sign Up</button>
    </div>
    </div> */}
    {theWidth>450 &&<div style={{position:"absolute", left:`${theWidth/100+7}%`, top:"225px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
     <img src={riley_clan} style={{width:`${theWidth/7+20}px`}} />
    </div>}
    {theWidth>450 &&<div style={{position:"absolute", right:`${theWidth/200-11}%`, top:"225px",transform:"translate(-50%,-50%)",zIndex:"-1"}}>   
     <img src={signature} style={{width:`${theWidth/5+20}px`}} />
    </div>} 
    {/* login UI: */}
    <div className="center-stuff">
    <div className="sign-up">
    <div className="center-stuff">
    <span style={{margin:"10px"}}><div className="the-titles">Login</div></span>
    </div>
        <div className="center-stuff">
          <div style={{color:"black", fontFamily:"Tahoma", margin:"5px",marginTop:"15px"}} >Email:</div>
        </div>
        <div className="center-stuff">
          <input style={{margin:"5px", marginRight:"15px",marginLeft:"15px", width:"200px"}} type="email" value={email} onChange={handleEmailChange} />
        </div>
        <div className="center-stuff">
          <div style={{color:"black", fontFamily:"Tahoma", margin:"5px"}} >Password:</div>
        </div>
        <div className="center-stuff">
          <input style={{margin:"5px", width:"200px"}} type="password" value={password} onChange={handlePasswordChange} />
        </div>
        <div className="center-stuff">
        <button onClick={handleLog} style={{margin:"10px", marginBottom:"15px", cursor:"pointer",fontFamily:"Tahoma"}}>Submit</button>
        </div>
      {/* <div>Forgot Password?</div> */}
      <div className="center-stuff">
      <a style={{marginBottom:"10px"}} href="#" onClick={passwordResetStart}>
        Forgot/Change Password
      </a>
      </div>



    </div>
    </div>

    {success && 
      <div className="center-stuff">
        <div className="login-error">
          <div style={{marginTop:"10px"}}>
          <div style={{margin:"5px", width:"150px"}}><div className="center-stuff"><BsFillCheckCircleFill style={{color:"green",marginRight:"25px"}} /><i>Logged In</i><BsFillCheckCircleFill style={{color:"green",marginLeft:"25px"}} /></div></div>
          </div>
        </div>
        </div>
    }
    {failure && 
        <div className="center-stuff">
        <div className="login-error">
          <div style={{margin:"10px"}}>
          <div className="center-stuff"><BsFillXCircleFill style={{color:"maroon",marginRight:"25px"}}  /><i>ERROR</i><BsFillXCircleFill style={{color:"maroon",marginLeft:"25px"}} /> </div>
          </div>
          {/* <div className="center-stuff"><img src={cs_face} style={{width:"150px",marginBottom:"5px"}} /></div> */}
          {/* failure messages: MAKE READABLE */}
          <i style={{margin:"5px"}}>{failureMessage}</i><br/>
          {/* <button onClick={handleResendVerification}>Send New Verification Email</button> */}
          {verifyError && <p>{verifyError}</p>}
        </div>
        </div>
    }
    </div>
    <div className="center-stuff">
      <img src={so_you_are_sanford_riley} style={{width:"70%",marginTop:"75px"}} />
    </div>
    </div>
    {loading && 
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    }
    {reset &&
     <div style={{position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%)"}}>
      {/* <LoadingSpinner /> */}
      <Card>
      <div className="center-login-prompt" style={{cursor:"default"}}>
      <div style={{marginTop:"10px", color:"maroon",cursor:"pointer", position:"fixed",right:"10%"}} onClick={hideReset}><BsXCircle style={{fontSize:"22px"}} /></div>
      <div style={{marginRight:"10px",marginLeft:"10px"}}>
      <div className="center-stuff">
      <span style={{marginBottom:"10px",marginTop:"35px"}}>Enter Email:</span>
      </div>
        <input style={{width:"200px"}}
        type="email"
        placeholder="Enter your email"
        value={emailReset}
        onChange={(e) => setEmailReset(e.target.value)} />
      </div>
      <div style={{margin:"10px"}}><button style={{cursor:"pointer"}} onClick={handleResetPassword} >Reset Password</button></div>
      {
        emailSent && <div><BsCheckCircleFill style={{color:"green", marginRight:"10px"}} />check your email!<BsCheckCircleFill style={{color:"green",marginLeft:"10px"}} /></div>
      }
      {
        errorReset && <div><BsXCircleFill style={{color:"maroon", marginRight:"10px"}} />{errorText.code}<BsXCircleFill style={{color:"maroon",marginLeft:"10px"}} /></div>
      }
      </div>
      </Card>
     </div> 
    }
    </>
  );
}

export default Login;