import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '/Users/Ian/ProjectFolder/family-tree-june/src/AuthContext';
import Dropdown from '../dropDown';
import Select from 'react-select';
import Compressor from 'compressorjs';
import { useNavigate  } from 'react-router-dom'
import {BsHouseFill, BsPersonVcard,BsFillHouseFill} from "react-icons/bs";
import sdimage2 from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/sd_image_line_2.jpeg';
import newpic from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/new_riley_pic11.jpeg';
import '../somecss.css'
import { sendPasswordResetEmail } from '@firebase/auth';
import cs from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/cs_riley.jpeg';
import rt from '/Users/Ian/ProjectFolder/family-tree-june/src/imageFolder/riley_rt.jpeg';
import LoadingSpinner from './loadingSpinner';
// firebase 
import { getStorage, ref, uploadBytes, listAll,deleteObject } from 'firebase/storage';
import { collection, addDoc, getFirestore, serverTimestamp } from "firebase/firestore";

import { isAccordionItemSelected } from 'react-bootstrap/esm/AccordionContext';

function UploadObit() {
  const { currentUser } = useAuth();
  const db = getFirestore();

    const navigate = useNavigate();
    const storage = getStorage();
    const [file, setFile] = useState();
    const folderPath = 'albums';
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);

    const [articleName, setArticleName] = useState('');
    const [articleContent, setArticleContent] = useState('');

    const [obitUrl, setObitUrl] = useState('');
    const [deceasedName, setDeceasedName] = useState('');

    function toHome() {
      navigate('/home');
    }
    function toTree() {
      navigate('/family-tree');
    }
    function toProfile() {
      navigate('/profile')
    }
    function toProfiles() {
      navigate('/profiles')
    }
    function toFaqs() {
      navigate('/faqs');
    }
    function toPhotos() {
      navigate('/album-list');
    }
    function toWall() {
      navigate('/community-wall');
    }
    function toAgenda() {
      navigate('/agenda');
    }
    function toStories() {
      navigate('/stories');
    }
    function toLogin() {
      navigate('/login');
  }


  const [albumTag, setAlbumTag] = useState('Family');

  const branchOptions = [
    { value: 'default', label: 'Family' },
    { value: 'historical', label: 'Historical' },
    { value: 'past-events', label: 'Past Events'},
    { value: 'other', label: 'Other' },
  ];
  const handleOptionChange = (selectedOption) => {
    console.log('Selected Option:', selectedOption);
    if (selectedOption && (selectedOption.value === 'default')) {
      setAlbumTag('Family');
      }  if (selectedOption && (selectedOption.value === 'historical')) {
        setAlbumTag('Historical');
      }    if (selectedOption && (selectedOption.value === 'past-events')) {
        setAlbumTag('Past-Events');
      }  if (selectedOption && (selectedOption.value === 'other')) {
        setAlbumTag('Other');
      }      
      else {
        // Do something for other options
        console.log('Option is not any of the listed ones');
        // Perform action Y
      }
  };

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    function backToArticles() {
        navigate('/stories');
    }
    function goHome(){
      navigate('/home');
    }
    function toProfile(){
      navigate('/profile');
    }

    const [theWidth, setTheWidth] = useState(window.innerWidth);

    useEffect(()=>{
      const handleResize = () => {
        setTheWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
      // 
      // 
    },[]);

    function toLogin() {
      navigate('/login');
  }
  function toSignUp() {
    navigate('/login');
}
const [buttonDisabled, setButtonDisabled] = useState(true);
const [uploadProgress, setUploadProgress] = useState(0);

const compressWithQuality = (file, quality, resolve, reject) => {
  new Compressor(file, {
    quality: quality,
    maxWidth:2000,
    // maxheight:500,
    success(result) {
      console.log('Original file size:', file.size, 'bytes');
      console.log('Compressed file size:', result.size, 'bytes');
      resolve(result);
    },
    error(error) {
      reject(error);
    },
  });
};
const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to megabytes

    if (fileSizeMB > 10) {
      // Compress with quality 0.4 for files larger than 5 MB
      compressWithQuality(file, 0.8, resolve, reject);
      console.log('over 10')
    } else if (fileSizeMB > 5) {
      // Compress with quality 0.4 for files larger than 5 MB
      console.log('over 5')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 2) {
      // Compress with quality 0.6 for files between 2 MB and 5 MB
      console.log('over 2')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 1) {
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      console.log('over 1mb')
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.8) {
      console.log('over 0.8mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.6) {
      console.log('over 0.6mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else if (fileSizeMB > 0.4) {
      console.log('over 0.4mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    } else {
      console.log('under 0.4mb')
      // Compress with quality 0.8 for files smaller than or equal to 2 MB
      compressWithQuality(file, 0.8, resolve, reject);
    }
  });
};

// new handleFileChange for ONE PHOTO only:
const handleFileChange = async (event) => {
    // make logic that ensures only images are uploaded!
    const file = event.target.files[0];
    try {
      const compressedFile = await compressImage(file);
      setSelectedFile(compressedFile);
      setButtonDisabled(false);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };
  const handlePdfChange = async (event) => {
    // make logic that ensures only images are uploaded!
    const pdfFile = event.target.files[0];
    try {
    //   const compressedFile = await compressImage(file);
      setSelectedPdf(pdfFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const obitStorage = async (file) => {
    // Check if a file is selected
    if (file) {
      try {
        setLoading(true);
        const deleteRef = ref(storage, `obits/${currentUser.email}/${deceasedName}`);
        const imageRef = ref(storage, `obits/${currentUser.email}/${deceasedName}/image/${file.name}`);
        // const pdfRef = ref(storage, `obits/${currentUser.email}/${deceasedName}/$pdf/${selectedPdf.name}`);
        // DELETE ANY PHOTOS
        const listResult = await listAll(deleteRef);
        const deletePromises = listResult.items.map((item) => deleteObject(item));
        await Promise.all(deletePromises);
        // UPLOAD PHOTO
        await uploadBytes(imageRef, file);
        // if(selectedPdf!=null){
        //     // UPLOAD PDF
        //     await uploadBytes(pdfRef, selectedPdf);
        // }
        // alert('File uploaded successfully!');
        toStories();
        window.location.reload();
        setLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Error uploading file:', error);
      }
    } else {
      // Handle case where no file is selected
    //   alert('Please select a file to upload.');
    }
  // pushEdit function resumes:
  await obitStorage(selectedFile);
};

// upload content/metada to firestore:
const obitFirestore = async () => {
    // ending url checks...
    try {
      const postData = {
        name: deceasedName,
        user:currentUser.email,
        timestamp: serverTimestamp(),
        link:obitUrl,
      };
    //   const docRef = await addDoc(collection(db, "wall-test"), postData);

      const docRef = await addDoc(collection(db, `obits`), postData);
      console.log("Post added with ID: ", docRef.id);
      setDeceasedName('')
      setObitUrl('')
    } catch (error) {
      console.error("Error adding post: ", error);
    }
  };



// dfgdfgdfg
const handleObitUrl = (e) => {
    setObitUrl(e.target.value);
};
const handleDeceasedname = (e) => {
    setDeceasedName(e.target.value);
  };

const handleBoolChange = (newValue) => {
  // setBoolValue(newValue);
};

const handleUpload = () => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    // make a check to ensure no article names are the same?
    if (deceasedName.trim().length === 0) {
      alert('Please enter a name');
      return; // Stop further execution
    }
    if (obitUrl.trim().length > 0 && selectedPdf!=null) {
        alert('No uploading both pdf and a link');
        return; // Stop further execution
      }
      if (obitUrl.trim().length > 0) {
        if (!urlRegex.test(obitUrl)) {
            alert('Invalid URL:', obitUrl);
            return
          }
      }
    obitFirestore();
    obitStorage();
    }
// newprocessfile:
const processFile = (file) => {
    return new Promise((resolve) => {
      // Simulating file processing with a timeout
      const processingInterval = setInterval(() => {
        const progress = Math.floor(Math.random() * 100); // Simulated progress, adjust as needed
        setUploadProgress(progress);
  
        if (progress === 100) {
          clearInterval(processingInterval);
          resolve(); // Resolve the promise when processing is done
        }
      }, 500); // Simulated processing time, adjust as needed
    });
  };


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 250, // You can adjust this value as needed
  }),
};
  return (
    <>
    {theWidth>750 ?
    <div>
    <div onClick={toHome} style={{position:"absolute",left:"4%", top:"2.5%",cursor:"pointer",borderBottom:"none"}}>
    <div className="the-titles-hover">Home</div>
    </div>
    <div onClick={toProfiles} style={{position:"absolute",left:"14%", top:"2.5%",color:"black",zIndex:"2",cursor:"pointer"}}>
    <div className="the-titles-hover">Profiles</div>
    </div>
    <div onClick={toPhotos} style={{position:"absolute",left:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Photos</div>
    </div>
    <div onClick={toAgenda} style={{position:"absolute",right:"3.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Agenda</div>
    </div>
    <div onClick={toWall} style={{position:"absolute",right:"15.3%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles-hover">Wall</div>
    </div>
    <div onClick={toStories} style={{position:"absolute",right:"24.5%", top:"2.5%",cursor:"pointer"}}>
    <div className="the-titles">Stories</div>
    </div>
    </div>
    :
    <>  
      <div onClick={goHome} style={{position:"absolute", left:"5%", top:"17px",cursor:"pointer"}}><BsFillHouseFill fontSize={28} color="#0078d0" /></div>
      <Dropdown handleBoolChange={handleBoolChange} />
    </>
}
        <div className="title">
            <div style={{fontFamily: 'cursive',fontSize: '2.5em',fontWeight: 'bold',fontStyle: 'italic',color: '#283593',textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',padding: '0px'}}>
            Riley Reunion
         </div>
    </div>   
    <div className={loading ? "blur-it" : ""}>
    <div>
    <div className="center-stuff">
    <div style={{cursor:"pointer",marginTop:"15px"}}>
      {!loading &&<button className="read-more-button" onClick={backToArticles}>Back to Stories</button>}
    </div>
    </div>
    <div>
    {!currentUser 
    ?
    <>
    <h3 className="center-stuff">First, you must:</h3>
    <div className="center-stuff">
    <button className="stories-button" onClick={toLogin}>Login</button>
    </div>
    </>
    :
    <div>
    <div>
      <div style={{ display: 'flex', flexDirection: 'column',marginTop:"15px"}}>

      <div className="center-stuff">
      <div style={{margin:"10px"}}>Name of the deceased:</div>
      </div>
      <div className="center-stuff">
      <input style={{margin:"10px",width:"250px"}} type="text" placeholder="Name of deceased..." value={deceasedName} onChange={handleDeceasedname} />
      </div>

      <div className="center-stuff"><div style={{margin:"10px"}} >Photo of the deceased:</div></div>
            <div className="center-stuff">
        <label htmlFor="fileInput" className="stories-button">
    Choose Photo
    <input
        id="fileInput"
        type="file"
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
    />
    </label>
      </div>
      <div className="center-stuff">
      {selectedFile && selectedFile.name}
      </div>

      <div className="center-stuff">
      {/* {articleContent} */}
      </div>
      <div className="center-stuff">
      <div style={{margin:"10px"}}>Link to obituary URL (include 'https://'):</div>
      </div>
      <div className="center-stuff">
      <input style={{margin:"10px",width:"400px"}} type="text" placeholder="Paste URL here.." value={obitUrl} onChange={handleObitUrl} />
      </div>
      {/* <div className="center-stuff">
      <div style={{margin:"10px"}}>Or upload a pdf version:</div>
      </div> */}
      {/* <div className="center-stuff">
        <label htmlFor="fileInput" className="stories-button">
    Choose PDF
    <input
        id="fileInput"
        type="file"
        onChange={handlePdfChange}
        accept="application/pdf"
        style={{ display: 'none' }}
    />
    </label>
      </div> */}
      </div>
      <br/>
      <div className="center-stuff">
      <button className="upload-button" disabled={buttonDisabled} onClick={handleUpload}>Post Obituary</button>
      </div>
    </div>
    </div>
    }
    </div>
    </div>
    </div>
    <br/>
    {loading && 
    <>
    <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
      <LoadingSpinner />
    </div>
    </>
    }
    </>
  );
}

export default UploadObit;