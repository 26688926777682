import React, { useContext, useState, useEffect } from 'react'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, signOut, 
	sendPasswordResetEmail, updateEmail, updatePassword } from 'firebase/auth';
import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';


const AuthContext = React.createContext()

export function useAuth(){
	return useContext(AuthContext)
}

export function AuthProvider({ children }){

	const auth = getAuth(app);


	const [currentUser, setCurrentUser] = useState()
	const [loading, setLoading] = useState(true)
	const [theSharedState, setTheSharedState] = useState(""); // Add your shared state here

	function updateSharedState(newValue) {
		setTheSharedState(newValue);
	  }

	function signup(email, password){
		alert('test')
		return createUserWithEmailAndPassword(auth, email, password)
	}

	function login(email, password){
		return signInWithEmailAndPassword(auth, email, password)
	}

	function logout(){
		return signOut(auth)
	}

	function resetPassword(email){
		return sendPasswordResetEmail(auth,email)
	}

	function updateEmail(email){
		return currentUser.updateEmail(email)
	}

	function updatePassword(password){
		return currentUser.updatePassword(password)

	}

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(user => {
			
			setCurrentUser(user)
			setLoading(false)	
		})
		return unsubscribe
	}, [])


	// auth.onAuthStateChanged(user => {
	//  	setCurrentUser(user)
	//  })

	const value = {
		currentUser, 
		theSharedState, 
    	updateSharedState,
		login,
		signup,
		logout,
		resetPassword,
		updateEmail,
		updatePassword
	}

	return(

		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>

	)
}



// CHAT GPT VERSION:


// AuthContext.js
// import React, { createContext, useContext, useEffect, useState } from 'react';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';
// import app from '/Users/Ian/ProjectFolder/family-tree-june/src/firebase';

// const AuthContext = createContext();

// export function AuthProvider({ children }) {
//   const [user, setUser] = useState(null);
//   const auth = getAuth(app);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setUser(user);
//     });

//     return () => unsubscribe();
//   }, [auth]);

//   return (
//     <AuthContext.Provider value={user}>
//       {children}
//     </AuthContext.Provider>
//   );
// }

// export function useAuth() {
//   return useContext(AuthContext);
// }